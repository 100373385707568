@charset "UTF-8";

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video,
button {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: top;
    background: transparent;
}

.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
    margin-bottom: 1rem;
}
/*.article-content h1{*/
/*    font-size: 15px !important;*/
/*    font-weight: bold !important;*/
/*    font-style: italic !important;*/
/*}*/

.article-content h2{
    font-size: 20px !important;
    font-weight: bold !important;
    color : #58b0e3 !important;
}
.article-content h3{
    font-size: 16px !important;
    font-weight: bold !important;
}
.article-content h4{
    font-size:  14px !important;
    font-weight: bold !important;
}
.article-content h5,.article-content h6{
    font-size: 14px !important;
    font-style: italic !important;
}
@font-face {
    font-family: "SF Pro Text";
    src: url("../font/SF-Pro-Text-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("../font/SF-Pro-Text-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("../font/SF-Pro-Text-Semibold.otf") format("opentype");
    font-weight: 600;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("../font/SF-Pro-Text-Heavy.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "SF Pro Text";
    src: url("../font/SF-Pro-Text-Black.otf") format("opentype");
    font-weight: 900;
}
@font-face {
    font-family: "SVN-Gilroy";
    src: url("../font/SVN-GilroySemiBold.woff2") format("woff2"),
        url("../font/SVN-GilroySemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "SVN-Gilroy";
    src: url("../font/SVN-GilroyBold.woff2") format("woff2"),
        url("../font/SVN-GilroyBold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SVN-Gilroy';
    src: url('../font/SVN-GilroyHeavy.woff2') format('woff2'),
        url('../font/SVN-GilroyHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
html {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-overflow-scrolling: touch;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
    /* Safari compatibility */
    /* height: 100%;
    width: 100%; */
    /* transform: translate3d(0,0,0); */
}

body {
    font-family: "SF Pro Text";
    color: #444;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    word-wrap: break-word;
    min-height: 100%;
    height: 100%;
    line-height: 1.6;
    font-weight: 400;
}

body.modal-open {
    overflow: visible !important;
}

/** --a-- **/
a {
    transition: all ease 0.3s;
    text-decoration: none;
}

a.no-transition {
    transition: unset;
}

a:hover {
    text-decoration: none;
}

a.no-color {
    color: unset;
}

/** --img-- **/
img {
    max-width: 100%;
    vertical-align: middle;
}

.product-description img {
    height: auto;
}

/** --ul-- **/
ul {
    margin: 0;
    list-style: none;
}

/* ul:after {
    clear: both;
    display: block;
    content: "";
} */

button {
    cursor: pointer;
}

button:focus {
    outline: none;
}

/** common ***/
@media (max-width: 767px) {
    #wrapper {
        padding-bottom: 57px;
    }
}

.wrap {
    max-width: 1216px;
    padding: 0 40px;
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 767px) {
    .wrap {
        padding: 0 16px;
    }
}

.wrap-content {
    max-width: 750px;
    margin: 0 auto;
}

.wrap-content-710 {
    max-width: 710px;
    margin: 0 auto;
}

.hover:hover {
    opacity: 0.7;
}

.sp-only {
    display: none !important;
}

@media (max-width: 767px) {
    .sp-only {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .pc-only {
        display: none !important;
    }
}

.c-red {
    color: #ff4451;
}

.c-blue {
    color: #34beef;
}

.c-green {
    color: #2cba4b;
}

.c-disable {
    color: #aaaaaa;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semibold {
    font-weight: 600;
}

.wrap_banner {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.wrap_banner .wrap_content {
    width: 64.3%;
}

@media (max-width: 991px) {
    .wrap_banner .wrap_content {
        width: 100%;
    }
}

.wrap_banner .wrap_bnr {
    width: 31.1%;
}

@media (max-width: 991px) {
    .wrap_banner .wrap_bnr {
        width: 100%;
        display: none;
    }
}

.groupCategory-title {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.groupCategory-title .groupCategory-tlt {
    font-size: 20px;
    color: #222;
    font-weight: 600;
}

@media (max-width: 767px) {
    .groupCategory-title {
        margin-bottom: 16px;
    }

    .groupCategory-title .groupCategory-tlt {
        font-size: 18px;
        color: #222;
        font-weight: 600;
    }
}

.groupCategory-title .readMore {
    color: #34beef !important;
}

.groupCategory-title .readMore:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .discount ul li {
        width: 100% !important;
    }
}

.discount ul li.transport .discountBox {
    align-items: initial;
}

.discount ul li.transport .discountBox::after {
    content: "";
    background: url("../images/voucher-line.png") no-repeat;
}

.discount ul li.transport .discountBox .discount-design {
    background-color: #34beef;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.discount ul li.transport .discountBox .discount-design .discount-thumb {
    width: 30px;
    height: 30px;
}

.discount ul li.code-promo .discountBox {
    align-items: initial;
}

.discount ul li.code-promo .discountBox::after {
    content: "";
    background: url("../images/voucher-line.png") no-repeat;
}

.discount ul li.code-promo .discountBox .discount-design {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 80px;
    padding-left: 6px;
    font-size: 10px;
    color: #000;
    text-align: center;
}

.discount ul li.code-promo .discountBox .discount-design .discount-thumb {
    /* width: 30px;
    height: 25px; */
    /* position: relative;
    border-radius: initial; */
}

.discount ul li.code-promo .discountBox .discount-design .discount-thumb img {
    max-height: 100%;
}

.btn.btn-primary {
    background-color: #34beef;
    border-color: #34beef;
}

.btn.btn-primary:hover {
    background-color: #179ece;
}

.modal .modal-content {
    border-radius: 15px;
    margin-bottom: 80px;
}

.modal .modal-header {
    border-bottom: none;
    padding-bottom: 7px;
}

.modal .modal-header .modal-title {
    font-size: 18px;
    font-weight: 600;
}

.modal .modal-header .modal-switch {
    position: absolute;
    right: 60px;
    top: 18px;
}

.modal .modal-body {
    padding: 1rem 20px;
}

.modal .modal-footer {
    border-top: none;
}

.modal .modal-footer .btn-ft-modal {
    max-width: 280px;
    width: 100%;
    height: 40px;
}

.modal .modal-footer .btn-disabled {
    background-color: #eeeeee;
    pointer-events: none;
    color: #aaaaaa;
    border-color: #eeeeee;
    opacity: 1;
}

.user__shop-info {
    display: flex;
    align-items: center;
}

.user__shop-info .shop-thumb {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.user__shop-info .shop-detail {
    padding-left: 10px;
}

.user__shop-info .shop-detail .info-head .shop-name {
    font-weight: 600;
    font-size: 16px;
}

.user__shop-info .shop-detail .info-head .btn-flow {
    color: #34beef;
    cursor: pointer;
}

.rate-star {
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: #34beef;
    padding-right: 10px;
    font-size: 14px;
}

.star-list li {
    display: flex;
    align-items: center;
}

.star-list li + li {
    padding-left: 2px;
}

.star-list li .svg {
    cursor: pointer;
}

.star-list li .svg path {
    fill: #dddddd;
}

.star-list li .svg.checked path {
    fill: #34beef;
}

.article-list {
    display: flex;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .article-list {
        overflow-x: auto;
        margin-left: 0;
    }
}

.article-list li {
    width: 33.33%;
    padding-left: 20px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .article-list li {
        width: 70%;
    }
}

.article-list li .img {
    position: relative;
    margin-bottom: 15px;
}

.article-list li .img .btn-save-bookmart {
    position: absolute;
    right: 14px;
    bottom: 14px;
    background-color: transparent;
    border: none;
    z-index: 10;
}

.article-list li .img .read-views {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 3px 10px;
    color: #fff;
    font-size: 12px;
    left: 14px;
    bottom: 14px;
    display: flex;
    align-items: center;
}

.article-list li .img .read-views .svg {
    margin-right: 5px;
    width: 13px;
}

.article-list li .img .read-views .svg path {
    fill: #fff;
    stroke: #fff;
}

.article-list li .ttl {
    margin-bottom: 10px;
}

.article-list li .ttl a {
    font-size: 16px;
    color: #222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.article-list li .ttl a:hover {
    color: #34beef;
}

.article-list li .article-user {
    display: flex;
    align-items: center;
}

.article-list li .article-user .thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    margin-bottom: 0;
}

.article-list li .article-user .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article-list li .article-user .name {
    padding-left: 7px;
    font-size: 13px;
    color: #444;
}

.poin_items {
    display: inline-block;
    background-color: #ff7572;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 12px;
    color: #fff;
    position: relative;
    margin-left: 5px;
}

.poin_items::after {
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -4px;
    content: "";
    border-right: 7px solid #ff7572;
    border-bottom: 5px solid transparent;
    border-left: 0;
    border-top: 5px solid transparent;
}

.formUpload-thumb {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
}

.formUpload-thumb li {
    cursor: pointer;
    margin-left: 12px;
    margin-top: 12px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
}

.formUpload-thumb .custom-control {
    margin-bottom: 0;
    height: inherit;
}

.formUpload-thumb .custom-control .btn {
    padding: 0;
    border: none;
}

input::placeholder,
textarea::placeholder {
    color: #aaa;
}

.selectPro li {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
}

@media (max-width: 767px) {
    .selectPro li {
        padding-top: 12px;
    }
}

.selectPro li .selectPro-check {
    width: 34px;
}

.selectPro li .selectPro-info {
    width: calc(100% - 34px);
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .selectPro li .selectPro-info {
        background: #f8f8f8;
        padding: 12px;
        border: 1px solid #f1f1f1;
        border-radius: 12px;
    }
}

.selectPro li .selectPro-info.selectPro--opacity .selectPro_thumb,
.selectPro li .selectPro-info.selectPro--opacity .selectPro-quantity {
    opacity: 0.5;
}

.selectPro
    li
    .selectPro-info.selectPro--opacity
    .selectPro_content
    .selectPro-tlt,
.selectPro li .selectPro-info.selectPro--opacity .selectPro_content .sales,
.selectPro li .selectPro-info.selectPro--opacity .selectPro_content .price {
    color: #aaa;
}

.selectPro li .selectPro-info .selectPro_thumb {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .selectPro li .selectPro-info .selectPro_thumb {
        width: 56px;
        height: 56px;
    }
}

.selectPro li .selectPro-info .selectPro_content {
    width: calc(100% - 96px);
    padding-left: 16px;
    padding-right: 90px;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .selectPro li .selectPro-info .selectPro_content {
        width: calc(100% - 56px);
        padding: 0 0 0 12px;
    }
}

@media (max-width: 767px) {
    .selectPro li .selectPro-info .selectPro_content .selectPro-tlt {
        font-size: 13px;
    }
}

.selectPro li .selectPro-info .selectPro_content .sales,
.selectPro li .selectPro-info .selectPro_content .price {
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .selectPro li .selectPro-info .selectPro_content .sales,
    .selectPro li .selectPro-info .selectPro_content .price {
        font-size: 13px;
    }
}

.selectPro li .selectPro-info .selectPro_content .sales {
    color: #ff4451;
    padding-right: 4px;
}

.selectPro li .selectPro-info .selectPro_content .price {
    color: #34beef;
}

.selectPro li .selectPro-info .selectPro_content .selectPro-quantity {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
}

@media (max-width: 767px) {
    .selectPro li .selectPro-info .selectPro_content .selectPro-quantity {
        position: static;
        margin-top: 6px;
    }
}

.selectPro li .text-intro {
    color: #ff4451;
    font-size: 12px;
}

.selectPro._style-2 {
    margin-bottom: 12px;
}

.selectPro._style-2 li {
    padding-top: 12px;
}

.selectPro._style-2 li .selectPro-info {
    width: 100%;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 12px;
}

.selectPro._style-2 li .selectPro-info .selectPro_content {
    padding-right: 0;
}

.selectPro._style-2 li .selectPro-info .product-quantily {
    color: #666;
}

.code_price-promo {
    display: flex;
}

.code_price-promo .price-items {
    padding: 1px 3px;
    border: 1px solid #ff4451;
    border-radius: 2px;
    color: #ff4451;
    font-weight: 500;
    display: block;
}

.code_price-promo .price-items + .price-items {
    margin-left: 4px;
}

.select_payment li {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 20px 35px 20px 15px;
    align-items: center;
    position: relative;
}

@media (max-width: 767px) {
    .select_payment li {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
}

.select_payment li + li {
    margin-top: -1px;
}

.select_payment li.active {
    background: url("../images/icon-radio-tick.svg") no-repeat 97% 50% #e7fcfd;
    border-color: #34beef;
    z-index: 1;
}

.select_payment li .icon {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
}

.select_payment li .payment-info {
    width: calc(100% - 33px);
    padding-left: 10px;
}

.select_payment li .payment-info .ttl {
    font-size: 13px;
    font-weight: 500;
}

.select_payment li .payment-info .text {
    font-size: 12px;
    color: #444;
}

.select_payment li .payment-info .credit-list {
    display: flex;
    flex-flow: wrap;
}

.select_payment li .payment-info .credit-list .items {
    padding-right: 10px;
    display: block;
}

table {
    width: 100%;
}

/**** radio checkbox ****/
.custom-control {
    display: inline-flex;
    min-height: 18px;
    padding-left: 24px;
    position: relative;
}

.custom-control .custom-control-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.custom-control .custom-control-input:checked ~ .custom-control-indicator {
    background-color: #34beef;
    border: 1px #34beef solid;
}

.custom-control .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(52, 190, 239, 0.25);
}

.custom-control .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #ddd;
    border: 1px #ccc solid;
    cursor: not-allowed;
}

.custom-control .custom-control-input:disabled ~ .custom-control-description {
    cursor: not-allowed;
}

.custom-control .custom-control-description {
    cursor: pointer;
}

.custom-control .custom-control-indicator {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    display: block;
    left: 0;
    position: absolute;
    top: calc(50% - 10px);
    cursor: pointer;
}

.custom-control.custom-checkbox .custom-control-indicator {
    border-radius: 4px;
    border: 1px #aaa solid;
    width: 18px;
    height: 18px;
}

.custom-control.custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-indicator {
    background: url(../images/iconCheckBox.svg) no-repeat 50% #34beef;
}

.custom-control.custom-checkbox
    .custom-control-input:disabled
    ~ .custom-control-indicator {
    background: url(../images/iconCheckBox.svg) no-repeat 50% #ddd;
}

.custom-control.custom-radio .custom-control-indicator {
    border-radius: 50%;
    border: 1px #c6c6c6 solid;
    width: 20px;
    height: 20px;
}

.custom-control.custom-radio
    .custom-control-input:checked
    ~ .custom-control-indicator {
    background: url(../images/icon-radio.svg) no-repeat 50%;
    border: 1px #34beef solid;
}

.custom-control.custom-radio
    .custom-control-input:disabled
    ~ .custom-control-indicator {
    background: #ddd;
    border: 1px #ccc solid;
    cursor: not-allowed;
}

/****/
/**** update file ***/
.custom-file {
    position: relative;
    padding: 0;
}

.custom-file .btn {
    height: auto;
}

.custom-file .btn.btn-txt {
    color: #187cc2;
    font-size: 10px;
    margin-top: 4px;
}

.custom-file-input {
    opacity: 0;
    display: none;
}

/****/
.switch {
    -moz-user-select: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    position: relative;
}

.switch.switch--change .switch-checkbox:checked + .switch-round:before {
    left: 50%;
    background: url(../images/Bookmark-white.svg) no-repeat 50% #666;
}

.switch.switch--change .switch-checkbox:checked + .switch-round {
    background: #aaa;
}

.switch.switch--change .switch-round {
    width: 40px;
    height: 10px !important;
}

.switch.switch--change .switch-round:before {
    background: url(../images/Bookmark-white.svg) no-repeat 50% #34beef;
    width: 20px;
    height: 20px;
    left: 0;
}

.switch .switch-label {
    cursor: pointer;
    margin: 0;
}

.switch .switch-checkbox {
    opacity: 0;
    position: absolute;
}

.switch .switch-checkbox.active + .switch-round:before {
    left: 44%;
    background: url(../images/Bookmark.svg) no-repeat 50% #fff;
    background-size: 11px auto;
    content: "";
}

.switch .switch-checkbox.active + .switch-round {
    background: #34beef;
}

.switch .switch-round {
    border-radius: 16px;
    display: inline-block;
    vertical-align: middle;
    background: #666;
    width: 35px;
    height: 22px;
    position: relative;
    -moz-appearance: none;
    outline: 0 none;
}

.switch .switch-round:before {
    background: url(../images/icon-notification-bookmark03.svg) no-repeat 50%
        #fff;
    background-size: 11px auto;
    border-radius: 50%;
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    left: 2px;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.53, 1) 0s;
}

.breadcrumbList {
    padding: 16px 0;
}

@media (max-width: 767px) {
    .breadcrumbList {
        padding: 8px 0;
    }
}

.breadcrumbList ul.ctg-list-2 {
    display: inline-block;
    overflow-x: scroll;
    white-space: nowrap;
    /* flex-wrap: wrap; */
    width: 100%;
}

.breadcrumbList ul.ctg-list-2 li {
    display: inline-block;
    padding-right: 24px;
    color: #666;
    position: relative;
    width: auto;
}

@media (max-width: 767px) {
    .breadcrumbList ul.ctg-list-2 li {
        padding-right: 16px;
    }
}

.breadcrumbList ul.ctg-list-2 li::after {
    position: absolute;
    top: 5px;
    right: 9px;
    content: "";
    display: block;
    width: 8px;
    height: 12px;
    background: url(../images/icon-arrow-breadcrumb.svg) no-repeat;
}

@media (max-width: 767px) {
    .breadcrumbList ul.ctg-list-2 li::after {
        background: #bbb;
        width: 1px;
        right: 6px;
    }
}

.breadcrumbList ul.ctg-list-2 li:last-child::after {
    background: none;
    right: 0;
}

.breadcrumbList ul li a {
    color: #666;
    font-size: 14px;
}

.btn {
    font-size: 14px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
}

.btn.btn--hg32 {
    height: 32px;
}

input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}

/* clears the 'X' from Internet Explorer */
input[type="text"]::-ms-clear,
input[type="text"]::-ms-reveal,
input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal,
input[type="number"]::-ms-clear,
input[type="number"]::-ms-reveal {
    display: none;
}

/* clears the 'X' from Chrome */
input[type="text"]::-webkit-search-decoration,
input[type="text"]::-webkit-search-cancel-button,
input[type="text"]::-webkit-search-results-button,
input[type="text"]::-webkit-search-results-decoration,
input[type="password"]::-webkit-search-decoration,
input[type="password"]::-webkit-search-cancel-button,
input[type="password"]::-webkit-search-results-button,
input[type="password"]::-webkit-search-results-decoration {
    display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    font-size: 14px;
    border: solid 1px #ddd;
}

.form-control.form-control-danger {
    border: 1px solid #ff4451;
}

.form-control:disabled {
    background: #f3f3f3;
    color: #aaaaaa;
}

select.form-control {
    background: url(../images/iconSelectBox.svg) no-repeat scroll right 12px
        center #fff;
    padding-right: 32px;
}

select.form-control-gray {
    background: url(../images/iconSelectBox.svg) no-repeat scroll right 12px
        center #f9f9f9;
    border: 1px solid #ececec;
}

select::-ms-expand {
    display: none;
}

textarea.form-control {
    resize: none;
}

.form-icon {
    position: relative;
}

.form-icon.icon-left .icon {
    left: 20px;
}

.form-icon.icon-left .form-control {
    padding-left: 50px;
}

.form-icon.icon-right .icon {
    right: 20px;
}

.form-icon.icon-right .form-control {
    padding-right: 50px;
}

.form-icon .icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
}

/***/
.quantity-input {
    position: relative;
}

.quantity-input .form-control {
    border-radius: 6px;
    height: 32px;
    padding: 0 25px;
    text-align: center;
    font-weight: 600;
}

.quantity-input .btn {
    position: absolute;
    top: 0;
    width: 26px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-input .btn:focus {
    box-shadow: none;
}

.quantity-input .btn.quantity-left-minus {
    left: 0;
}

.quantity-input .btn.quantity-right-plus {
    right: 0;
}

/****/
.form-icon {
    position: relative;
}

.form-icon.icon-left .icon {
    left: 14px;
}

.form-icon.icon-left .form-control {
    padding-left: 50px;
}

.form-icon.icon-right .icon {
    right: 14px;
}

.form-icon.icon-right .form-control {
    padding-right: 50px;
}

.form-icon .icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
}

.pagination {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pagination ul {
    display: flex;
    flex-wrap: wrap;
}

.pagination ul li {
    padding: 0 6px;
}

@media (max-width: 991px) {
    .pagination ul li {
        padding: 0 2px;
    }
}

.pagination ul li a,
.pagination ul li span {
    /*width: 36px;*/
    cursor: pointer;
    min-width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    background: #fff;
    border: solid 1px #eee;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #444;
    font-weight: 500;
    padding: 0 3px;
}

@media (max-width: 991px) {
    .pagination ul li a,
    .pagination ul li span {
        min-width: 30px;
        height: 30px;
    }
}

.pagination ul li a.active,
.pagination ul li span.active {
    background: #34beef;
    border: 1px solid #34beef;
    color: #fff;
}

.pagination .pagination-note {
    width: 100%;
    color: #494949;
    font-size: 13px;
    margin-top: 24px;
    text-align: center;
}

.navFooter {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #eee;
}

.navFooter .active a {
    color: #34beef;
}

@media (max-width: 767px) {
    .navFooter {
        display: block;
    }
}

.navFooter ul {
    display: flex;
    flex-wrap: wrap;
}

.navFooter ul li {
    width: 20%;
    text-align: center;
}

.navFooter ul li a {
    color: #aaa;
    font-size: 10px;
    font-weight: 500;
    padding: 8px 0;
    display: block;
}

.navFooter ul li a:hover {
    color: #34beef;
}

.navFooter .navFooter-item {
    height: 24px;
}

.navFooter .navFooter-item .svg path {
    fill: #aaa;
}

.navFooter ul li a:hover .navFooter-item .svg path {
    fill: #34beef;
}

.dropdown--notification {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 10px 0 0;
    padding: 0;
    border: none;
    border-radius: 12px;
    min-width: 375px;
}

@media (max-width: 991px) {
    .dropdown--notification {
        min-width: 70vw;
    }
}

.notification {
    background: #fff;
    max-height: 400px;
    overflow-y: auto;
}

.notification .notification-group:first-of-type {
    /*border-bottom: 8px solid #F3F3F3;*/
    padding-bottom: 12px;
    margin-bottom: 8px;
}

.notification .notification-title {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notification .notification-title .notification-tlt {
    font-size: 18px;
    font-weight: 600;
}

.notification .notification-title a {
    color: #34beef;
    font-size: 13px;
}

.notification-lst ul li.active {
    background: #e7fcfd;
}

.notification-lst ul li a {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 12px 16px;
}

.notification-lst .notification-thumb {
    width: 44px;
    position: relative;
}

.notification-lst .notification-thumb .notification-avatar {
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 50%;
}

.notification-lst .notification-thumb .notification-item {
    position: absolute;
    bottom: 0;
    right: -3px;
}

.notification-lst .notification-content {
    width: calc(100% - 44px);
    padding-left: 12px;
}

.notification-lst .notification-content .tlt {
    font-size: 14px;
    font-weight: 600;
    color: #222;
}

.notification-lst .notification-content .text {
    font-size: 13px;
    color: #444;
}

.notification-lst .notification-content .time {
    color: #aaa;
    font-size: 12px;
}

/****/
.filter > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}

.filter > ul > li {
    padding-left: 8px;
    margin-top: 8px;
}

.filter > ul > li:last-child .dropdown-menu {
    left: inherit !important;
    right: 0 !important;
}

/*****/
.filterItem.show .btn--dropdown {
    background: #e7fcfd;
    color: #34beef;
    border: 1px solid #34beef;
}

.filterItem.show .btn--dropdown::after {
    background: url(../images/icon-arrow-listNav-active.svg) no-repeat;
}

/*****/
.header .headerTop {
    border-bottom: solid 1px #f3f3f3;
    padding: 20px 0;
}

@media (max-width: 767px) {
    .header .headerTop {
        border: none;
        padding: 12px 0;
    }
}

.header .headerTop-dflex {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.header .headerInfo {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 55%;
}

@media (max-width: 991px) {
    .header .headerInfo {
        width: inherit;
    }
}

@media (max-width: 767px) {
    .header .headerInfo {
        width: 109px;
    }
}

.header .headerInfo .logo {
    width: 140px;
}

@media (max-width: 767px) {
    .header .headerInfo .logo {
        display: none;
    }
}

.header .headerInfo .head-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-left: 12px;
    margin-left: 15px;
    color: #666;
    border-left: 1px solid #ddd;
}

.header .headerInfo .gNav {
    width: calc(100% - 140px);
}

@media (max-width: 767px) {
    .header .headerInfo .gNav {
        display: none;
    }
}

.header .headerInfo .gNav ul {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.header .headerInfo .gNav ul li {
    padding-left: 40px;
}

@media (max-width: 991px) {
    .header .headerInfo .gNav ul li {
        padding-left: 24px;
    }
}

.header .headerInfo .gNav ul li a {
    color: #444;
    line-height: 40px;
    font-weight: 600;
}

.header .headerInfo .gNav ul li a:hover {
    color: #34beef;
}

.header .headerInfo .gNav ul li a.active {
    color: #34beef;
}

.header .headerControl .searchTb {
    display: none;
    width: 100%;
    text-align: right;
}

@media (max-width: 991px) {
    .header .headerControl .searchTb {
        display: block;
        width: 100%;
        padding-right: 0px;
    }
}

.header .headerControl .searchTb .btn:focus {
    box-shadow: none;
}

.header .headerControl .searchTb .dropdown-menu {
    width: 300px;
    top: -40px !important;
    padding: 0;
    border: none;
    margin-top: 0;
}

.header .headerControl .searchTb .dropdown-menu .search-wrap {
    position: relative;
}

.header .headerControl .searchTb .dropdown-menu .form-control {
    height: 40px;
    padding-left: 44px;
}

.header .headerControl .searchTb .dropdown-menu .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 40px;
}

.header .headerControl .search {
    position: relative;
    width: calc(100% - 212px);
}

/* @media (max-width: 767px) {
    .header .headerControl .search {
        width: 100%;
    }
} */

.header .headerControl .search .form-control {
    background: #f8f8f8;
    border: none;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    height: 40px;
    padding-left: 44px;
}

.header .headerControl .search .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 40px;
}

.header .headerControl .search .btn:focus {
    box-shadow: none;
}

@media (max-width: 991px) {
    .header .headerControl .search {
        display: block;
    }
}

@media (max-width: 767px) {
    .header .headerControl .search {
        display: block;
    }
}

.headerLog > ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.headerLog > ul > li {
    min-width: 22px;
}

.headerLog-cart,
.headerLog-notification {
    position: relative;
}

.headerLog > ul > li.headerLog-cart a,
.headerLog > ul > li.headerLog-notification a {
    position: relative;
}

.headerLog > ul > li.headerLog-cart a span,
.headerLog > ul > li.headerLog-notification a span {
    position: absolute;
    top: -6px;
    right: -3px;
    width: 12px;
    height: 13px;
    background: #ff4451;
    justify-content: center;
    overflow: hidden;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
}

.headerLog > ul > li.headerLog-cart a span.two,
.headerLog > ul > li.headerLog-notification a span.two {
    right: -6px;
    width: 18px;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    -o-border-radius: 100px;
    -ms-border-radius: 100px;
}

.headerLog > ul > li.headerLog-info {
    padding-left: 12px;
    position: relative;
}

@media (max-width: 991px) {
    .headerLog > ul > li.headerLog-info {
        white-space: nowrap;
    }
}

.headerLog > ul > li.headerLog-info::after {
    position: absolute;
    top: 6px;
    right: -6px;
    content: "";
    display: block;
    background: #eaeaea;
    height: 12px;
    width: 1px;
}

.headerLog > ul > li.headerLog-info:last-child::after {
    right: 0;
    background: none;
}

.header
    .headerControl
    .headerLog
    > ul
    > li.headerLog-notification
    .notification-show {
    display: none;
}

.headerLog-user {
    position: relative;
    min-width: 50px;
}

.headerLog-user a {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.headerLog-user a span {
    margin-left: 8px;
}

.headerLog-user .thumb-user {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
}

/***/
.btnNav {
    display: none;
}

@media (max-width: 767px) {
    .btnNav {
        display: block;
    }
}

.btnNav .btn--nav {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
}

.btnNav .btn--nav span {
    width: 20px;
    height: 2px;
    background: #333;
    display: block;
    margin: 0 auto 6px;
    transition: all 200ms ease 0s;
}

.btnNav .btn--nav span:last-child {
    margin-bottom: 0;
}

.btnNav .btn--nav:focus {
    box-shadow: none;
}

.btnNav--active .btn--nav {
    padding-top: 6px;
}

.btnNav--active .btn--nav span:nth-of-type(1) {
    transform: translateY(5px) rotate(-44deg);
}

.btnNav--active .btn--nav span:nth-of-type(2) {
    opacity: 0;
}

.btnNav--active .btn--nav span:nth-of-type(3) {
    transform: translateY(-11px) rotate(44deg);
}

/*** headerSp ****/
.headerSp {
    background: #fff;
}

.headerSp .wrap {
    padding-top: 10px;
    padding-bottom: 10px;
}

.headerSp .headerNav .wrap {
    padding-top: 0;
    padding-bottom: 0;
}

.headerSp .headerWrap .header-col62 ul {
    display: flex;
    flex-wrap: wrap;
}

.headerSp .headerWrap .header-col62 ul li {
    width: 50%;
}

.headerSp .headerWrap .header-col62 .searchTb .dropdown-menu {
    width: 300px;
    top: -32px !important;
    padding: 0;
    border: none;
    margin-top: 0;
}

.headerSp .headerWrap .header-col62 .searchTb .dropdown-menu .search-wrap {
    position: relative;
}

.headerSp .headerWrap .header-col62 .searchTb .dropdown-menu .form-control {
    height: 40px;
    padding-left: 44px;
}

.headerSp .headerWrap .header-col62 .searchTb .dropdown-menu .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 40px;
}

/*** navUser ***/
.navUser {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 10px 0 0;
    padding: 0;
    border: none;
    border-radius: 12px;
    min-width: 208px;
}

.navUser ul li a {
    color: #222;
    padding: 16px 16px;
    display: block;
    font-size: 14px;
}

.navUser ul li a:hover {
    background: #e7fcfd;
    color: #34beef;
}

.footer {
    background: #f8f8f8;
    padding-top: 40px;
}

@media (max-width: 767px) {
    /* .footer {
        display: none;
    } */
}

.footer .copyright {
    color: #b0b0b0;
    margin-top: 30px;
    padding: 6px 0;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    border-top: solid 2px #eee;
}

.footer .footerWrap {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5%;
}

.footer .footerWrap .footerCompany {
    width: 45%;
    padding-left: 5%;
    margin-top: 25px;
}

@media (max-width: 991px) {
    .footer .footerWrap .footerCompany {
        width: 100%;
        order: 2;
    }
}

.footer .footerWrap .footerInfo {
    width: 55%;
    padding-left: 5%;
    margin-top: 25px;
}

@media (max-width: 991px) {
    .footer .footerWrap .footerInfo {
        width: 100%;
        order: 1;
    }
}

.footer .footerWrap .footerInfo .footerNav {
    width: 35%;
    padding-left: 5%;
}

@media (max-width: 991px) {
    .footer .footerWrap .footerInfo .footerNav {
        width: 50%;
    }
}

.footer .footerWrap .footerInfo .footerIntrol {
    width: 65%;
    padding-left: 5%;
}

@media (max-width: 991px) {
    .footer .footerWrap .footerInfo .footerIntrol {
        width: 50%;
    }
}

.footer .company {
    border-bottom: 2px solid #ddd;
    padding-bottom: 14px;
}

@media (max-width: 991px) {
    .footer .company {
        border-top: 2px solid #ddd;
        padding-top: 24px;
    }
}

.footer .company .company-name {
    font-weight: 600;
}

.footer .company .company-info {
    margin-top: 10px;
    font-size: 13px;
    color: #444;
    font-weight: 500;
}

.footer .company .company-info .item {
    margin-right: 9px;
    margin-top: 2px;
    display: inline-flex;
}

.footer .company .company-info a {
    color: #444;
}

.footer .footer-papers .footer-text {
    margin-top: 12px;
    font-size: 13px;
    font-weight: 400;
}

.footer .footer-papers .footer-text span {
    font-size: 13px;
    font-weight: 400;
}

.footer .footerNavigation li {
    margin-bottom: 8px;
}

.footer .footerNavigation li:last-child {
    margin-bottom: 0;
}

.footer .footerNavigation li a {
    color: #444;
    font-weight: 600;
    font-size: 13px;
}

.footer .signForm .signForm-title {
    color: #444;
    font-weight: bold;
}

.footer .signForm .signForm-input {
    margin-top: 12px;
    position: relative;
}

.footer .signForm .signForm-input .form-control {
    height: 48px;
    padding-right: 95px;
    font-size: 16px;
}

.footer .signForm .signForm-input .btn {
    width: 88px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 0;
}

.footer .signForm .signForm-input .btn span {
    padding-right: 10px;
}

.footer .footerShare {
    margin-top: 24px;
}

.footer .footerShare .footerShare-title {
    color: #444;
    font-weight: bold;
}

.footer .footerShare .footerShare-app {
    display: flex;
    flex-wrap: wrap;
}

.footer .footerShare .footerShare-app li {
    margin-top: 12px;
    padding-right: 12px;
}

.footer .footerShare .footerShare-app li:last-child {
    padding-right: 0;
}

.footer .footerShare .footerShare-item {
    display: flex;
    flex-wrap: wrap;
}

.footer .footerShare .footerShare-item li {
    margin-top: 32px;
    padding-right: 24px;
}

.footer .footerShare .footerShare-item li:last-child {
    padding-right: 0;
}

.btnChat {
    position: fixed;
    bottom: 20px;
    right: 24px;
    height: 44px;
    width: 44px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    cursor: pointer;
}

@media (max-width: 767px) {
    .groupChart {
        display: none;
    }

    .footer .footerShare .footerShare-item li {
        margin-top: 32px;
        padding-right: 8px;
    }
}

@media (max-width: 767px) {
    .btnChat {
        bottom: 70px;
        border-radius: 16px 0 0 16px;
    }
}

.btnChat .btnChat-number {
    position: absolute;
    right: 10px;
    top: 0;
}

.btnChat .btnChat-number span {
    position: absolute;
    top: -3px;
    right: -6px;
    background: #ff4451;
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    overflow: hidden;
    border-radius: 50%;
}

.chart-info .btnChat-number span {
    position: absolute;
    top: -3px;
    right: -6px;
    background: #ff4451;
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    overflow: hidden;
    border-radius: 50%;
}

/*** chatContent ***/
.chatContent {
    position: fixed;
    bottom: 8px;
    right: 76px;
    z-index: 101;
    background: #fff;
    width: 668px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

@media (max-width: 991px) {
    .chatContent {
        width: 630px;
    }
}

@media (max-width: 767px) {
    .chatContent {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.chatContent .chatHeader {
    background: #34beef;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px 8px 0 0;
    padding: 10px 0;
}

@media (max-width: 991px) {
    .chatContent .chatHeader {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .chatContent .chatHeader {
        display: none;
    }
}

.chatContent .chatHeader__search {
    width: calc(100% - 60px);
    position: relative;
}

.chatContent .chatHeader__search .form-control {
    height: 50px;
    border: none;
    background: none;
    padding-left: 70px;
    color: #fff;
}

@media (max-width: 991px) {
    .chatContent .chatHeader__search .form-control {
        height: 44px;
    }
}

.chatContent .chatHeader__search .form-control::-webkit-input-placeholder,
.chatContent .chatHeader__search .form-control::-moz-placeholder,
.chatContent .chatHeader__search .form-control:-ms-input-placeholder,
.chatContent .chatHeader__search .form-control::-ms-input-placeholder,
.chatContent .chatHeader__search .form-control::placeholder {
    color: #fff;
}

.chatContent .chatHeader__search .form-control:focus {
    box-shadow: none;
}

.chatContent .chatHeader__search .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 50px;
}

.chatContent .chatHeader__search .btn:focus {
    box-shadow: none;
}

@media (max-width: 991px) {
    .chatContent .chatHeader__search .btn {
        height: 44px;
    }
}

.chatContent .chatHeader__close {
    width: 60px;
}

.chatContent .chatHeader__close .btn {
    width: 100%;
    height: 50px;
}

.chatContent .chatHeader__close .btn:focus {
    box-shadow: none;
}

@media (max-width: 991px) {
    .chatContent .chatHeader__close .btn {
        height: 44px;
    }
}

.chatContent .chatMain {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .chatContent .chatMain {
        height: 100%;
    }
}

.chatContent .chatMain .sideBar {
    width: 276px;
}

@media (max-width: 991px) {
    .chatContent .chatMain .sideBar {
        width: 260px;
    }
}

@media (max-width: 767px) {
    .chatContent .chatMain .sideBar {
        width: 100%;
        height: 100%;
    }
}

.chatContent .chatMain .sideBar > ul {
    overflow-y: auto;
    max-height: 500px;
    min-height: 450px;
    padding: 0 8px;
    background: #fff;
}

@media (max-width: 767px) {
    .chatContent .chatMain .sideBar > ul {
        max-height: inherit;
        min-height: inherit;
        height: calc(100% - 48px);
        padding: 16px;
    }
}

.chatContent .chatMain .sideBar > ul > li {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #fff;
    padding: 8px;
    cursor: pointer;
}

.chatContent .chatMain .sideBar > ul > li.active {
    background: #e7fcfd;
    box-shadow: none;
}

.chatContent .chatMain .sideBar .chart-thumb {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
}

.chatContent .chatMain .sideBar .chart-info {
    position: relative;
    width: calc(100% - 30px);
    padding-left: 12px;
    padding-right: 25px;
}

.chatContent .chatMain .sideBar .chart-info .color-blue {
    color: #34beef;
}

.chatContent .chatMain .sideBar .chart-info .chart-name {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.chatContent .chatMain .sideBar .chart-info .chart-name .chartOn {
    width: 6px;
    height: 6px;
    background: #ff4451;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 8px;
    margin-left: 1px;
}

.chatContent .chatMain .sideBar .chart-info .chart-text {
    font-size: 12px;
    color: #666;
    margin-top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.chatContent .chatMain .sideBar .chart-info .chart-date {
    font-size: 10px;
    color: #666;
    margin-top: 5px;
}

.chatContent .chatMain .chatForm {
    width: calc(100% - 276px);
    background: #f8f8f8;
}

@media (max-width: 991px) {
    .chatContent .chatMain .chatForm {
        width: calc(100% - 260px);
    }
}

@media (max-width: 767px) {
    .chatContent .chatMain .chatForm {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
    }
}

.chatContent .scrollBar {
    padding: 8px;
    overflow-y: auto;
    max-height: 392px;
    min-height: 392px;
}

@media (max-width: 767px) {
    .chatContent .scrollBar {
        max-height: inherit;
        min-height: inherit;
        height: calc(100% - 106px);
    }
}

.chat-form-logo {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.chatContent .chatPro a {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
}

.chatContent .chatPro .chatPro__thumb {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 8px;
}

.chatContent .chatPro .chatPro__content {
    width: calc(100% - 64px);
    padding-left: 8px;
}

.chatContent .chatPro .chatPro__content .tlt {
    font-weight: 500;
    color: #222;
}

.chatContent .chatPro .chatPro__content .view .view-evaluate {
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    margin-right: 8px;
}

.chatContent .chatPro .chatPro__content .view .view-evaluate img {
    margin-right: 4px;
}

.chatContent .chatPro .chatPro__content .view .view-review {
    font-size: 12px;
    color: #666;
    font-weight: 500;
}

.chatContent .chatPro .chatPro__content .price .price-discount {
    color: #ff4451;
    font-weight: 600;
    margin-right: 4px;
}

.chatContent .chatPro .chatPro__content .price .price-number {
    color: #34beef;
    font-weight: 600;
}

.chatContent .chartText .chartYou {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.chatContent .chartText .chartYou .chartYou__thumb {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.chatContent .chartText .chartYou .chartYou__text {
    width: calc(100% - 40px);
    max-width: 250px;
    padding-left: 8px;
}

.chatContent .chartText .chartYou .chartYou__text p {
    /* margin-bottom: 8px; */
    background: #eee;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    max-width: 100%;
}

/* .chatContent .chartText .chartYou .chartYou__text p span {
    background: #eee;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
} */

.chatContent .chartText .chartMe {
    /* text-align: right; */
    /* margin-top: 15px; */
    max-width: 274px;
    margin-left: auto;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
}

.chatContent .chartText .chartMe p {
    /* padding-bottom: 8px; */
    background: #34beef;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    color: #fff;
    max-width: 100%;
}

/* .chatContent .chartText .chartMe p span {
    background: #34beef;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    color: #fff;
} */

.chatContent .chartText .chart-img {
    background: #fff !important;
    padding: 8px;
    border-radius: 8px;
    /* width: 100%;
    height: 240px; */
}

.chat-img img {
    max-width: 154px;
    height: auto;
}

/* .chatContent .chartText .chart-img img {
    max-width: 154px;
} */

.chartYou + .chartMe,
.chartMe + .chartYou {
    margin-top: 16px;
}

.chatContent .chartInput {
    background: #fff;
    padding: 6px 0 12px;
    min-height: 58px;
    display: flex;
    flex-wrap: wrap;
}

.chatContent .chartInput .chartImages,
.chatContent .chartInput .chartSend {
    width: 52px;
}

.chatContent .chartInput .chartImages .btn,
.chatContent .chartInput .chartSend .btn {
    width: 100%;
    height: 40px;
    padding: 0;
    border: none;
}

.chatContent .chartInput .chartImages .btn:focus,
.chatContent .chartInput .chartSend .btn:focus {
    box-shadow: none;
}

.chatContent .chartInput .chartImport {
    width: calc(100% - 104px);
    position: relative;
}

.chatContent .chartInput .chartImport .form-control {
    height: 40px;
    border-radius: 24px;
    background: #f8f8f8;
    border: solid 1px #f3f3f3;
    padding-right: 55px;
    padding-top: 0;
    padding-bottom: 0;
}

.chatContent .chartInput .chartImport .btn {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 24px;
    width: 48px;
    padding: 0;
    height: 40px;
}

.chatContent .chartInput .chartImport .btn:focus {
    box-shadow: none;
}

.chatContent .chart-more {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
}

.chatContent .chart-more .btn {
    padding: 0;
    width: 100%;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatContent .chart-more .btn:focus {
    box-shadow: none;
    background: #f3f3f3;
}

.chatContent .chart-more .btn.show-is {
    background: #f3f3f3;
}

.dropdown-menu-more {
    border: none;
    padding: 0;
    border-radius: 12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    min-width: 240px;
    background: #fff;
}

@media (max-width: 767px) {
    .dropdown-menu-more {
        position: fixed !important;
        transform: none !important;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0;
        margin: 0;
    }

    .dropdown-menu-more ul {
        background: #fff;
        border-radius: 16px 16px 0 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.dropdown-menu-more > ul > li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    cursor: pointer;
    padding: 12px 12px 12px 0;
}

.dropdown-menu-more > ul > li:last-child {
    bottom: none;
}

.dropdown-menu-more .more-icon {
    width: 50px;
    text-align: center;
}

.dropdown-menu-more .more-text {
    width: calc(100% - 50px);
}

.dropdown-menu-more .more-text .tlt {
    font-weight: 600;
}

.dropdown-menu-more .more-text .text {
    font-size: 12px;
    color: #666;
}

.chatheader-sp {
    flex-wrap: wrap;
    align-items: center;
    padding: 4px;
    background: #fff;
    height: 48px;
    display: none;
}

@media (max-width: 767px) {
    .chatheader-sp {
        display: flex;
    }
}

.chatheader-sp .chatheader-col {
    width: 56px;
    position: relative;
}

.chatheader-sp .chatheader-col .btn {
    padding: 0;
    height: 40px;
    width: 100%;
}

.chatheader-sp .chatheader-col .btn:focus {
    box-shadow: none;
}

.chatheader-sp .chatheader-text {
    width: calc(100% - 112px);
    text-align: center;
}

.chatheader-sp .chatheader-text .tlt,
.chatheader-sp .chatheader-text .date {
    overflow: hidden;
    white-space: nowrap;
    white-space: nowrap;
}

.chatheader-sp .chatheader-text .tlt {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.chatheader-sp .chatheader-text .date {
    color: #666;
    font-size: 10px;
}

.dropdown-menu-search {
    position: fixed !important;
    top: 0 !important;
    left: inherit !important;
    right: 0 !important;
    transform: none !important;
    padding: 4px;
    border: none;
    margin: 0;
    width: calc(100% - 56px);
}

.dropdown-menu-search .search {
    display: flex;
}

.dropdown-menu-search .search .search-input {
    width: calc(100% - 50px);
    position: relative;
}

.dropdown-menu-search .search .search-input .form-control {
    background: #f3f3f3;
    height: 40px;
    padding: 0 36px 0 44px;
    border: none;
}

.dropdown-menu-search .search .search-input .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 40px;
}

.dropdown-menu-search .search .search-input .btn:focus {
    box-shadow: none;
}

.dropdown-menu-search .search .search-input .search-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-menu-search .search .btn--cancel {
    padding: 0;
    width: 50px;
}

.dropdown-menu-search .search .btn--cancel:focus {
    box-shadow: none;
}

.chatHeader .form-control::placeholder {
    color: #fff;
}

.banner {
    overflow: hidden;
}

.main--shop {
    padding-bottom: 56px;
}

@media (max-width: 767px) {
    .main--shop {
        padding-bottom: 30px;
    }
}

.main--shop .serviceList ul {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -10px;
}

.main--shop .serviceList ul li {
    width: 13%;
    padding-left: 10px;
    margin-top: 40px;
    text-align: center;
}

@media (max-width: 991px) {
    .main--shop .serviceList ul li {
        width: 23%;
    }
}

@media (max-width: 767px) {
    .main--shop .serviceList ul li {
        width: 28%;
    }
}

.main--shop .serviceList ul li.more {
    display: none;
}

@media (max-width: 767px) {
    .main--shop .serviceList ul li.more {
        display: block;
    }
}

.main--shop .serviceList ul li .serviceList_thumb {
    height: 75px;
    align-items: center;
    justify-content: center;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main--shop .serviceList ul li a {
    font-size: 12px;
    color: #666;
    display: block;
}

.main--shop .serviceList ul li .link-more {
    font-size: 12px;
    color: #666;
    display: block;
    cursor: pointer;
}

.main--shop .serviceList ul li .serviceList_more {
    height: 75px;
    align-items: center;
    justify-content: center;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main--shop .serviceList ul li .serviceList_more span {
    width: 3.4rem;
    height: 3.4rem;
    background: #e7fcfd;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.titleShop {
    margin-bottom: 24px;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.titleShop .titleShop-title {
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .titleShop {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .titleShop .titleShop-title {
        font-size: 18px;
        font-weight: 600;
    }
}

.titleShop .titleShop-title span {
    padding-right: 9px;
}

.titleShop .titleShop-title .titleShop-number {
    padding: 0 0 0 8px;
    color: #34beef;
    font-size: 20px;
    font-weight: 600;
    vertical-align: middle;
}

@media (max-width: 767px) {
    .titleShop .titleShop-title .titleShop-number {
        font-size: 16px;
    }
}

.titleShop a {
    color: #34beef;
}

.dealHot {
    margin-top: 24px;
    margin-left: -20px;
    padding: 0 20px;
    overflow: hidden;
    width: calc(100% + 40px);
}

@media (max-width: 767px) {
    .dealHot {
        margin-top: 16px;
    }
}

@media (max-width: 767px) {
    .dealHot .slider {
        margin-left: -122px;
    }
}

.dealHot .slider .slick-list {
    margin-left: -20px;
    padding: 0 2px 4px;
}

@media (max-width: 767px) {
    .dealHot .slider .slick-list {
        margin-left: -12px;
    }
}

@media (max-width: 767px) {
    .dealHot .slider .slick-list .slick-track {
        padding: 4px;
    }
}

.dealHot .slider .slick-list .slick-slide {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .dealHot .slider .slick-list .slick-slide {
        padding-left: 12px;
    }
}

.dealHot .dealHot-thumb {
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}

.dealHot .dealHot-thumb img {
    width: 100%;
}

.dealHot .dealHot-title {
    padding: 12px 16px 16px;
    height: 66px;
    font-weight: 500;
    font-size: 16px;
}

.dealHot .dealHot-info a {
    display: block;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: #fff;
    color: #222;
    font-size: 16px;
}

.flashSale {
    margin-top: 24px;
    margin-left: -20px;
    overflow: hidden;
    padding: 0 20px;
    width: calc(100% + 40px);
}

@media (max-width: 767px) {
    .flashSale {
        margin: 0;
        padding: 0;
        width: auto;
    }
}

/* @media (max-width: 767px) {
    .flashSale .sliderFlashSale {
        display: none;
    }
} */

.flashSale .flashSale-sp {
    display: none;
}

@media (max-width: 767px) {
    .flashSale .flashSale-sp {
        display: block;
    }
}

.flashSale .flashSale-sp .flashSale-info {
    margin-top: 16px;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flashSale .flashSale-sp .flashSale-info .flashSale-thumb.link-image-area {
    width: 148px;
    padding: 0px;
}

.flashSale .flashSale-sp .flashSale-info .flashSale-content {
    width: calc(100% - 148px);
    padding-left: 12px;
}

.flashSale .flashSale-sp .flashSale-info .flashSale-pice {
    margin-bottom: 4px;
}

.flashSale .flashSale-sp .more {
    margin-top: 24px;
}

.flashSale .flashSale-sp .more a {
    padding: 10px;
    background: #e7fcfd;
    display: block;
    color: #34beef;
    border-radius: 6px;
    text-align: center;
}

.flashSale .slider .slick-list {
    margin-left: -20px;
}

.flashSale .slider .slick-list .slick-slide {
    padding-left: 20px;
}

.flashSale .slick-arrow {
    background: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    top: 100px;
    z-index: 1;
}

.flashSale .slick-arrow.slick-prev {
    left: -16px;
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
    transform: rotate(180deg);
    top: calc(40% - 32px);
}

.flashSale .slick-arrow.slick-next {
    right: -16px;
    top: calc(40% - 16px);
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
}

@media (max-width: 767px) {
    .flashSale-info.flashSale-info-sp {
        display: flex;
        flex-wrap: wrap;
    }

    .flashSale-info.flashSale-info-sp .flashSale-thumb {
        width: 148px;
    }

    .flashSale-info.flashSale-info-sp .flashSale-content {
        width: calc(100% - 148px);
        padding-left: 12px;
    }

    .flashSale-info.flashSale-info-sp .flashSale-content .flashSale-maya {
        margin-top: 0;
    }

    .flashSale-info.flashSale-info-sp .flashSale-content .flashSale-title,
    .flashSale-info.flashSale-info-sp .flashSale-content .flashSale-number,
    .flashSale-info.flashSale-info-sp .flashSale-content .flashSale-piceNumber {
        font-size: 16px;
    }
}

.flashSale-info .flashSale-numberBuyers {
    display: none;
    position: absolute;
    bottom: 4px;
    left: 8px;
}

@media (max-width: 767px) {
    .flashSale-info .flashSale-numberBuyers {
        display: block;
    }
}

.flashSale-info .flashSale-numberBuyers span {
    background: rgba(34, 34, 34, 0.2);
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    padding: 2px 4px;
    display: inline-block;
}

.flashSale-info .flashSale-thumb {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.flashSale-info .flashSale-thumb a img {
    width: 100%;
}

.flashSale-info .flashSale-thumb .flashSale-time {
    position: absolute;
    top: 12px;
    left: 0;
    padding: 0 12px;
    width: 100%;
}

@media (max-width: 767px) {
    .flashSale-info .flashSale-thumb .flashSale-time {
        top: 8px;
        padding: 0 8px;
    }
}

.flashSale-info .flashSale-thumb .flashSale-time span {
    background: #ff7572;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 2px 6px 0;
}

@media (max-width: 767px) {
    .flashSale-info .flashSale-thumb .flashSale-time span {
        font-size: 10px;
        padding: 2px 6px;
    }
}

.flashSale-info .flashSale-thumb .flashSale-time span img {
    padding-right: 6px;
}

.flashSale-info .flashSale-thumb .flashSale-bookmark {
    width: 16px;
    height: 20px;
    position: absolute;
    bottom: 10px;
    right: 12px;
}

@media (max-width: 767px) {
    .flashSale-info .flashSale-thumb .flashSale-bookmark {
        bottom: 12px;
        right: 8px;
        width: 13px;
        height: 16px;
    }
}

.flashSale-info .flashSale-thumb .flashSale-numberBuyers {
    position: absolute;
    bottom: 4px;
    left: 8px;
}

.flashSale-info .flashSale-thumb .flashSale-numberBuyers span {
    background: rgba(34, 34, 34, 0.2);
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    padding: 2px 4px;
    display: inline-block;
}

.flashSale-info .flashSale-maya {
    margin-top: 8px;
    font-size: 12px;
    color: #aaa;
}

.flashSale-info .flashSale-title a {
    color: #222;
    font-weight: 500;
}

.flashSale-info .flashSale-view {
    margin-top: 4px;
}

.flashSale-info .flashSale-view .flashSale-number {
    font-size: 12px;
    font-weight: 600;
    color: #222;
    display: inline-flex;
}

.flashSale-info .flashSale-view .flashSale-number img {
    /* margin-right: 3px; */
    margin-top: -2px !important;
}

.flashSale-info .flashSale-view .flashSale-numberItem {
    font-size: 12px;
    color: #666;
    padding-left: 4px;
}

.flashSale-info .flashSale-pice .flashSale-number {
    color: #ff4451;
    font-weight: 600;
    padding-right: 4px;
}

.flashSale-info .flashSale-pice .flashSale-piceNumber {
    color: #34beef;
    font-weight: 600;
    padding-right: 4px;
}

.flashSale-info .flashSale-pice .flashSale-label {
    background: #f8f8f8;
    border-radius: 4px;
    font-size: 12px;
    color: #666;
    padding: 2px 4px;
    display: inline-block;
}

.tabsHot {
    margin-top: 10px;
}

.tabsHot .nav {
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
    width: 100%;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@media (max-width: 767px) {
    .tabsHot .nav {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px;
        border-bottom: solid 1px #f3f3f3;
    }
}

.tabsHot .nav li {
    display: inline-block;
    padding-right: 25px;
}

.tabsHot .nav li:last-child {
    padding-right: 0;
}

.tabsHot .nav li span {
    color: #444;
    padding: 7px 0;
    display: block;
    position: relative;
    cursor: pointer;
}

.tabsHot .nav li span.active {
    color: #34beef;
}

.tabsHot .nav li span.active:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background: #34beef;
}

.prohot {
    margin-top: 24px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .prohot {
        margin-top: 16px;
    }
}

.prohot .slick-list {
    margin-left: -20px;
}

@media (max-width: 767px) {
    .prohot .slick-list {
        margin-left: -12px;
    }
}

.prohot .slick-list .slick-slide {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .prohot .slick-list .slick-slide {
        padding-left: 12px;
    }
}

.prohot .more a {
    background: #f3f3f3;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #666;
}

@media (max-width: 767px) {
    .prohot .more a {
        font-size: 12px;
    }
}

.prohot .more a .more-item {
    max-width: 27px;
    margin: 0 auto 15px;
}

@media (max-width: 767px) {
    .prohot .more a .more-item {
        margin: 0 auto 10px;
    }
}

.prohot-info .prohot-thumb {
    border: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
}

.prohot-info .prohot-thumb a {
    display: block;
}

.prohot-info .prohot-thumb a img {
    width: 100%;
}

.prohot-info .prohot-thumb .prohot-rectangle {
    background: url(../images/icon-rectangle.svg) no-repeat;
    width: 24px;
    height: 32px;
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-top: 3px;
}

.prohot-info .prohot-thumb .prohot-rectangle--gray {
    background: url(../images/icon-rectangle-gray.svg) no-repeat;
}

.prohot-info .prohot-thumb .prohot-bookmark {
    position: absolute;
    bottom: 8px;
    right: 10px;
    width: 16px;
    height: 20px;
}

.prohot-info .prohot-content .prohot-pit {
    padding-top: 8px;
    color: #666;
    font-size: 12px;
}

.prohot-info .prohot-content .prohot-title a {
    font-size: 16px;
    color: #222;
    font-weight: 500;
}

.prohot-info .prohot-content .prohot-view {
    padding-top: 4px;
}

.prohot-info .prohot-content .prohot-view .prohot-number {
    font-size: 12px;
    font-weight: 600;
    color: #222;
    display: inline-flex;
}

.prohot-info .prohot-content .prohot-view .prohot-number img {
    /* margin-right: 3px; */
    margin-top: -2px !important;
}

.prohot-info .prohot-content .prohot-view .prohot-numberItem {
    font-size: 12px;
    color: #666;
    padding-left: 4px;
}

@media (max-width: 767px) {
    .prohot-info .prohot-content .prohot-price {
        font-size: 12px;
    }
}

.prohot-info .prohot-content .prohot-price .prohot-number {
    color: #ff4451;
    font-weight: 600;
    padding-right: 4px;
}

@media (max-width: 767px) {
    .prohot-info .prohot-content .prohot-price .prohot-number {
        padding-right: 0;
    }
}

.prohot-info .prohot-content .prohot-price .prohot-priceNumber {
    color: #34beef;
    font-weight: 600;
}

.voucher {
    margin-top: 24px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .voucher {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .voucher .sliderVoucher {
        display: none;
    }
}

.voucher .sliderVoucher .slick-list {
    margin-left: -20px;
}

.voucher .sliderVoucher .slick-list .slick-slide {
    padding-left: 20px;
}

.voucher .voucher-sp {
    display: none;
}

@media (max-width: 767px) {
    .voucher .voucher-sp {
        display: block;
    }
}

.voucher .voucher-sp ul {
    margin-left: -12px;
    display: flex;
    flex-wrap: wrap;
}

.voucher .voucher-sp ul li {
    padding-left: 12px;
    margin-top: 16px;
    width: 50%;
}

.voucher-info .voucher-thumb {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.voucher-info .voucher-thumb a img {
    width: 100%;
}

.voucher-info .voucher-thumb .voucher-bookmark {
    position: absolute;
    bottom: 8px;
    right: 10px;
    width: 16px;
    height: 20px;
}

.voucher-info .voucher-content .voucher-title {
    margin-top: 8px;
}

.voucher-info .voucher-content .voucher-title a {
    color: #222;
    font-size: 16px;
    font-weight: 500;
}

.voucher-info .voucher-content .voucher-price {
    margin-top: 4px;
}

.voucher-info .voucher-content .voucher-price .voucher-number {
    color: #ff4451;
    font-weight: 600;
    padding-right: 4px;
}

.voucher-info .voucher-content .voucher-price .voucher-priceNumber {
    color: #34beef;
    font-weight: 600;
    padding-right: 4px;
}

.voucher-info .voucher-content .voucher-price .voucher-label {
    background: #f8f8f8;
    border-radius: 4px;
    font-size: 12px;
    color: #666;
    padding: 2px 4px;
    display: inline-block;
}

.voucher-info .voucher-content .voucher-name {
    display: flex;
    align-items: center;
    color: #444;
    margin-top: 3px;
}

.voucher-info .voucher-content .voucher-name .voucher-ellipse {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 4px;
}

.searchKey {
    margin-top: 24px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .searchKey {
        margin-top: 8px;
    }
}

@media (max-width: 991px) {
    .searchKey .sliderSearchKey {
        margin-left: -70px;
    }
}

@media (max-width: 767px) {
    .searchKey .sliderSearchKey {
        display: none;
    }
}

.searchKey .sliderSearchKey .slick-list {
    margin-left: -20px;
}

@media (max-width: 991px) {
    .searchKey .sliderSearchKey .slick-list {
        margin-left: -16px;
    }
}

.searchKey .sliderSearchKey .slick-list .slick-slide {
    padding-left: 20px;
}

@media (max-width: 991px) {
    .searchKey .sliderSearchKey .slick-list .slick-slide {
        padding-left: 16px;
    }
}

.searchKey .searchKey-sp {
    display: none;
}

@media (max-width: 767px) {
    .searchKey .searchKey-sp {
        display: block;
    }
}

.searchKey .searchKey-sp ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
}

.searchKey .searchKey-sp ul li {
    width: 50%;
    padding-left: 7px;
    margin-top: 8px;
}

.searchKey .thumbSearch {
    border-radius: 8px;
    overflow: hidden;
}

.searchKey .thumbSearch a {
    position: relative;
    display: block;
}

.searchKey .thumbSearch a img {
    width: 100%;
}

.searchKey .thumbSearch a .thumbSearch-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(34, 34, 34, 0.4);
}

.productsLst ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .productsLst ul {
        margin-left: -12px;
    }
}

.productsLst ul li {
    padding-left: 20px;
    width: calc(100% / 6);
    margin-top: 24px;
}

.productsLst-modal .modal-body {
    max-height: 80vh;
    overflow-y: scroll;
}

.productsLst-modal ul li {
    width: 25%;
}

@media (max-width: 991px) {
    .productsLst ul li {
        width: 33.333333% !important;
    }
}

@media (max-width: 767px) {
    .productsLst ul li {
        width: 50% !important;
        padding-left: 12px;
        margin-top: 16px;
    }
}

.link-image-area {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: #f2f2f2;
    padding-top: 100%;
}

.link-image-area a {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-image-area a img {
    width: auto !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 100% !important;
    object-fit: cover;
}

.slider-image-wrapper {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: #f2f2f2;
    padding-top: 100%;
}

.slider-image-wrapper img {
    width: auto !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 100% !important;
    object-fit: cover;
}

.products-thumb .products-bookmark {
    width: 16px;
    height: 20px;
    position: absolute;
    bottom: 10px;
    right: 12px;
}

.products-bookmark {
    z-index: 2;
}

.products-thumb .prohot-rectangle {
    background: url(../images/icon-rectangle.svg) no-repeat;
    width: 24px;
    height: 32px;
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-top: 3px;
}

.products-thumb .prohot-rectangle--gray {
    background: url(../images/icon-rectangle-gray.svg) no-repeat;
}

.products-thumb .out-stock-info {
    position: absolute;
    bottom: 12px;
    left: 0;
    padding: 0 12px;
    width: auto;
    background-color: rgba(90, 90, 90, 0.7);
    color: white;
}

.product-layer {
    position: absolute;
    bottom: 3px;
    left: 0;
    width: calc(100% - 30px);
    z-index: 2;
}

.product-layer div {
    display: inline-block;
    width: fit-content;
    /* max-width: 33%; */
    margin-right: 2px;
    margin-left: 2px;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 3px;
    font-weight: 500;
    padding: 3px 5px;
    color: white;
    font-weight: 600;
}

/* .products-thumb .layer-free-ship {
    line-height: 23px;
    padding-left: 3px;
    background-color: #FFD000;
    font-style: italic;
} */

.products-thumb .layer-free-text {
    max-height: 29px;
    max-width: 40%;
    border-radius: 3px;
    margin-left: 3px;
}

@media (max-width: 767px) {
    .product-layer div {
        font-size: 12px;
        margin-right: 1px;
        margin-left: 1px;
        padding: 2px 3px;
    }

    .products-thumb .layer-free-ship {
        padding-left: 1px;
    }
}

.products-thumb .flashSale-time {
    position: absolute;
    top: 12px;
    left: 0;
    padding: 0 12px;
    width: 100%;
}

@media (max-width: 767px) {
    .products-thumb .flashSale-time {
        top: 8px;
        padding: 0 8px;
    }
}

.products {
    position: relative;
}

.dealhot-tag {
    position: absolute;
    top: 12px;
    left: -8px;
    z-index: 3;
}

.dealhot-tag div {
    background: #ff7572;
    border-radius: 2px 2px 2px 0px;
    color: #fff;
    padding: 2px 6px 0;
}

.dealhot-tag img {
    margin-top: -20px;
    display: inline;
}

@media (max-width: 767px) {
    .dealhot-tag {
        top: 8px;
    }

    .dealhot-tag div {
        font-size: 12px;
    }
}

.products-thumb .flashSale-time span {
    background: #ff7572;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 2px 6px 0;
}

@media (max-width: 767px) {
    .products-thumb .flashSale-time span {
        font-size: 10px;
        padding: 2px 6px;
    }
}

.products-thumb .flashSale-time span img {
    padding-right: 6px;
    margin-bottom: 2px;
}

@media (max-width: 767px) {
    .products-thumb .products-bookmark {
        bottom: 12px;
        right: 8px;
        width: 13px;
        height: 16px;
    }
}

.products-thumb .products-numberBuyers {
    position: absolute;
    bottom: 4px;
    left: 8px;
}

.products-thumb .products-numberBuyers span {
    background: rgba(34, 34, 34, 0.2);
    border-radius: 2px;
    color: #fff;
    font-size: 10px;
    padding: 2px 4px;
    display: inline-block;
}

.products-content .products-company {
    margin-top: 8px;
    font-size: 12px;
    color: #aaa;
}

.products-content .products-title a {
    color: #222;
    font-weight: 500;
}

.products-content .products-title {
    /* height: 32px; */
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.profile-info .products-content .products-title {
    height: auto;
    overflow: none;
}

.products-content .products-view .products-number {
    font-size: 12px;
    font-weight: 600;
    color: #222;
    display: inline-flex;
}

.products-content .products-view .products-number img {
    /* margin-right: 3px !important; */
    margin-top: -2px !important;
}

.products-content .products-view .products-numberItem {
    font-size: 12px;
    color: #666;
    padding-left: 4px;
}

.products-content .products-price .products-number {
    color: #ff4451;
    font-weight: 600;
    padding-right: 4px;
}

.products-content .products-price .products-priceNumber {
    color: #34beef;
    font-weight: 600;
    padding-right: 4px;
}

.products-content .products-label {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.products-label .products-label-content {
    color: #34beef;
    background-color: #f0fbff;
    border: 1px #34beef solid;
    border-radius: 4px;
    font-size: 12px;
    padding: 2px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.discount {
    margin-bottom: 25px;
}

@media (max-width: 767px) {
    .discount {
        margin-bottom: 15px;
    }
}

.discount ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.discount ul li {
    width: 33.333333%;
    padding-left: 20px;
    margin-bottom: 24px;
}

.discount-home ul li {
    margin-bottom: 0px;
}

@media (max-width: 991px) {
    .discount ul li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .discount ul li {
        width: 100%;
        margin-bottom: 12px;
    }
}

.discountBox {
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    min-height: 94px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.discountBox::after {
    position: absolute;
    top: 10px;
    left: -1px;
    content: "";
    height: calc(100% - 20px);
    width: 5px;
    background: url(../images/bg-discount.png) repeat-y;
}

.discountBox .discount-design {
    width: 80px;
    padding-left: 6px;
    font-size: 10px;
    color: #000;
    text-align: center;
}

.discountBox .discount-design .discount-thumb {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    padding-top: 0px;
}

.discountBox .discount-content {
    width: calc(100% - 80px);
    border-left: 1px dotted #eee;
    background-color: #fff;
}

.discountBox .discount-info {
    padding: 12px 28px 12px 12px;
    position: relative;
}

.discountBox .discount-info .discount-title {
    font-size: 13px;
    font-weight: 600;
}

.discountBox .discount-info .discount-inhaus {
    font-size: 12px;
    color: #444;
    font-weight: 500;
}

.discountBox .discount-info .discount-expiryDate {
    color: #666;
    font-size: 12px;
}

.discountBox .discount-info .discount-dateTime {
    font-size: 10px;
    color: #ff4451;
    font-weight: 500;
}

.discountBox .discount-info .item-infoSquare {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 13px;
    height: 13px;
    cursor: pointer;
    background: url(../images/icon-infoSquare.svg) no-repeat center;
    display: block;
}

.discountBox .discount-info .discount-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.discountBox .discount-info .discount-btn .btn {
    height: 24px;
    font-size: 12px;
    padding: 0 9px;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 28-06-2021, 09:00:00
    Author     : HUNG.ND
*/
/*** show flash sale ***/
.main--shop._flashsale .serviceList {
    white-space: nowrap;
    overflow-x: auto;
}

@media (max-width: 767px) {
    .main--shop._flashsale .serviceList {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.main--shop._flashsale .serviceList ul {
    flex-flow: initial;
}

@media (max-width: 767px) {
    .main--shop._flashsale .serviceList ul {
        margin-left: 0;
    }
}

.main--shop._flashsale .serviceList ul li a {
    min-width: 80px;
    padding-bottom: 7px;
    border: 1px solid rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
}

.main--shop._flashsale .serviceList ul li a:hover {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
}

.main--shop._flashsale .serviceList ul li a.active {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
}

.main--shop._flashsale .serviceList ul li > div {
    min-width: 80px;
    padding-bottom: 7px;
    border: 1px solid rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
    cursor: pointer;
}

.main--shop._flashsale .serviceList ul li > div:hover {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
}

.main--shop._flashsale .serviceList ul li div.active {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
}

.main--shop._flashsale .serviceList ul li .serviceList_thumb {
    height: 75px;
}

.main--shop._flashsale .serviceList ul li {
    width: 12.5%;
    padding-left: 10px;
    margin-top: 32px;
    text-align: center;
}

@media (max-width: 991px) {
    .main--shop._flashsale .serviceList ul li {
        width: 20%;
        margin-top: 24px;
    }
}

@media (max-width: 767px) {
    .main--shop._flashsale .serviceList ul li {
        width: 25%;
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .main--shop._flashsale .serviceList ul li .serviceList_thumb img {
        max-height: 70px;
    }
}

/* @media (max-width: 767px) {
    .main--shop._flashsale .productsLst ul li {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .main--shop._flashsale .productsLst ul li .products-content {
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 767px) {
    .main--shop._flashsale .productsLst ul li .products-thumb {
        width: 148px;
    }
}

@media (max-width: 767px) {
    .main--shop._flashsale .productsLst ul li .products-content .products-content {
        width: calc(100% - 148px);
        padding-left: 10px;
    }
} */

.main--shop._flashsale .product_flash-sale {
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .main--shop._flashsale .product_flash-sale {
        margin-bottom: 25px;
    }
}

.main--shop._flashsale .product_flash-sale li {
    width: 33.33%;
}

.main--shop._flashsale .slider-ads {
    /* margin-bottom: 55px; */
}

/* @media (max-width: 767px) {
    .main--shop._flashsale .slider-ads {
        display: none;
    }
} */

.main--shop._flashsale .slider-ads .slick-dots {
    bottom: 5px;
    left: 20px;
}

.main--shop._flashsale .slider-ads .items a {
    display: block;
}

.main--shop._flashsale .slider-ads .items a img {
    border-radius: 6px;
    width: 100%;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 28-06-2021, 09:00:00
    Author     : HUNG.ND
*/
/*** show flash sale ***/
.mv-slider {
    position: relative;
    overflow: hidden;
}

@media (max-width: 767px) {
    /* .mv-slider {
        display: none;
    } */
}

.mv-slider .slick-dot {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 30px;
    z-index: 5;
}

.slick-dots {
    width: initial;
    bottom: initial;
}

.slick-dots li {
    height: 6px;
    width: 6px;
    margin: 0 4px;
}

.slick-dots li.slick-active {
    width: 32px;
}

.slick-dots li.slick-active button {
    border-radius: 30px;
    width: 32px;
    background-color: #34beef;
}

.slick-dots li button {
    background-color: #fff;
    border-radius: 50%;
    height: 6px;
    width: 6px;
    padding: 0;
}

.slider_shop .items {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-image: url("../images/shop-flashsale-img-2.png");
    height: 356px;
}

@media (max-width: 991px) {
    .slider_shop .items {
        height: 250px;
    }
}

.slider_shop .items .wrap {
    height: 100%;
}

.slider_shop .items .banner_wrap {
    display: inline-block;
    padding: 53px 80px 20px 20px;
    background: linear-gradient(
        180deg,
        #f1ede8 47.92%,
        rgba(224, 221, 216, 0) 100%
    );
}

.slider_shop .items .banner_wrap .ttl {
    font-size: 31px;
    font-weight: 600;
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .slider_shop .items .banner_wrap .ttl {
        font-size: 22px;
    }
}

.slider_shop .items .banner_wrap .ttl-sale {
    font-size: 48px;
    font-weight: 800;
    color: #34beef;
    display: block;
    margin-bottom: 20px;
    line-height: 100%;
}

@media (max-width: 991px) {
    .slider_shop .items .banner_wrap .ttl-sale {
        font-size: 32px;
    }
}

.slider_shop .items .banner_wrap .date-sale {
    display: block;
    font-size: 20px;
    color: #444444;
}

@media (max-width: 991px) {
    .slider_shop .items .banner_wrap .date-sale {
        font-size: 16px;
    }
}

.main--shop._deadhot {
    /* padding-top: 32px; */
}

/* @media (max-width: 767px) {
    .main--shop._deadhot .slider-ads {
        display: none;
    }
} */

.flashsale_wrap {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    margin-top: 32px;
}

.flashsale_wrap .flashsal_list {
    width: calc(100% - 405px);
}

@media (max-width: 991px) {
    .flashsale_wrap .flashsal_list {
        width: 100%;
    }

    .flashsale_wrap {
        margin-top: 24px;
    }
}

.flashsale_wrap .flashsal_list li {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .flashsale_wrap .flashsal_list li {
        text-align: center;
    }

    .flashsale_wrap {
        margin-top: 16px;
    }
}

.flashsale_wrap .flashsal_list li:last-child {
    margin-bottom: 0;
}

.flashsale_wrap .flashsal_list li a:hover .thumb {
    opacity: 0.8;
}

.flashsale_wrap .flashsal_list li a:hover .sale_name,
.flashsale_wrap .flashsal_list li a:hover .update {
    color: #34beef;
}

.flashsale_wrap .flashsal_list li a .thumb {
    margin-bottom: 15px;
    transition: all ease 0.3s;
}

.flashsale_wrap .flashsal_list li a .thumb img {
    width: 100%;
    border-radius: 10px;
}

.flashsale_wrap .flashsal_list li a .sale_name {
    color: #222222;
    font-weight: 600;
    margin-bottom: 5px;
    transition: all ease 0.3s;
}

.flashsale_wrap .flashsal_list li a .update {
    color: #666;
    font-size: 12px;
    display: block;
    transition: all ease 0.3s;
}

.flashsale_wrap .flashsale_bnr {
    width: 365px;
}

@media (max-width: 991px) {
    .flashsale_wrap .flashsale_bnr {
        display: none;
    }
}

.flashsale_wrap .flashsale_bnr a {
    display: block;
}

.deadhot_detail .banner {
    margin-bottom: 40px;
    background-color: #f8f8f8;
}

@media (max-width: 767px) {
    .deadhot_detail .banner {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .deadhot_detail .banner .wrap {
        padding: 0;
    }
}

.deadhot_detail .deadhot_title {
    text-align: center;
    font-size: 20px;
    /* text-transform: uppercase; */
    margin-bottom: 10px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .deadhot_detail .deadhot_title {
        font-size: 18px;
    }
}

.deadhot_detail .text-desc {
    color: #444444;
    margin-bottom: 20px;
}

.deadhot_detail .productsLst {
    max-width: 850px;
    margin: 0 auto;
}

.deadhot_detail .productsLst ul li {
    width: 33.33%;
}

.deadhot_landing .deadhot_title {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    color: #222;
}

.deadhot_landing .text-desc {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #666666;
    padding: 0px 20px;
}

.deadhot_landing .coupons-tab {
    width: 100%;
    margin-top: 64px;
}

.deadhot_landing .coupons-tab .tab-banner {
    margin-bottom: 24px;
}

.deadhot_landing .coupons-tab .tab {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    padding: 24px 8px;
    background: #f3f3f3;
}

.deadhot_landing .coupons-tab .tab a {
    color: #666666;
}

.deadhot_landing .coupons-tab .tab.active {
    background: #34beef;
}

.deadhot_landing .coupons-tab .tab.active a {
    color: #ffffff;
}

.deadhot_landing .coupons-tab .tab p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.deadhot_landing .deadhot_detail_flashsales .tab p:first-child {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.deadhot_landing .deadhot_detail_flashsales .tab p:last-child {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
}

.deadhot_landing .coupons-tab .tab {
    color: #666666;
}

.deadhot_landing .coupons-list {
    width: 100%;
    margin-top: 40px;
}

.deadhot_landing .coupons-list ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
}

.deadhot_landing .coupons-list ul li {
    width: 50%;
    padding-left: 40px;
    margin-bottom: 24px;
}

.deadhot_landing .discountBox {
    border: none;
    border-radius: 4px;
    min-height: 94px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #34beef;
}

.deadhot_landing .discountBox::after {
    position: absolute;
    top: 10px;
    left: -7px;
    content: "";
    height: calc(100% - 20px);
    max-height: 170px;
    width: 13px;
    background: url("../images/voucher-line-single.png") repeat-y;
    background-size: 100%;
}

.deadhot_landing .discountBox .discount-design {
    width: 131px;
    padding-left: 7px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.deadhot_landing .discountBox .discount-design .discount-thumb {
    width: 68px;
    height: 68px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    padding-top: 0px;
    border: 4px solid #b7dded;
}

.deadhot_landing .discountBox .discount-content {
    width: calc(100% - 131px);
    border-left: none;
    background-color: #04386c;
}

.deadhot_landing .discountBox .discount-info {
    padding: 16px 20px 16px 20px;
    position: relative;
}

.deadhot_landing .discountBox .discount-info * {
    color: #fff;
}

.deadhot_landing .discountBox .discount-info .discount-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    max-width: calc(100% - 25px);
    letter-spacing: 0em;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.deadhot_landing .discountBox .discount-info .discount-inhaus {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.deadhot_landing .discountBox .discount-info .discount-count {
    background-color: #f4f4f4;
    width: fit-content;
    border-radius: 4px;
    padding: 4px 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #666666;
}

.deadhot_landing .discountBox .discount-info .discount-expiryDate {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 44px;
}

.deadhot_landing .discountBox .discount-info .item-infoSquare {
    display: block;
    position: absolute;
    top: 24px;
    right: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background: url(../images/icon-infoSquare-white.svg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.deadhot_landing .discountBox .discount-info .discount-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.deadhot_landing .discountBox .discount-info .discount-btn .btn {
    height: auto;
    font-size: 14px;
    padding: 10px 42px;
}

.deadhot_landing .discountBox .discount-info .discount-btn .btn:disabled {
    color: #000;
}

.deadhot_landing .deadhot_detail_today {
    background-color: #04386c;
    padding: 80px 0px;
}

.deadhot_landing .deadhot_detail_today .deadhot_title {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
}

.deadhot_landing .deadhot_detail_today .text-desc {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    color: #65d2f5;
}

.deadhot_landing .deadhot_detail_today .products-content .products-title a h3 {
    /* font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em; */
    text-align: left;
    color: #fff;
}

.deadhot_landing
    .deadhot_detail_today
    .products-content
    .products-view
    .products-number {
    /* font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em; */
    text-align: left;
    color: #fff;
}

.deadhot_landing
    .deadhot_detail_today
    .products-content
    .products-view
    .products-numberItem {
    /* font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em; */
    text-align: left;
    color: #fff;
}

.deadhot_landing .deadhot_detail_today .products-content .products-priceNumber {
    /* font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em; */
    text-align: left;
}

.deadhot_landing .deadhot_detail_flashsales {
    background-color: transparent;
    padding: 40px 0px;
}

.deadhot_landing .deadhot_detail_flashsales .deadhot_title {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #666666;
}

.deadhot_landing .deadhot_detail_flashsales .text-desc {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    color: #222222;
}

.deadhot_detail_all .all-header {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
}

.deadhot_detail_all .all-title {
    width: 192px;
    height: 52px;
    background-color: #222222;
    border-radius: 52px;
    padding: 12px 16px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
}

.deadhot_detail_all .all-filter {
    width: calc(100% - 192px);
    text-align: right;
    padding-top: 6px;
}

.deadhot_landing .productsLst {
    max-width: none;
    margin: 0 auto;
}

.deadhot_landing .deadhot_detail_3 .productsLst ul li {
    width: 33.33%;
}

.deadhot_landing .deadhot_detail_4 .productsLst ul li {
    width: 25%;
}

.deadhot_landing .html-content {
    margin-top: 20px;
}

.deadhot_landing .html-content img {
    height: auto !important;
}

@media (max-width: 767px) {
    .deadhot_landing .deadhot_title {
        font-size: 18px;
        line-height: 20px;
    }

    .deadhot_landing .text-desc {
        font-size: 14px;
        line-height: 24px;
        padding: 0px;
    }

    .productsLst ul li {
        /* width: 50% !important; */
    }

    .deadhot_landing .coupons-tab {
        margin-top: 24px;
    }

    .deadhot_landing .coupons-tab .tab-banner {
        margin-bottom: 24px;
    }

    .deadhot_landing .coupons-tab .tab {
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        padding: 10px 6px;
        height: 48px;
    }

    .deadhot_landing .coupons-tab .tab p {
        -webkit-line-clamp: 2;
    }

    .deadhot_landing .coupons-list ul li {
        width: 100%;
    }

    .deadhot_landing .coupons-list {
        margin-top: 20px;
    }

    .deadhot_landing .discountBox {
    }

    .deadhot_landing .discountBox::after {
        max-height: 170px;
    }

    .deadhot_landing .discountBox .discount-design {
        width: 80px;
        font-size: 12px;
    }

    .deadhot_landing .discountBox .discount-design p {
        line-height: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .deadhot_landing .discountBox .discount-design .discount-thumb {
        width: 40px;
        height: 40px;
        border: 2px solid #b7dded;
    }

    .deadhot_landing .discountBox .discount-content {
        width: calc(100% - 80px);
    }

    .deadhot_landing .discountBox .discount-info {
        padding: 10px 12px 10px 12px;
    }

    .deadhot_landing .discountBox .discount-info .discount-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        max-width: calc(100% - 20px);
    }

    .deadhot_landing .discountBox .discount-info .discount-inhaus {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .deadhot_landing .discountBox .discount-info .discount-count {
        padding: 2px 4px;
    }

    .deadhot_landing .discountBox .discount-info .discount-expiryDate {
        margin-top: 28px;
        font-size: 12px;
    }

    .deadhot_landing .discountBox .discount-info .item-infoSquare {
        top: 12px;
        right: 12px;
        width: 18px;
        height: 18px;
    }

    /* deadhot_detail_today deadhot_detail_today deadhot_detail_today deadhot_detail_today deadhot_detail_today */
    .deadhot_landing .deadhot_detail_today {
        padding: 32px 0px;
    }

    .deadhot_landing .deadhot_detail_today .deadhot_title {
        font-size: 16px;
        line-height: 16px;
    }

    .deadhot_landing .deadhot_detail_today .text-desc {
        font-size: 20px;
        line-height: 24px;
    }

    .deadhot_landing .products-content * {
        font-size: 12px !important;
        line-height: 16px !important;
    }

    /* .deadhot_landing .products-content .products-title a h3 {
        font-size: 14px !important;
        line-height: 16px !important;
    } */

    .deadhot_landing .deadhot_detail_flashsales {
        padding: 32px 0px;
    }

    .deadhot_landing .deadhot_detail_flashsales .deadhot_title {
        font-size: 16px;
        line-height: 16px;
    }

    .deadhot_landing .deadhot_detail_flashsales .text-desc {
        font-size: 20px;
        line-height: 24px;
    }

    .deadhot_landing .deadhot_detail_flashsales .tab p:first-child {
        font-size: 12px;
        line-height: 12px;
    }

    .deadhot_landing .deadhot_detail_flashsales .tab p:last-child {
        font-size: 12px;
        line-height: 12px;
    }

    .deadhot_landing .deadhot_detail_all .all-header {
        margin-top: 32px;
        display: block;
        flex-wrap: wrap;
    }

    .deadhot_landing .deadhot_detail_all .all-title {
        width: 160px;
        height: 38px;
        padding: 8px 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    .deadhot_landing .deadhot_detail_all .all-filter {
        width: 100%;
        text-align: left;
        padding-top: 6px;
    }

    .deadhot_landing .deadhot_detail_all .all-filter .btn {
        font-size: 12px;
    }

    .deadhot_landing
        .deadhot_detail_all
        .all-filter
        .filterItem
        .btn--dropdown {
        padding: 8px 20px 8px 8px;
    }

    .deadhot_landing
        .deadhot_detail_all
        .all-filter
        .filterItem
        .dropdown-menu
        .filter-list
        li {
        font-size: 12px;
    }

    .deadhot_landing
        .deadhot_detail_all
        .all-filter
        .filterItem
        .btn--dropdown::after {
        background-size: 80%;
        top: calc(50% - 3px);
        right: 3px;
    }
}

.main--box {
    background: #f8f8f8;
    background: -moz-linear-gradient(
        left,
        #f8f8f8 0%,
        #f8f8f8 50%,
        #ffffff 50%,
        #ffffff 100%
    );
    background: -webkit-linear-gradient(
        left,
        #f8f8f8 0%,
        #f8f8f8 50%,
        #ffffff 50%,
        #ffffff 100%
    );
    background: linear-gradient(
        to right,
        #f8f8f8 0%,
        #f8f8f8 50%,
        #ffffff 50%,
        #ffffff 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8f8f8', endColorstr='#ffffff', GradientType=1);
    /* margin-bottom: 8px; */
}

@media (max-width: 767px) {
    .main--box {
        background: #fff;
    }
}

.groupContent {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
}

.groupContent .slideBar {
    width: 250px;
    background: #f8f8f8;
    padding-bottom: 20px;
}

@media (max-width: 991px) {
    .groupContent .slideBar {
        width: 182px;
    }
}

@media (max-width: 767px) {
    .groupContent .slideBar {
        display: none;
    }
}

.groupContent .contentMain {
    width: calc(100% - 250px);
    padding-left: 40px;
}

@media (max-width: 991px) {
    .groupContent .contentMain {
        width: calc(100% - 182px);
        padding-left: 20px;
    }
}

@media (max-width: 767px) {
    .groupContent .contentMain {
        width: 100%;
        padding-left: 0;
    }
}

.listNav {
    padding: 8px 14px 0;
    position: sticky;
    top: 130px;
    overflow: scroll;
    height: calc(100vh - 130px);
}

@media (max-width: 991px) {
    .listNav {
        padding: 8px 14px 0 0;
    }
}

.listNav > ul > li {
    margin-top: 24px;
}

.listNav > ul > li > a {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    display: block;
}

.listNav>ul>li>a.active {
    color: #34BEEF;
}

.listNav .listNav-accordion {
    padding-bottom: 8px;
}

.listNav .listNav-accordion > ul > li > a {
    padding: 18px 20px 0 0;
    display: block;
    color: #444;
    font-size: 13px;
    font-weight: 500;
    position: relative;
}

.listNav .listNav-accordion > ul > li.have-children > a::after {
    position: absolute;
    top: 24px;
    right: 0;
    content: "";
    width: 12px;
    height: 6px;
    background: url(../images/icon-arrow-listNav.svg) no-repeat center;
    display: block;
}

.listNav .listNav-accordion ul > li > a.active {
    color: #34beef;
}

.listNav .listNav-accordion > ul > li.have-children > a.active::after {
    background: url(../images/icon-arrow-listNav-active.svg) no-repeat center;
}

li.have-children > a.active::after {
    background: url(../images/icon-arrow-listNav-active.svg) no-repeat center;
}

.listNav .listNav-sub {
    display: none;
}

.listNav .listNav-sub > ul > li > a {
    padding: 8px 0 8px 24px;
    display: block;
    color: #444;
}

.listNav .listNav-sub > ul > li:first-child > a {
    padding-top: 16px;
}

.listNav .listNav-sub > ul > li:last-child > a {
    padding-bottom: 0px;
}

/*** bannerCategory ***/
@media (max-width: 767px) {
    .bannerCategory .slider {
        width: calc(100% + 32px);
        overflow: hidden;
        margin-left: -16px;
    }
}

.bannerCategory .slick-list {
    border-radius: 16px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .bannerCategory .slick-list {
        border-radius: 0;
    }
}

.bannerCategory {
    position: relative;
}

.bannerCategory .bannerWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 52px 15% 0;
}

@media (max-width: 991px) {
    .bannerCategory .bannerWrap {
        padding: 23px 15% 0;
    }
}

@media (max-width: 767px) {
    .bannerCategory .bannerWrap {
        padding: 24px 20px;
    }
}

.bannerCategory .bannerWrap .ttl {
    color: #444;
    font-size: 28px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .bannerCategory .bannerWrap .ttl {
        font-size: 20px;
        font-weight: 600;
    }
}

.bannerCategory .bannerWrap .ttl-sale {
    display: block;
    color: #34beef;
    font-size: 40px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .bannerCategory .bannerWrap .ttl-sale {
        font-size: 24px;
        font-weight: 800;
        color: #222;
    }
}

.bannerCategory .bannerWrap .date-sale {
    color: #444;
    font-size: 20px;
    margin-top: 28px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .bannerCategory .bannerWrap .date-sale {
        display: none;
    }
}

.bannerCategory .slick-dots {
    position: absolute;
    left: 48px;
    width: 100%;
    bottom: 0;
    z-index: 5;
    width: initial;
}

@media (max-width: 767px) {
    .bannerCategory .slick-dots {
        left: 20px;
    }
}

.bannerCategory .slick-dots li {
    height: 6px;
    width: 6px;
    margin: 0 4px;
}

.bannerCategory .slick-dots li.slick-active {
    width: 32px;
}

@media (max-width: 767px) {
    .bannerCategory .slick-dots li.slick-active {
        width: 6px;
    }
}

.bannerCategory .slick-dots li.slick-active button {
    border-radius: 30px;
    width: 32px;
    background-color: #34beef;
}

@media (max-width: 767px) {
    .bannerCategory .slick-dots li.slick-active button {
        background-color: #fff;
        border-radius: 50%;
        width: 6px;
    }
}

.bannerCategory .slick-dots li button {
    background-color: #fff;
    border-radius: 50%;
    height: 6px;
    width: 6px;
    transition: all ease 0.3s;
}

.bannerCategory .slick-arrow {
    background: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    top: calc(50% - 16px);
    z-index: 1;
}

.bannerCategory .slick-arrow.slick-prev {
    left: -16px;
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
    transform: rotate(180deg);
    top: calc(50% - 32px);
}

.bannerCategory .slick-arrow.slick-next {
    right: -16px;
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
}

/*** groupCategory ****/
.groupCategory {
    padding-bottom: 30px;
}

.groupCategory .groupCategory-title {
    padding-top: 32px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

@media (max-width: 767px) {
    .groupCategory .groupCategory-title {
        padding-top: 16px;
        margin-bottom: 16px;
        padding: 24px 16px 0;
        width: calc(100% + 32px);
        margin-left: -16px;
        border-top: 6px solid #f8f8f8;
    }
}

.groupCategory .groupCategory-title .groupCategory-tlt {
    font-size: 20px;
    color: #222;
    font-weight: 600;
}

@media (max-width: 767px) {
    .categoryHot {
        /* overflow: hidden; */
    }
}

.categoryHot .slick-list {
    margin-left: -20px;
}

@media (max-width: 767px) {
    .categoryHot .slick-list {
        margin-left: -12px;
    }
}

.categoryHot .slick-slide {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .categoryHot .slick-slide {
        padding-left: 12px;
    }
}

@media (max-width: 767px) {
    .categoryHot .prohot-info .prohot-content .prohot-title a {
        font-size: 12px;
    }
}

.categoryHot .slick-arrow {
    background: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.categoryHot .slick-arrow.slick-prev {
    left: -16px;
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
    transform: rotate(180deg);
    top: calc(40% - 32px);
}

.categoryHot .slick-arrow.slick-next {
    right: -16px;
    top: calc(40% - 16px);
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
}

.listNav-sp {
    display: none;
    width: calc(100% + 33px);
    margin-left: -17px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .listNav-sp {
        display: block;
    }
}

.listNav-sp ul {
    display: flex;
    flex-wrap: wrap;
}

.listNav-sp ul li {
    width: 33.333333%;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    text-align: center;
}

.listNav-sp ul li a {
    display: block;
    padding: 20px 6px;
    color: #444;
    font-size: 12px;
}

.categoryTag {
    margin-top: 8px;
    margin-bottom: 8px;
}

.categoryTag ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}

.categoryTag ul li {
    padding-left: 8px;
    margin-top: 8px;
}

.tag {
    background: #34beef;
    border-radius: 34px;
    color: #fff;
    padding: 2px 30px 2px 8px;
    position: relative;
}

.tag .item-close {
    background: url(../images/icon-close.svg) no-repeat;
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 2px;
    right: 8px;
    cursor: pointer;
}

.categoryProducts ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .categoryProducts ul {
        margin-left: -12px;
    }
}

.categoryProducts ul li {
    width: 25%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .categoryProducts ul li {
        width: 50%;
        margin-top: 16px;
        padding-left: 12px;
    }
}

.listNavLevel-sp {
    display: none;
}

@media (max-width: 767px) {
    .listNavLevel-sp {
        display: block;
    }
}

.listNavLevel-sp ul {
    display: flex;
    flex-wrap: wrap;
}

.listNavLevel-sp ul li {
    text-align: center;
    margin-top: 18px;
    width: 25%;
}

.listNavLevel-sp ul li a {
    padding: 8px 6px;
    color: #666;
    font-size: 12px;
    display: block;
}

.listNavLevel-sp .thumb {
    height: 52px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .main--shopProfile .breadcrumbList {
        display: none;
    }
}

.main--shopProfile .groupCategory-title {
    padding-top: 0;
}

@media (max-width: 767px) {
    .main--shopProfile .groupCategory-title {
        padding-top: 32px;
        border: none;
    }
}

.store {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0 24px;
}

@media (max-width: 991px) {
    .store {
        padding: 16px 0 24px;
    }
}

@media (max-width: 767px) {
    .store {
        padding: 0;
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.store .store-evaluate {
    width: 365px;
    min-height: 165px;
    border-radius: 8px;
    overflow: hidden;
    background: url(../images/bg-store.jpg) no-repeat center;
    background-size: 100%;
    position: relative;
}

@media (max-width: 991px) {
    .store .store-evaluate {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .store .store-evaluate {
        border-radius: 0;
    }
}

.store .store-evaluate::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.5);
}

.store .store-evaluate .store-content {
    position: relative;
    z-index: 1;
    padding: 24px 16px;
}

@media (max-width: 767px) {
    .store .store-evaluate .store-content {
        padding: 16px 90px 24px 16px;
    }
}

.store .store-evaluate .evaluate {
    display: flex;
    flex-wrap: wrap;
}

.store .store-evaluate .evaluate .evaluate-thumb {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
}

.store .store-evaluate .evaluate .evaluate-info {
    width: calc(100% - 48px);
    padding-left: 16px;
}

.store .store-evaluate .evaluate .evaluate-info .tlt {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.store .store-evaluate .evaluate .evaluate-info .evaluate-star {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.store .store-evaluate .evaluate .evaluate-info .evaluate-star .evaluate-item {
    line-height: 16px;
}

.store
    .store-evaluate
    .evaluate
    .evaluate-info
    .evaluate-star
    .evaluate-statistical {
    padding: 0 0 0 5px;
}

.store
    .store-evaluate
    .evaluate
    .evaluate-info
    .evaluate-star
    .evaluate-statistical
    span {
    margin-right: 4px;
    color: rgba(255, 255, 255, 0.8);
}

.store .store-evaluate .store-btn {
    display: flex;
    flex-wrap: wrap;
    margin-left: -14px;
}

@media (max-width: 767px) {
    .store .store-evaluate .store-btn {
        display: block;
        width: 68px;
        position: absolute;
        top: 23px;
        right: 16px;
        margin-left: 0;
    }
}

.store .store-evaluate .store-btn li {
    width: 50%;
    padding-left: 14px;
    margin-top: 20px;
}

@media (max-width: 767px) {
    .store .store-evaluate .store-btn li {
        width: 100%;
        margin-top: 4px;
        padding-left: 0;
    }
}

.store .store-evaluate .store-btn li .btn {
    height: 40px;
    width: 100%;
}

@media (max-width: 767px) {
    .store .store-evaluate .store-btn li .btn {
        font-size: 12px;
        height: 24px;
        padding: 0;
    }
}

.store .store-evaluate .evaluate-follow {
    color: #fff;
    font-size: 12px;
    margin-top: 4px;
    display: none;
}

@media (max-width: 767px) {
    .store .store-evaluate .evaluate-follow {
        display: block;
    }
}

.store .store-information {
    width: calc(100% - 365px);
    padding-left: 20px;
}

@media (max-width: 991px) {
    .store .store-information {
        display: none;
    }
}

.store .store-information .store-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 6px;
}

.store .store-toll {
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 10px 16px 0;
    display: none;
}

@media (max-width: 767px) {
    .store .store-toll {
        display: flex;
    }
}

.store .store-toll .btn {
    padding: 0;
    height: 40px;
}

.store .store-toll .btn:focus {
    box-shadow: none;
}

.store .store-toll .store-item {
    width: 40px;
}

.store .store-toll .store-item--change {
    text-align: right;
    width: 32px;
}

.store .store-toll .store-search {
    width: calc(100% - 72px);
    position: relative;
}

.store .store-toll .store-search .form-control {
    border: none;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    height: 40px;
    padding-left: 40px;
}

.store .store-toll .store-search .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
}

/*** ****/
.statistical {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

.statistical li {
    width: 50%;
    background: #fff;
    padding: 14px 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media (max-width: 767px) {
    .statistical li {
        width: 100%;
    }
}

.statistical li.gray {
    background: #f8f8f8;
}

.statistical li .statistical-item {
    width: 168px;
    padding-right: 12px;
    color: #666;
    font-size: 13px;
    display: flex;
}

.statistical li .statistical-item span {
    min-width: 30px;
}

.statistical li .statistical-number {
    font-size: 13px;
    width: calc(100% - 168px);
}

.statistical li .statistical-number--blue {
    font-weight: 600;
    color: #34beef;
}

/*** tabsNav ***/
.groupTabs {
    padding-bottom: 24px;
}

@media (max-width: 767px) {
    .groupTabs {
        padding-bottom: 0;
    }
}

.tabsNav {
    background: #f8f8f8;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

@media (max-width: 767px) {
    .tabsNav {
        background: #fff;
    }
}

.tabsNav ul {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
}

@media (max-width: 767px) {
    .tabsNav ul {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px;
    }
}

.tabsNav ul li {
    padding-right: 34px;
    display: inline-block;
}

@media (max-width: 991px) {
    .tabsNav ul li {
        padding-right: 40px;
    }
}

@media (max-width: 767px) {
    .tabsNav ul li {
        padding-right: 24px;
    }
}

.tabsNav ul li a {
    line-height: 56px;
    display: block;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #444;
}

@media (max-width: 991px) {
    .tabsNav ul li a {
        font-size: 14px;
        line-height: 48px;
    }
}

@media (max-width: 767px) {
    .tabsNav ul li a {
        font-size: 13px;
        font-weight: 500;
    }
}

.tabsNav ul li a.active {
    color: #34beef;
}

.tabsNav ul li a.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #34beef;
}

@media (max-width: 767px) {
    .tabsNav ul li a.active::after {
        height: 2px;
    }
}

.navProfile ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1%;
}

.navProfile ul li {
    width: auto;
    padding-left: 1%;
    text-align: center;
    margin-top: 28px;
}

@media (max-width: 991px) {
    .navProfile ul li {
        width: auto;
    }
}

@media (max-width: 767px) {
    .navProfile ul li {
        margin-top: 16px;
    }
}

.navProfile ul li a {
    color: #666;
    font-size: 12px;
    display: block;
}

.navProfile ul li.active {
    border-bottom: 1px solid #34beef;
}

.navProfile ul li.active a {
    color: #34beef !important;
}

.navProfile .navProfile-thumb {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-bottom: 6px;
}

.profileHot {
    margin-top: 24px;
}

@media (max-width: 767px) {
    .profileHot {
        margin-top: 16px;
        overflow: hidden;
    }
}

.profileHot .sliderProfileHot .slick-list {
    margin-left: -20px;
}

@media (max-width: 767px) {
    .profileHot .sliderProfileHot .slick-list {
        margin-left: -12px;
    }
}

.profileHot .sliderProfileHot .slick-slide {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .profileHot .sliderProfileHot .slick-slide {
        padding-left: 12px;
    }
}

.profile-info {
    display: flex;
    flex-wrap: wrap;
}

.profile-info .products-thumb {
    width: 148px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    padding-top: 148px;
    max-height: 148px;
}

/* @media (max-width: 767px) {
    .profile-info .products-thumb {
        width: 100%;
        padding-top: 100%;
    }
} */

.profile-info .products-thumb img {
    width: 100%;
}

.profile-info .profile-thumb .profile-rectangle {
    background: url(../images/icon-rectangle.svg) no-repeat;
    width: 24px;
    height: 32px;
    display: block;
    position: absolute;
    top: 0;
    left: 14px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding-top: 3px;
}

.profile-info .profile-thumb .profile-rectangle--gray {
    background: url(../images/icon-rectangle-gray.svg) no-repeat;
}

.profile-info .profile-thumb .profile-bookmark {
    position: absolute;
    /*bottom: 8px;*/
    top: 110px;
    right: 10px;
    width: 16px;
    height: 20px;
}

.profile-info .products-content {
    width: calc(100% - 148px);
    padding-left: 12px;
}

/* @media (max-width: 767px) {
    .profile-info .products-content {
        width: 100%;
        padding: 0;
    }
} */

.profile-info .profile-content .profile-company {
    color: #aaa;
    font-size: 12px;
}

.profile-info .profile-content .profile-title a {
    color: #222;
    font-size: 16px;
    font-weight: 500;
}

@media (max-width: 991px) {
    .profile-info .profile-content .profile-title a {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .profile-info .profile-content .profile-title a {
        font-size: 12px;
    }

    .profile-info {
        margin-bottom: 16px;
    }

    .profile-info:last-child {
        margin-bottom: 0px;
    }
}

.productsLst-modal .profile-info {
    margin-bottom: 16px;
}

.profile-info .profile-content .profile-view {
    margin-top: 4px;
}

.profile-info .profile-content .profile-view .profile-number {
    font-size: 12px;
    font-weight: 600;
    color: #222;
    display: inline-flex;
}

.profile-info .profile-content .profile-view .profile-number img {
    margin-right: 3px;
    margin-top: -3px;
}

.profile-info .profile-content .profile-view .profile-numberItem {
    font-size: 12px;
    color: #666;
    padding-left: 4px;
}

.profile-info .profile-content .profile-price {
    margin-bottom: 4px;
}

@media (max-width: 767px) {
    .profile-info .profile-content .profile-price {
        font-size: 12px;
    }
}

.profile-info .profile-content .profile-price .profile-number {
    color: #ff4451;
    font-weight: 600;
    padding-right: 4px;
}

@media (max-width: 767px) {
    .profile-info .profile-content .profile-price .profile-number {
        padding-right: 0;
    }
}

.profile-info .profile-content .profile-price .profile-priceNumber {
    color: #34beef;
    font-weight: 600;
}

.profile-info .profile-content .profile-price .profile-label {
    background: #f8f8f8;
    border-radius: 4px;
    font-size: 12px;
    color: #666;
    padding: 2px 4px;
    display: inline-block;
}

@media (max-width: 767px) {
    .groupInfo--bodr .titleShop {
        border-top: 6px solid #f8f8f8;
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 32px 16px 0;
    }
}

@media (max-width: 767px) {
    .title-product-shop {
        border-top: 6px solid #f8f8f8;
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 32px 16px 0;
    }
}

@media (max-width: 767px) {
    .title-product-shop-danh-muc {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0px 16px 0;
    }
}

.photoUser {
    margin-top: 24px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .photoUser {
        margin-top: 16px;
    }
}

@media (max-width: 991px) {
    .photoUser .sliderPhotoUser {
        margin-left: 0px;
    }
}

@media (max-width: 767px) {
    .photoUser .sliderPhotoUser {
        margin-left: 0px;
    }
}

.photoUser .sliderPhotoUser .slick-list {
    margin-left: -20px;
}

@media (max-width: 767px) {
    .photoUser .sliderPhotoUser .slick-list {
        margin-left: -12px;
    }
}

.photoUser .sliderPhotoUser .slick-slide {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .photoUser .sliderPhotoUser .slick-slide {
        padding-left: 12px;
    }
}

.photoGallery {
    overflow: hidden;
    border-radius: 8px;
}

.photoGallery a {
    position: relative;
    display: block;
}

.photoGallery a img {
    width: 100%;
}

.photoGallery .photo-User {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(34, 34, 34, 0.3);
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .photoGallery .photo-User {
        padding: 8px;
    }
}

.photoGallery .photo-User .photo-name {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
}

.photoGallery .photo-User .photo-name span {
    width: 20px;
    min-width: 20px;
    margin-right: 4px;
}

.photoGallery .photo-User .photo-number {
    color: #fff;
    font-size: 12px;
}

@media (max-width: 767px) {
    .photoGallery .photo-User .photo-number {
        display: none;
    }
}

.sliderAds {
    margin-top: 56px;
}

@media (max-width: 767px) {
    .sliderAds {
        margin-top: 32px;
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

@media (max-width: 767px) {
    .sliderAds {
        display: none;
    }
}

.sliderAds .slick-dots {
    left: 20px;
    bottom: 5px;
}

.sliderAds img {
    width: 100%;
}

.popup-slider .slick-dots {
    left: 20px;
    bottom: 5px;
}

.navProfile-sp {
    margin-bottom: 16px;
    display: none;
}

@media (max-width: 767px) {
    .navProfile-sp {
        display: block;
    }
}

.navProfile-sp ul {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 0 16px;
    border-bottom: 1px solid #f3f3f3;
}

.navProfile-sp ul li {
    padding-right: 16px;
    display: inline-block;
}

.navProfile-sp ul li a {
    display: block;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    color: #444;
    padding: 5px 0;
}

.navProfile-sp ul li a.active {
    color: #34beef;
}

.navProfile-sp ul li a.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #34beef;
}

@media (max-width: 767px) {
    .navProfile-sp ul li a.active::after {
        height: 2px;
    }
}

.main--hotdead .tabsNav {
    margin-bottom: 32px;
}

@media (max-width: 991px) {
    .main--hotdead .tabsNav {
        margin-bottom: 24px;
    }
}

@media (max-width: 767px) {
    .main--hotdead .tabsNav {
        margin-bottom: 16px;
    }
}

.main--shopProfileFlashsale {
    padding-bottom: 50px;
}

.main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt32 {
    margin-top: 32px;
}

@media (max-width: 991px) {
    .main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt32 {
        margin-top: 24px;
    }
}

@media (max-width: 767px) {
    .main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt32 {
        margin-top: 18px;
    }
}

.main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt56 {
    margin-top: 56px;
}

@media (max-width: 991px) {
    .main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt56 {
        margin-top: 32px;
    }
}

@media (max-width: 767px) {
    .main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt56 {
        margin-top: 24px;
    }
}

.main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt56 .filter {
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--shopProfileFlashsale .groupFlashsale.groupFlashsale-mt56 .filter {
        margin-top: 16px;
    }
}

.main--shopProfileFlashsale .groupFlashsale .title {
    font-size: 20px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .main--shopProfileFlashsale .groupFlashsale .title {
        font-size: 18px;
    }
}

.main--shopProfileFlashsale .listFlashsale > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--shopProfileFlashsale .listFlashsale > ul > li {
    padding-left: 20px;
    margin-top: 24px;
    width: 33.333333%;
}

@media (max-width: 767px) {
    .main--shopProfileFlashsale .listFlashsale > ul > li {
        width: 100%;
        margin-top: 16px;
    }
}

.main--shopProfileFlashsale .listFlashsale-all > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--shopProfileFlashsale .listFlashsale-all > ul > li {
    padding-left: 20px;
    margin-top: 24px;
    width: 25%;
}

@media (max-width: 991px) {
    .main--shopProfileFlashsale .listFlashsale-all > ul > li {
        width: 33.333333%;
    }
}

@media (max-width: 767px) {
    .main--shopProfileFlashsale .listFlashsale-all > ul > li {
        width: 100%;
        margin-top: 16px;
    }
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 28-06-2021, 09:00:00
    Author     : HUNG.ND
*/
/*** show shop discount ***/
.main--shop._code-discount {
    /* padding-top: 32px; */
}

@media (max-width: 767px) {
    .main--shop._code-discount {
        /* padding-top: 15px; */
    }
}

.main--shop._code-discount .serviceList {
    margin-bottom: 24px;
    white-space: nowrap;
    overflow-x: auto;
}

@media (max-width: 480px) {
    .main--shop._code-discount .serviceList ul {
        flex-flow: initial;
        margin-left: 0;
    }
}

.main--shop._code-discount .serviceList ul li {
    margin-top: 0;
    width: 20%;
}

@media (max-width: 767px) {
    .main--shop._code-discount .serviceList ul li {
        margin-top: 20px;
        border: 1px solid #ededed;
        padding: 0;
    }
}

@media (max-width: 480px) {
    .main--shop._code-discount .serviceList ul li {
        width: initial;
    }
}

.main--shop._code-discount .serviceList ul li a {
    min-width: 80px;
    padding-bottom: 7px;
    border: 1px solid rgba(255, 255, 255, 0);
}

.main--shop._code-discount .serviceList ul li a:hover {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
}

.main--shop._code-discount .serviceList ul li a .serviceList_thumb {
    height: 55px;
}

@media (max-width: 767px) {
    .main--shop._code-discount .serviceList ul li a .serviceList_thumb img {
        max-height: 30px;
    }
}

.main--shop._code-discount .filter {
    margin-bottom: 24px;
}

.main--shop._code-discount .discount ul li {
    width: 50%;
}

.modal_discount-code .discount {
    margin-bottom: 10px;
}

.modal_discount-code .btn-done {
    height: 40px;
    width: 280px;
    font-size: 14px;
    font-weight: 500;
}

.modal_discount-code .title-detail {
    font-weight: 500;
    font-size: 14px;
}

.main--discountCode .wrap_banner {
    padding: 32px 0 66px;
}

@media (max-width: 991px) {
    .main--discountCode .wrap_banner {
        padding: 24px 0 66px;
    }
}

.main--discountCode .title {
    font-size: 20px;
    font-weight: 600;
}

.main--discountCode .discount {
    margin: 0;
}

.main--discountCode .discount ul {
    padding-top: 8px;
}

.main--discountCode .discount ul li {
    width: 50%;
    margin: 16px 0 0;
}

.main--photoLibrary .group-main {
    padding: 32px 0 66px;
}

@media (max-width: 991px) {
    .main--photoLibrary .group-main {
        padding: 24px 0 66px;
    }
}

.main--photoLibrary .lstPhotoLibrary {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-top: 4px;
}

@media (max-width: 767px) {
    .main--photoLibrary .lstPhotoLibrary {
        padding-left: -12px;
        margin-top: 0;
    }
}

.main--photoLibrary .lstPhotoLibrary li {
    padding-left: 20px;
    margin-top: 20px;
    width: 25%;
}

@media (max-width: 767px) {
    .main--photoLibrary .lstPhotoLibrary li {
        width: 50%;
        padding-left: 12px;
        margin-top: 16px;
    }
}

.title-main .tlt {
    font-size: 20px;
    font-weight: 600;
}

.title-main .tlt .lable-number {
    color: #34beef;
    margin-left: 8px;
}

.title-main .evaluate {
    display: flex;
    flex-wrap: wrap;
}

.title-main .evaluate ul {
    display: flex;
    flex-wrap: wrap;
}

.title-main .evaluate ul li {
    margin-right: 3px;
}

.title-main .evaluate .evaluate-number {
    color: #34beef;
    font-size: 13px;
    font-weight: 500;
    padding-top: 4px;
}

.title-main .evaluate .evaluate-number-fl {
    font-size: 13px;
    color: #666;
    padding-left: 4px;
    padding-top: 4px;
}

.main--about .groupAbout {
    padding: 32px 0 66px;
}

@media (max-width: 991px) {
    .main--about .groupAbout {
        padding: 24px 0 66px;
    }
}

.main--about .groupAbout .tlt {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .main--about .groupAbout .tlt {
        font-size: 16px;
        margin-bottom: 16px;
    }
}

.main--about .groupAbout .text-tlt {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .main--about .groupAbout .text-tlt {
        display: flex;
        color: black;
        font-size: 15px;
        font-weight: 500;
    }
}

.main--about .groupAbout .text-tlt span {
    padding-right: 10px;
    min-width: 34px;
}

.main--about .groupAbout .text {
    font-size: 16px;
}

@media (max-width: 767px) {
    .main--about .groupAbout .text {
        font-size: 13px;
    }
}

.main--about .groupAbout .statistical {
    display: none;
}

@media (max-width: 991px) {
    .main--about .groupAbout .statistical {
        display: flex;
    }
}

@media (max-width: 767px) {
    .main--about .groupAbout .statistical li:nth-child(3) {
        background: #f8f8f8;
    }

    .main--about .groupAbout .statistical li:nth-child(4) {
        background: #fff;
    }
}

.main--evaluate .wrap_banner {
    padding: 32px 0 66px;
}

@media (max-width: 991px) {
    .main--evaluate .wrap_banner {
        padding: 24px 0 66px;
    }
}

.boxEvaluate {
    background: #f8f8f8;
    border-radius: 8px;
    margin-top: 26px;
    padding: 28px;
}

@media (max-width: 767px) {
    .boxEvaluate {
        margin-top: 0;
        padding: 16px;
    }
}

.evaluate-fl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
}

.evaluate-fl .evaluate-star {
    width: 130px;
    text-align: center;
    padding-right: 24px;
}

.evaluate-fl .evaluate-star span {
    font-size: 32px;
    font-weight: 600;
    line-height: 34px;
}

.evaluate-fl .evaluate-star ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.evaluate-fl .evaluate-star ul li {
    margin: 0 2px;
}

.evaluate-fl .evaluate-star ul li img {
    width: 16px;
}

.evaluate-fl .evaluate-content {
    width: calc(100% - 130px);
    padding-left: 12px;
    border-left: solid 1px #ddd;
}

@media (max-width: 767px) {
    .evaluate-fl .evaluate-star {
        width: 115px;
        padding-right: 16px;
    }

    .evaluate-fl .evaluate-content {
        width: calc(100% - 115px);
    }
}

.evaluate-fl .evaluate-content ul li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.evaluate-fl .evaluate-content ul li .evaluate-number {
    width: 42px;
    color: #34beef;
    padding-right: 10px;
    text-align: right;
}

.evaluate-fl .evaluate-content ul li .evaluate-number span {
    margin-left: 6px;
    margin-top: 4px;
    display: inline-flex;
}

.evaluate-fl .evaluate-content ul li .evaluate-percent {
    width: 50px;
    padding-left: 10px;
    text-align: left;
}

.evaluate-fl .evaluate-content ul li .evaluate-percent.evaluate-gray {
    color: #aaa;
}

.evaluate-fl .evaluate-content ul li .evaluate-side {
    width: calc(100% - 92px);
    background: #ddd;
    height: 6px;
    border-radius: 16px;
    overflow: hidden;
}

.evaluate-fl .evaluate-content ul li .evaluate-side span {
    background: #34beef;
    border-radius: 16px;
    height: 6px;
    display: block;
}

.evaluate-cmt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 24px;
    border-bottom: 1px solid #f3f3f3;
}

.evaluate-cmt .evaluate-nav ul {
    display: flex;
    flex-wrap: wrap;
}

.evaluate-cmt .evaluate-nav ul li {
    margin-right: 20px;
}

.evaluate-cmt .evaluate-nav ul li a {
    color: #444;
    font-size: 500;
    padding: 6px 0;
    display: block;
    position: relative;
}

.evaluate-cmt .evaluate-nav ul li a.active {
    color: #34beef;
}

.evaluate-cmt .evaluate-nav ul li a.active::after {
    position: absolute;
    bottom: -5px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    background: #34beef;
}

.evaluate-cmt .evaluate-select {
    margin-bottom: 5px;
}

@media (max-width: 767px) {
    .evaluate-cmt .evaluate-select {
        padding: 10px 0;
    }
}

.comment {
    /****/
    /***/
    /***/
    /***/
}

.comment .comment-group {
    padding: 24px 0;
    border-bottom: 1px solid #eee;
}

.comment .comment-group:last-child {
    border-bottom: none;
}

@media (max-width: 767px) {
    .comment .comment-group {
        padding: 16px 0;
    }
}

.comment .comment-user {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.comment .comment-user .comment-info {
    width: 45%;
    padding-right: 10px;
}

.comment .comment-user .comment-name {
    width: 55%;
    padding-left: 10px;
    text-align: right;
}

.comment .comment-user .comment-name .text {
    font-size: 12px;
    color: #aaa;
}

.comment .comment-user .comment-name .text-firm {
    font-weight: 500;
}

.comment .comment-user .comment-name .text-firm .spanBox {
    border: 1px solid #f3f3f3;
    border-radius: 2px;
    padding: 2px;
    display: inline-block;
}

.comment .comment-user .comment-name .text-firm .spanBox img {
    margin: -1px 6px 0 0;
}

.comment .comment-slider {
    overflow: hidden;
    margin-top: 16px;
}

.comment .comment-slider .slick-list {
    margin-left: -12px;
}

.comment .comment-slider .slick-list .slick-slide {
    padding-left: 12px;
}

.comment .comment-slider .photo {
    border-radius: 12px;
    overflow: hidden;
}

.comment .comment-content {
    margin-top: 16px;
}

@media (max-width: 767px) {
    .comment .comment-content .comment-txt {
        font-size: 13px;
    }
}

.comment .comment-content .comment-tools {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
}

.comment .comment-content .comment-tools .numberLike {
    color: #34beef;
}

.comment .comment-content .comment-tools .numberLike span {
    margin-left: 4px;
}

.comment .cmt-tools {
    display: flex;
    flex-wrap: wrap;
}

.comment .cmt-tools li {
    margin-right: 24px;
}

@media (max-width: 767px) {
    .comment .cmt-tools li {
        font-size: 12px;
        margin-right: 16px;
    }
}

.comment .cmt-tools li.time {
    color: #aaa;
}

.comment .cmt-tools li.like.like-active {
    color: #34beef;
}

.comment .cmt-tools li.like,
.comment .cmt-tools li.reply {
    color: #666;
    font-weight: 500;
    cursor: pointer;
}

.comment .comment-info {
    display: flex;
    flex-wrap: wrap;
}

.comment .comment-info .comment-info_thumb {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
}

.comment .comment-info .comment-info_text {
    width: calc(100% - 40px);
    padding-left: 8px;
}

.comment .comment-info .comment-info_text .name {
    color: #444;
    font-weight: 500;
    line-height: 20px;
}

@media (max-width: 767px) {
    .comment .comment-info .comment-info_text .name {
        font-size: 13px;
    }
}

.comment .comment-info .comment-info_text .text {
    color: #444;
}

@media (max-width: 767px) {
    .comment .comment-info .comment-info_text .text {
        font-size: 13px;
    }
}

.comment .comment-info .comment-info_text ul {
    display: flex;
    flex-wrap: wrap;
}

.comment .comment-info .comment-info_text ul li {
    padding-right: 2px;
}

.comment .cmt-user .comment-info {
    background: #f8f8f8;
    border-radius: 16px;
    margin-top: 8px;
    padding: 12px;
}

.comment-more {
    max-width: 340px;
    margin: 0 auto;
    padding-top: 8px;
}

.comment-more .btn {
    width: 100%;
    height: 40px;
}

.comment-more .btn span {
    margin-left: 13px;
}

.navSetting {
    /***/
}

@media (max-width: 767px) {
    .navSetting {
        border-top: 1px solid #f3f3f3;
    }
}

.navSetting .tab-content {
    border-bottom: 1px solid #f3f3f3;
}

.navSetting .nav {
    justify-content: center;
    border-bottom: 1px solid #f3f3f3;
}

.navSetting .nav li a {
    padding: 0 28px;
    line-height: 48px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #444;
    position: relative;
}

@media (max-width: 767px) {
    .navSetting .nav li a {
        padding: 0 12px;
        font-size: 14px;
    }
}

.navSetting .nav li a.active {
    color: #34beef;
}

.navSetting .nav li a.active::after,
.navSetting .nav li a.active::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
}

.navSetting .nav li a.active::after {
    bottom: 0;
    left: calc(50% - 3px);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #f3f3f3;
}

.navSetting .nav li a.active::before {
    bottom: -1px;
    left: calc(50% - 3px);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    z-index: 1;
}

.navSetting .navHistory {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
    text-align: center;
}

@media (max-width: 767px) {
    .navSetting .navHistory {
        width: calc(100% + 32px);
        padding: 0 16px;
        margin-left: -16px;
    }
}

.navSetting .navHistory li {
    padding-right: 44px;
    display: inline-block;
}

@media (max-width: 767px) {
    .navSetting .navHistory li {
        padding-right: 12px;
    }
}

.navSetting .navHistory li:last-child {
    padding-right: 0;
}

@media (max-width: 767px) {
    .navSetting .navHistory li:last-child {
        padding-right: 16px;
    }
}

.navSetting .navHistory li a {
    line-height: 48px;
    display: block;
    color: #444;
    font-weight: 500;
    position: relative;
}

.navSetting .navHistory li a.active {
    color: #34beef;
}

.navSetting .navHistory li a.active:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    background: #34beef;
}

/***/
.wrapContent {
    display: flex;
    flex-wrap: wrap;
}

.wrapContent__info {
    width: 270px;
}

@media (max-width: 991px) {
    .wrapContent__info {
        width: 100%;
    }
}

.wrapContent__main {
    width: calc(100% - 270px);
    padding-left: 40px;
}

@media (max-width: 991px) {
    .wrapContent__main {
        width: 100%;
        padding-left: 0;
        margin-top: 24px;
    }
}

/***/
.userBox {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 24px;
}

@media (max-width: 767px) {
    .userBox {
        padding: 12px;
    }
}

.userBox .userBox-avatar {
    width: 64px;
    margin: 0 auto;
    position: relative;
}

.userBox .userBox-avatar .avatar {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
}

.userBox .userBox-avatar .item-pen {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.userBox .userBox-avatar .item-pen img {
    width: 24px;
}

.userBox .userBox-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    text-align: center;
}

.userBox .follow {
    text-align: center;
    margin-top: 16px;
}

.userBox .follow li {
    display: inline-block;
    color: #666;
    padding: 0 4px;
    position: relative;
}

.userBox .follow li::after {
    position: absolute;
    right: -3px;
    top: 5px;
    content: "";
    display: block;
    width: 1px;
    height: 12px;
    background: #aaa;
}

.userBox .follow li:last-child::after {
    background: none;
    right: 0;
}

.userBox .follow li span {
    color: #222;
}

.userBox .userBox-btn {
    display: flex;
    margin-left: -8px;
    margin-top: 16px;
}

.userBox .userBox-btn .btn {
    width: 100%;
    margin-left: 8px;
    height: 32px;
    padding: 0 4px;
    font-size: 12px;
}

.userBox .userBox-btn .btn.btn--yellow {
    min-width: 120px;
}

.userBox .userBox-btn .btn.btn--blue {
    min-width: 92px;
}

.userBox .userBox-btn .btn span {
    margin-right: 4px;
}

.userBox .member {
    margin-top: 16px;
}

.userBox .member a {
    display: block;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 15px 12px 0;
    background: url(../images/icon-arrow-slider.svg) no-repeat center right;
}

.userBox .member .member-tlt {
    color: #222;
    font-weight: 500;
}

.userBox .member .member-point {
    color: #34beef;
    font-size: 12px;
    font-weight: 500;
}

.userBox .userBox-btnShop {
    margin-top: 16px;
}

.userBox .userBox-btnShop .btn {
    width: 100%;
    height: 40px;
}

.userBox .userBox-btnShop .btn span {
    margin-right: 8px;
}

.present {
    background: #e7fcfd;
    padding: 9px 16px;
    border-radius: 8px;
}

.present .present-txt {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #444;
}

@media (max-width: 767px) {
    .present .present-txt {
        font-size: 13px;
    }
}

.present .present-txt .present-item {
    min-width: 56px;
    margin-right: 16px;
}

.present .present-txt .present-color {
    color: #34beef;
}

.present .present-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 56px);
}

@media (max-width: 767px) {
    .present .present-content {
        flex-wrap: wrap;
    }
}

.present .present-link {
    color: #34beef;
    font-size: 13px;
    font-weight: 500;
    padding-left: 10px;
    min-width: 122px;
}

@media (max-width: 767px) {
    .present .present-link {
        padding: 8px 0 0 0;
    }
}

.main--history {
    /****/
}

.main--history .wrapContent {
    padding: 24px 0 66px;
}

.main--history .historyVoucher {
    margin-top: 24px;
}

@media (max-width: 991px) {
    .main--history .voucherSlider {
        overflow: hidden;
    }
}

.main--history .voucherSlider .sliderVoucher .slick-list {
    margin-left: -20px;
}

@media (max-width: 767px) {
    .main--history .voucherSlider .sliderVoucher .slick-list {
        margin-left: -12px;
    }
}

.main--history .voucherSlider .sliderVoucher .slick-list .slick-slide {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .main--history .voucherSlider .sliderVoucher .slick-list .slick-slide {
        padding-left: 12px;
    }
}

.main--history .orderHistory .groupCategory-title {
    margin: 40px 0 16px;
}

.historyNav ul {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

@media (max-width: 767px) {
    .historyNav ul {
        width: calc(100% + 32px);
        padding: 0 16px;
        margin-left: -16px;
    }
}

.historyNav ul li {
    display: inline-block;
    padding-right: 45px;
}

@media (max-width: 767px) {
    .historyNav ul li {
        padding-right: 24px;
    }
}

.historyNav ul li:last-child {
    padding-right: 0;
}

@media (max-width: 767px) {
    .historyNav ul li:last-child {
        padding-right: 16px;
    }
}

.historyNav ul li a {
    line-height: 52px;
    display: block;
    color: #444;
    font-weight: 400;
    position: relative;
}

.historyNav ul li a:hover {
    color: #34beef !important;
}

.historyNav ul li a.active {
    color: #34beef;
}

.historyNav ul li a.active:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    background: #34beef;
}

.historyNav ul li a span {
    color: #34beef;
}

.groupHistory .lstHistory {
    border-bottom: 1px solid #f3f3f3;
    padding: 24px 0;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory {
        padding: 12px 16px;
        border-top: 6px solid #f8f8f8;
        border-bottom: none;
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.groupHistory .lstHistory:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.groupHistory .lstHistory-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.groupHistory .lstHistory-header .lstHistory-info {
    display: flex;
}

.groupHistory .lstHistory-header .lstHistory-info .lstHistory-thumb {
    width: 24px;
    min-width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
}

.groupHistory .lstHistory-header .lstHistory-info .lstHistory-thumb img {
    position: absolute;
}

.groupHistory .lstHistory-header .lstHistory-info .lstHistory-name {
    font-size: 16px;
    font-weight: 600;
}

.groupHistory .lstHistory-header .lstHistory-lable {
    font-size: 13px;
    font-weight: 500;
}

.groupHistory .lstHistory-header .lstHistory-lable.lstHistory-lable--red {
    color: #ff4451;
}

.groupHistory .lstHistory-box {
    padding: 16px;
    margin-top: 16px;
    background: #f8f8f8;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-box {
        padding: 12px;
    }
}

.groupHistory .lstHistory-box .lstHistory-thumbPro {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-box .lstHistory-thumbPro {
        width: 56px;
        height: 56px;
    }
}

.groupHistory .lstHistory-box .lstHistory-thumbPro img {
    width: 100%;
}

.groupHistory .lstHistory-box .lstHistory-content {
    width: calc(100% - 96px);
    padding-left: 12px;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-box .lstHistory-content {
        width: calc(100% - 56px);
    }
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-box .lstHistory-content .tlt {
        font-size: 13px;
    }
}

.groupHistory .lstHistory-box .lstHistory-content .sales,
.groupHistory .lstHistory-box .lstHistory-content .price {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-box .lstHistory-content .sales,
    .groupHistory .lstHistory-box .lstHistory-content .price {
        font-size: 13px;
    }
}

.groupHistory .lstHistory-box .lstHistory-content .sales {
    color: #ff4451;
}

.groupHistory .lstHistory-box .lstHistory-content .price {
    color: #34beef;
    padding-left: 4px;
}

.groupHistory .lstHistory-box .lstHistory-quantily {
    position: absolute;
    top: 38px;
    right: 16px;
    color: #666;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-box .lstHistory-quantily {
        top: inherit;
        bottom: 12px;
        right: 12px;
        font-size: 13px;
    }
}

.groupHistory .lstHistory-total {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #f3f3f3;
}

.groupHistory .lstHistory-total .lstHistory-number,
.groupHistory .lstHistory-total .total-price {
    color: #666;
}

.groupHistory .lstHistory-total .total-price span {
    color: #34beef;
    padding-left: 16px;
    font-weight: 600;
}

.groupHistory .lstHistory-btn {
    padding-top: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.groupHistory .lstHistory-btn .lstHistory-text {
    width: calc(100% - 308px);
    padding-right: 12px;
    color: #666;
    font-weight: 500;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-btn .lstHistory-text {
        width: 100%;
        margin-bottom: 8px;
    }
}

.groupHistory .lstHistory-btn .lstHistory-btnWap {
    width: 308px;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-btn .lstHistory-btnWap {
        width: 100%;
    }
}

.groupHistory .lstHistory-btn .lstHistory-btnWap .lstHistory-btnInfo {
    display: flex;
    justify-content: flex-end;
    margin-left: -12px;
}

.groupHistory .lstHistory-btn .lstHistory-btnWap .lstHistory-btnInfo .btn {
    width: 100%;
    max-width: 150px;
    height: 40px;
    margin-left: 12px;
}

@media (max-width: 767px) {
    .groupHistory .lstHistory-btn .lstHistory-btnWap .lstHistory-btnInfo .btn {
        max-width: 100%;
    }
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 05-07-2021, 09:00:00
    Author     : HUNG.ND
*/
/*** show shop detail ***/
.main--shop._shopDetail .detail_product-head {
    border-bottom: 6px solid #f8f8f8;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main--shop._shopDetail .detail_product-head .wrap {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.main--shop._shopDetail .detail_product-head .detail_slider-thumb {
    width: 50%;
}

@media (max-width: 767px) {
    .main--shop._shopDetail .detail_product-head .detail_slider-thumb {
        width: 100%;
        margin-bottom: 30px;
    }
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .slider-thumb
    .items
    img {
    width: 100%;
    border-radius: 5px;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .dealine-sale {
    background-color: #ff7572;
    display: flex;
    justify-content: center;
    padding: 10px;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .dealine-sale
    .icon-clock {
    display: flex;
    margin-right: 7px;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .dealine-sale
    .sale_count {
    color: white;
    padding: 0 5px;
    display: inline-block;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .dealine-sale
    .arrow {
    display: flex;
    margin-left: 10px;
    align-items: center;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .dealine-sale
    .arrow
    path {
    stroke: #fff;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .detail_slider
    .pagingInfo {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 10px;
    color: #fff;
    position: absolute;
    z-index: 5;
    bottom: 50px;
    left: 48%;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: 2px;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .thumb-control {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .thumb-control
    li {
    padding: 0 10px;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .thumb-control
    .svg
    path {
    stroke: #333;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .slider-thumb-sub {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .main--shop._shopDetail
        .detail_product-head
        .detail_slider-thumb
        .slider-thumb-sub {
        display: none;
    }
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .slider-thumb-sub
    .items {
    padding: 0 7px;
    cursor: pointer;
}

.main--shop._shopDetail
    .detail_product-head
    .detail_slider-thumb
    .slider-thumb-sub
    .items
    img {
    border-radius: 5px;
}

.main--shop._shopDetail .detail_product-head .deatil_info {
    width: 50%;
    padding-left: 45px;
}

@media (max-width: 767px) {
    .main--shop._shopDetail .detail_product-head .deatil_info {
        width: 100%;
        padding-left: 0;
    }
}

.ctg-list {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 10px;
}

.ctg-list li {
    line-height: 100%;
    font-size: 13px;
    color: #666666;
    padding: 2px 10px;
}

.ctg-list li + li {
    border-left: 1px solid #ddd;
}

.ctg-list li:first-child {
    padding-left: 0;
}

.main--shop._shopDetail .detail_product-head .deatil_info .product-name {
    font-size: 20px;
    font-weight: 500;
    color: #222;
    margin-bottom: 7px;
}

.main--shop._shopDetail .detail_product-head .deatil_info .view-rate {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .view-rate
    .view-share {
    display: flex;
    align-items: center;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .view-rate
    .view-share
    li {
    line-height: 100%;
    font-size: 14px;
    color: #666666;
    padding: 2px 10px;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .view-rate
    .view-share
    li
    + li {
    border-left: 1px solid #ddd;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .view-rate
    .view-share
    li:first-child {
    padding-left: 0;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .view-rate
    .view-share
    li
    img {
    width: 16px;
    height: 16px;
}

.main--shop._shopDetail .detail_product-head .deatil_info .price_wrap {
    display: flex;
    align-items: baseline;
    flex-flow: wrap;
    margin-bottom: 30px;
}

.future-price {
    color: #ff4451;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .price_wrap
    .price_buy {
    font-size: 20px;
    color: #34beef;
    font-weight: 700;
    padding-right: 5px;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .price_wrap
    .number-discount {
    color: #ff4451;
    font-size: 14px;
    padding-right: 5px;
}

.price-before {
    color: #aaa;
    font-size: 14px;
    text-decoration: line-through;
}

.main--shop._shopDetail .detail_product-head .deatil_info .list-detail {
    margin-bottom: 25px;
}

.main--shop._shopDetail .detail_product-head .deatil_info .list-detail li {
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-weight: 400;
}

.main--shop._shopDetail .detail_product-head .deatil_info .list-detail li.need {
    background: url("../images/icon-arrow-slider.svg") no-repeat 100% 50%;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .list-detail
    li
    .icon {
    width: 25px;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .list-detail
    li
    .info {
    width: calc(100% - 25px);
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .list-detail
    li
    .info
    .info-code {
    /* width: 50px; */
    color: #34beef;
    font-weight: 500;
    cursor: pointer;
}

.main--shop._shopDetail .detail_product-head .deatil_info .product-select {
    display: flex;
    flex-flow: wrap;
}

.main--shop._shopDetail .detail_product-head .deatil_info .product-select li {
    border-radius: 4px;
    background-color: #f8f8f8;
    padding: 5px 8px;
    color: #666;
    font-size: 13px;
    margin: 2px 10px 2px 0;
    cursor: pointer;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .product-select
    li.active {
    background-color: #34beef;
    color: #fff;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .select-product-number {
    max-width: 110px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .select-product-number
    button {
    background-color: transparent;
    font-size: 20px;
    font-weight: 500;
    width: 30px;
    height: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100%;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .select-product-number
    button:focus {
    outline: none;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .select-product-number
    .number {
    border: none;
    background-color: transparent;
    width: 40px;
    text-align: center;
}

.main--shop._shopDetail
    .detail_product-head
    .deatil_info
    .select-product-number
    .number:focus {
    outline: none;
}

.main--shop._shopDetail .detail_product-head .deatil_info .button-control {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.main--shop._shopDetail .detail_product-head .deatil_info .button-control .btn {
    width: calc(50% - 8px);
    height: 48px;
    font-size: 16px;
}

.main--shop._shopDetail .product-block {
    border-bottom: 6px solid #f8f8f8;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main--shop._shopDetail .product-shop {
    margin-bottom: 30px;
}

.product_detail-sticky {
    top: 132px;
}

.shop_profile-sticky {
    top: 80px;
}

@media (max-width: 991px) {
    .product_detail-sticky {
        top: 86px;
    }

    .shop_profile-sticky {
        top: 58px;
    }
}

.main--shop._shopDetail .product_detail-wrap .product_detail-tabs {
    background-color: white;
}

@media (max-width: 991px) {
    .main--shop._shopDetail
        .product_detail-wrap
        .product_detail-tabs
        .nav-pills {
        white-space: nowrap;
        overflow-x: auto;
        flex-flow: initial;
    }
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-tabs
    .nav-pills
    .nav-item
    .nav-link {
    padding: 8px 12px 8px 12px;
    border-radius: 0;
    color: #444;
    font-size: 16px;
    border-bottom: 3px solid rgba(255, 255, 255, 0);
}

@media (max-width: 991px) {
    .main--shop._shopDetail
        .product_detail-wrap
        .product_detail-tabs
        .nav-pills
        .nav-item
        .nav-link {
        padding: 8px 12px 8px 12px;
        font-size: 14px;
    }
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-tabs
    .nav-pills
    .nav-item
    .nav-link.active {
    background-color: transparent;
    border-bottom-color: #34beef;
    color: #34beef;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-tabs
    .nav-pills
    .nav-item
    .nav-link.active
    .number {
    opacity: 1;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-tabs
    .nav-pills
    .nav-item
    .nav-link
    .number {
    opacity: 0.5;
}

.main--shop._shopDetail .product_detail-wrap .product_detail-content {
    padding-top: 30px;
}

.main--shop._shopDetail .product_detail-wrap .product_detail-content .wrap_bnr {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    .wrap_bnr
    .foot-group {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    .wrap_bnr
    .foot-group
    .save-bookmark {
    width: 25px;
    display: block;
    color: #333;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    .wrap_bnr
    .foot-group
    .btn {
    width: calc(50% - 25px);
    height: 40px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user {
    border-radius: 6px;
    margin-bottom: 20px;
    position: relative;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-thumb {
    border-radius: 0 0 6px 6px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 9px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 12px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-thumb
    .user-upload {
    display: flex;
    align-items: center;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-thumb
    .user-upload
    .user-name {
    padding-left: 7px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .slider_thumbs-user
    .items
    img {
    width: 100%;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-button {
    position: absolute;
    left: 0;
    top: 30%;
    width: 100%;
}

@media (max-width: 767px) {
    .main--shop._shopDetail
        .product_detail-wrap
        .product_detail-content
        #product-user-thumb
        .slider_thumb-user
        .control-button {
        display: none;
    }
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-button
    .btn-control {
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: none;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-button
    .btn-control.btn-prev {
    left: -15px;
    transform: rotate(-180deg);
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_thumb-user
    .control-button
    .btn-control.btn-next {
    right: -15px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_user-nav
    .items {
    cursor: pointer;
    width: 64px;
    height: 64px;
    padding: 4px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_user-nav
    .items
    img {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-user-thumb
    .slider_user-nav
    .slick-current
    .items
    img {
    border-color: #34beef;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .table-parameter {
    width: 100%;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .table-parameter
    th,
.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .table-parameter
    td {
    padding: 8px 0;
    border: none;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .table-parameter
    th {
    font-weight: 400;
    width: 50%;
    color: #666666;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .table-parameter
    td {
    font-weight: 600;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .product_list-color {
    display: flex;
    flex-flow: wrap;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .product_list-color
    li {
    margin-right: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-parameter
    .product_list-color
    li
    .color-icon {
    margin-right: 7px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #dddddd;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-describe {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 50px;
    margin-bottom: 30px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-describe
    .descript-block {
    position: relative;
}

.hiden {
    overflow: hidden;
    height: 100px;
    position: relative;
}

.no-hidden {
    overflow: none;
}

.hiden::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    content: "";
    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        white 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        white 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        white 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-describe
    table
    td {
    border: 1px #ccc dashed;
    padding: 5px 5px 5px 5px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-describe
    table
    th {
    border: 1px #ccc dashed;
    padding: 5px 5px 5px 5px;
}

.btn-readmore {
    position: absolute;
    width: 50%;
    bottom: 0;
    left: 50%;
    margin-left: -25%;
    z-index: 10;
    height: 40px;
}

.btn-readmore.btn-thugon {
    bottom: -45px;
}

.btn-readmore img {
    margin-left: 7px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-comment {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-comment
    .title-main {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-comment
    .btn-review {
    position: relative;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-comment
    .btn-review
    .poin_items::after {
    transform: rotate(90deg);
    top: -4px;
    left: 10px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question {
    margin-bottom: 15px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li:last-child {
    border-bottom: none;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li
    .info-left {
    font-weight: 600;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li
    .info-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 4px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li
    .info-head
    .question-time {
    color: #aaa;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li
    .question_wrap {
    margin-bottom: 10px;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .list-question
    li
    .question_wrap
    .question-name {
    color: #444;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .viewAll {
    text-align: center;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .viewAll
    .btn-viewAll {
    border: none;
    background: transparent;
}

.main--shop._shopDetail
    .product_detail-wrap
    .product_detail-content
    #product-QA
    .viewAll
    .btn-viewAll:focus {
    outline: none;
}

/*** main--information ***/
.main--information .bodyInformation {
    background: #f8f8f8;
    border-bottom: solid 1px #ddd;
    padding: 24px 0 24px;
}

@media (max-width: 767px) {
    .main--information .bodyInformation {
        padding: 0px;
        border: none;
    }
}

.main--information .wrapInformation {
    max-width: 930px;
    margin: 0 auto;
    width: 750px;
}

.main--information .messageInformation {
    padding: 16px 22px 16px 16px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media (max-width: 767px) {
    .main--information .messageInformation {
        width: calc(100% + 32px);
        margin-left: -16px;
        border-radius: 0;
    }
}

.main--information .messageInformation--bgBlue {
    background: #34beef;
}

.main--information .messageInformation--bgBoldBlue {
    background: #278ff7;
}

.main--information .messageInformation--bgGreen {
    background: #2cba4b;
}

.main--information .messageInformation--bgRed {
    background: #ff4451;
}

.main--information .messageInformation .messageText {
    color: #fff;
    width: calc(100% - 40px);
    padding-right: 10px;
}

.main--information .messageInformation .messageText .message-p {
    font-weight: 600;
}

.main--information .messageInformation .messageText .message-p1 {
    font-size: 13px;
}

.main--information .messageInformation .messageItem {
    width: 40px;
}

.main--information .boxInformation {
    background: #fff;
    margin-top: 12px;
    padding: 16px 24px;
    border-radius: 8px;
}

@media (max-width: 767px) {
    .main--information .boxInformation {
        width: calc(100% + 32px);
        margin-left: -16px;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 6px;
        padding: 16px;
    }
}

.main--information .titleInformation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}

@media (max-width: 767px) {
    .main--information .titleInformation {
        padding-bottom: 0;
    }
}

.main--information .titleInformation .title-tlt .tlt {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .main--information .titleInformation .title-tlt .tlt {
        font-size: 13px;
    }
}

.main--information .titleInformation .title-tlt .tlt .item {
    margin-right: 14px;
    min-width: 20px;
}

.main--information .titleInformation .title-tlt .tlt .avatar {
    width: 24px;
    min-width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 4px;
}

.main--information .titleInformation .title-link a {
    font-size: 13px;
    font-weight: 600;
    color: #34beef;
}

.main--information .personal {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #eee;
    padding-top: 16px;
}

@media (max-width: 767px) {
    .main--information .personal {
        border: none;
    }
}

.main--information .personal .personal-name {
    width: 150px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .main--information .personal .personal-name {
        width: 100%;
    }
}

.main--information .personal .personal-add {
    width: calc(100% - 150px);
    padding: 0 12px;
    color: #444;
    font-weight: 500;
}

@media (max-width: 767px) {
    .main--information .personal .personal-add {
        width: 100%;
        padding: 0;
        font-size: 13px;
        color: #666;
    }
}

.main--information .personal .personal-maps {
    width: 82px;
    text-align: right;
}

@media (max-width: 767px) {
    .main--information .personal .personal-maps {
        width: 100%;
    }
}

.main--information .personal .personal-maps a {
    font-size: 12px;
    font-weight: 500;
    color: #34beef;
}

.main--information .personal .personal-maps .item {
    margin-right: 6px;
}

@media (max-width: 767px) {
    .main--information .priceInformation {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.main--information .priceInformation li {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #eee;
    text-align: right;
}

@media (max-width: 767px) {
    .main--information .priceInformation li {
        padding: 12px 16px;
    }
}

@media (max-width: 767px) {
    .main--information .priceInformation li .tlt {
        color: #595959;
    }
}

.main--information .priceInformation li .price {
    font-weight: 500;
    padding-left: 12px;
}

.main--information .priceInformation li.priceInformation-bold .tlt,
.main--information .priceInformation li.priceInformation-bold .price {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .main--information .priceInformation li.priceInformation-bold .tlt,
    .main--information .priceInformation li.priceInformation-bold .price {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .main--information .priceInformation li.priceInformation-bold .tlt {
        color: #222;
    }
}

.main--information .priceInformation li.priceInformation-bold .price {
    color: #34beef;
}

.main--information .priceInformation li.priceInformation-total .price {
    color: #34beef;
    font-size: 20px;
    font-weight: 600;
}

.main--information .priceInformation li.priceInformation-total .price span {
    font-size: 14px;
    color: #666;
    display: block;
    font-weight: 400;
}

.main--information .lstInformation-box {
    padding: 16px;
    margin-bottom: 12px;
    background: #f8f8f8;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

@media (max-width: 767px) {
    .main--information .lstInformation-box {
        padding: 12px 30px 12px 12px;
    }
}

.main--information .lstInformation-box .lstInformation-thumbPro {
    width: 96px;
    height: 96px;
    border-radius: 8px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .main--information .lstInformation-box .lstInformation-thumbPro {
        width: 56px;
        height: 56px;
    }
}

.main--information .lstInformation-box .lstInformation-thumbPro img {
    width: 100%;
}

.main--information .lstInformation-box .lstInformation-content {
    width: calc(100% - 96px);
    padding-left: 12px;
}

@media (max-width: 767px) {
    .main--information .lstInformation-box .lstInformation-content {
        width: calc(100% - 56px);
    }
}

@media (max-width: 767px) {
    .main--information .lstInformation-box .lstInformation-content .tlt {
        font-size: 13px;
    }
}

.main--information .lstInformation-box .lstInformation-content .sales,
.main--information .lstInformation-box .lstInformation-content .price {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .main--information .lstInformation-box .lstInformation-content .sales,
    .main--information .lstInformation-box .lstInformation-content .price {
        font-size: 13px;
    }
}

.main--information .lstInformation-box .lstInformation-content .sales {
    color: #ff4451;
}

.main--information .lstInformation-box .lstInformation-content .price {
    color: #34beef;
    padding-left: 4px;
}

.main--information .lstInformation-box .lstInformation-content .note {
    color: #ff4451;
    font-size: 12px;
    margin-top: 10px;
}

.main--information .lstInformation-box .lstInformation-quantily {
    position: absolute;
    top: 38px;
    right: 16px;
    color: #666;
}

@media (max-width: 767px) {
    .main--information .lstInformation-box .lstInformation-quantily {
        top: inherit;
        bottom: 12px;
        right: 12px;
        font-size: 13px;
    }
}

.main--information .titleInformation-fl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.main--information .titleInformation-fl .tlt {
    font-weight: 600;
}

@media (max-width: 767px) {
    .main--information .titleInformation-fl .tlt {
        font-size: 13px;
    }
}

.main--information .titleInformation-fl .codeOrders {
    color: #666;
}

.main--information .titleInformation-fl a {
    color: #34beef;
    font-size: 13px;
    font-weight: 600;
}

.main--information .bankTransfer {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.main--information .bankTransfer span {
    min-width: 28px;
    margin-right: 10px;
}

.main--information .textInformation {
    margin-top: -16px;
}

.main--information .textInformation li {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #eee;
}

@media (max-width: 767px) {
    .main--information .textInformation li {
        border-bottom: none;
        padding: 12px 0 0;
    }
}

.main--information .textInformation li:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .main--information .textInformation li.textInformation-sp {
        display: block;
    }

    .main--information .textInformation li.textInformation-sp .tlt {
        width: 100%;
    }

    .main--information .textInformation li.textInformation-sp .text {
        width: 100%;
        max-width: 100%;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .main--information .textInformation li .tlt {
        color: #595959;
    }
}

.main--information .textInformation li .text {
    font-weight: 500;
    max-width: 300px;
    min-width: 120px;
    text-align: right;
}

.main--information .information-btn {
    /* max-width: 496px; */
    margin: 16px 0 0 auto;
}

@media (max-width: 767px) {
    .main--information .information-btn {
        margin-top: 10px;
    }
}

.main--information .information-btn ul {
    margin-left: -16px;
    display: flex;
}

@media (max-width: 767px) {
    .main--information .information-btn ul {
        margin-left: -12px;
    }
}

.main--information .information-btn ul li {
    width: 100%;
    padding-left: 16px;
}

@media (max-width: 767px) {
    .main--information .information-btn ul li {
        padding-left: 12px;
    }
}

.main--information .information-btn ul li .btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
}

@media (max-width: 767px) {
    .main--information .information-btn ul li .btn {
        height: 40px;
        font-size: 14px;
    }
}

/*** modal_confirm ***/
.modal--confirm .modal-dialog {
    max-width: 370px;
}

@media (max-width: 480px) {
    .modal--confirm .modal-dialog {
        max-width: inherit;
    }
}

.modal--confirm .confirmText {
    text-align: center;
}

.modal--confirm .confirmText .confirmText-p {
    font-size: 16px;
    font-weight: 600;
}

.modal--confirm .confirmText .confirmText-p01 {
    color: #444;
    margin-top: 8px;
}

.modal--confirm .confirmBtn {
    display: flex;
    margin-left: -12px;
    margin-top: 24px;
}

.modal--confirm .confirmBtn .btn {
    height: 40px;
    margin-left: 12px;
    width: 50%;
}

/*** header -- requestReturn ***/
.requestReturn {
    background: #fff;
    padding: 24px 0;
}

.requestReturn .requestReturn-top {
    display: flex;
    justify-content: space-between;
}

.requestReturn .logo {
    display: flex;
    align-items: center;
}

.requestReturn .logo a {
    border-right: solid 2px #ddd;
    padding-right: 14px;
}

.requestReturn .logo .title {
    color: #666;
    padding-left: 14px;
}

.main--requestReturn {
    background: #f8f8f8;
    border-bottom: solid 1px #ddd;
    padding: 24px 0 66px;
    /***/
}

@media (max-width: 767px) {
    .main--requestReturn {
        padding: 0 0 66px;
        border: none;
    }
}

.main--requestReturn .wrapReturn {
    max-width: 750px;
    margin: 0 auto;
}

.main--requestReturn .wrapReturn .boxReturn:last-child {
    margin-bottom: 0;
}

.main--requestReturn .boxReturn {
    background: #fff;
    padding: 16px 24px;
    border-radius: 4px;
    margin-bottom: 12px;
}

@media (max-width: 767px) {
    .main--requestReturn .boxReturn {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 16px;
        margin-bottom: 6px;
    }
}

.main--requestReturn .boxReturn .tlt {
    color: #444;
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .main--requestReturn .boxReturn .tlt {
        background: #f8f8f8;
        padding: 8px 16px;
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -16px;
        border-top: 1px solid #ebebeb;
        color: #666;
        font-size: 14px;
    }
}

.main--requestReturn .boxReturn .tlt-name {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--requestReturn .boxReturn .tlt-name {
        font-size: 12px;
        margin-top: 12px;
    }
}

.main--requestReturn .boxReturn .tlt-name .tlt-thumb {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
}

.main--requestReturn .formReturn {
    margin-top: -24px;
}

.main--requestReturn .formReturn > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--requestReturn .formReturn > ul > li {
    width: 50%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--requestReturn .formReturn > ul > li {
        width: 100%;
    }
}

.main--requestReturn .formReturn > ul > li label {
    margin-bottom: 12px;
    font-weight: 500;
}

.main--requestReturn .formReturn > ul > li label .required {
    color: #ff4451;
}

.main--requestReturn .formReturn > ul > li .form-control {
    height: 44px;
}

.main--requestReturn .formReturn > ul > li textarea.form-control {
    height: 108px;
}

.main--requestReturn .formReturn > ul > li .formReturn-note {
    color: #444;
    margin-top: -8px;
}

.main--requestReturn .requestReturn-btn {
    max-width: 365px;
    margin: 24px 0 0 auto;
}

@media (max-width: 767px) {
    .main--requestReturn .requestReturn-btn {
        margin-top: 0;
    }
}

.main--requestReturn .requestReturn-btn .btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
}

.login {
    display: flex;
    height: 100vh;
    min-height: 700px;
    /****/
}

@media (max-width: 991px) {
    .login {
        display: block;
        min-height: inherit;
    }
}

.login .login-info {
    background: #e7fcfd;
    width: 33%;
    height: 100%;
    padding: 12px 40px;
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    .login .login-info {
        display: none;
    }
}

.login .login-info .logo {
    text-align: center;
    margin-bottom: 10%;
}

.login .login-info .logo img {
    width: 248px;
}

.login .login-form {
    width: 77%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

@media (max-width: 991px) {
    .login .login-form {
        width: 100%;
        height: inherit;
        padding: 0 16px;
        display: block;
    }
}

.login .login-form .logo {
    display: none;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 80px;
}

@media (max-width: 991px) {
    .login .login-form .logo {
        display: block;
    }
}

@media (max-width: 767px) {
    .login .login-form .logo {
        margin-top: 0;
    }
}

.login .loginWrap {
    width: 100%;
}

.login .sliderBanner .bannerWrap {
    text-align: center;
}

.login .sliderBanner .bannerWrap .bannerThumb {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login .sliderBanner .bannerWrap .bannerText {
    margin-top: 30px;
}

.login .sliderBanner .bannerWrap .bannerText .tlt {
    font-size: 20px;
    font-weight: 600;
}

.login .sliderBanner .bannerWrap .bannerText .text {
    color: #666;
}

.login .sliderBanner .slick-dots {
    width: 100%;
    padding: 10px 0 0;
}

.login .sliderBanner .slick-dots li {
    width: 12px;
    height: 12px;
}

.login .sliderBanner .slick-dots li.slick-active {
    width: 12px;
}

.login .sliderBanner .slick-dots li.slick-active button {
    width: 12px;
    height: 12px;
}

.login .sliderBanner .slick-dots li button {
    width: 12px;
    height: 12px;
}

.login .copyright {
    position: fixed;
    bottom: 0;
    left: 33%;
    width: calc(100% - 33%);
    text-align: center;
    font-size: 12px;
    color: #aaa;
    padding: 0 0 16px;
    background: #fff;
    z-index: 2;
}

@media (max-width: 991px) {
    .login .copyright {
        left: 0;
        width: 100%;
    }
}

.login .formWrap {
    width: 100%;
    max-width: 446px;
    margin: 0 auto;
    padding: 40px 40px 50px;
}

@media (max-width: 991px) {
    .login .formWrap {
        padding: 20px 0 80px;
    }
}

.login .formWrap .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.login .formWrap .form-note {
    color: #aaa;
    text-align: center;
    margin: 16px 0 16px;
}

.login .formWrap .form-note span {
    color: #7c7c7c;
}

.groupForm {
    margin-bottom: 12px;
    position: relative;
}

.groupForm .form-control {
    height: 48px;
    font-size: 16px;
    padding-right: 46px;
}

.groupForm .form-control:focus {
    border: 1px solid #34beef;
    box-shadow: none;
}

.groupForm .item {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
}

.groupForm .item:focus {
    box-shadow: none;
}

.groupForm-btn {
    padding-top: 4px;
}

.groupForm-btn .btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
}

.login .login-text {
    padding-top: 16px;
    text-align: center;
}

.login .login-text .login-text-p {
    padding-top: 8px;
    color: #666;
}

.login .login-text .login-text-p a {
    font-weight: 600;
    color: #5a5a5a;
}

.login .error {
    margin-top: 4px;
    color: #ff4451;
}

.login .loginShare {
    margin-top: 24px;
}

.login .loginShare .loginShare-or {
    position: relative;
    text-align: center;
}

.login .loginShare .loginShare-or span {
    position: relative;
    z-index: 1;
    font-size: 13px;
    color: #aaa;
    background: #fff;
    padding: 0 15px;
}

.login .loginShare .loginShare-or::after {
    position: absolute;
    top: 10px;
    left: 0;
    content: "";
    height: 1px;
    width: 100%;
    background: #ddd;
    display: block;
}

.login .loginShare .loginShare-item {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.login .loginShare .loginShare-item li {
    padding: 0 8px;
}

.login .loginRegistration {
    text-align: center;
    margin-top: 24px;
}

.login .loginRegistration a {
    color: #34beef;
    font-weight: 500;
}

.login .loginOverlook {
    text-align: center;
    margin-top: 10px;
}

.login .loginOverlook a {
    color: #aaa;
    font-weight: 500;
}

.login .loginAuthentic {
    padding: 24px 45px 44px;
}

.login .loginAuthentic ul {
    display: flex;
    margin-left: -16px;
}

.login .loginAuthentic ul li {
    width: 16.666667%;
    padding-left: 16px;
}

.login .loginAuthentic ul li .form-control {
    border: none;
    border-bottom: 2px solid #ddd;
    padding: 0;
    color: #444;
    font-size: 24px;
    border-radius: 0;
    text-align: center;
}

.login .loginAuthentic ul li .form-control:focus {
    border-bottom: 2px solid #34beef;
    box-shadow: none;
}

/*** ****/
.process {
    background: #f4f4f4;
    height: 6px;
    display: block;
}

@media (max-width: 991px) {
    .process {
        height: 4px;
    }
}

@media (max-width: 767px) {
    .process {
        height: 2px;
    }
}

.process span {
    background: #34beef;
    border-radius: 0 12px 12px 0;
    display: block;
    height: 6px;
}

@media (max-width: 991px) {
    .process span {
        height: 4px;
    }
}

@media (max-width: 767px) {
    .process span {
        height: 2px;
    }
}

.main--login {
    /***/
}

.main--login .wrap-content {
    padding: 64px 0 100px;
}

@media (max-width: 767px) {
    .main--login .wrap-content {
        padding: 24px 0 66px;
    }
}

.main--login .titleLogin {
    text-align: center;
}

.main--login .titleLogin .tlt {
    font-size: 24px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .main--login .titleLogin .tlt {
        font-size: 16px;
    }
}

.main--login .titleLogin .text {
    margin-top: 16px;
    color: #aaa;
}

.main--login .navLogin {
    padding: 32px 0;
    border-bottom: 1px solid #f4f4f4;
}

@media (max-width: 991px) {
    .main--login .navLogin {
        padding: 16px 0;
    }
}

.main--login .navLogin ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--login .navLogin ul li {
    padding-left: 20px;
    width: 50%;
}

@media (max-width: 991px) {
    .main--login .navLogin ul li {
        width: 100%;
        margin-top: 12px;
    }
}

.main--login .navLogin ul li a {
    background: #f4f4f4;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    padding: 0 46px;
    color: #666;
    font-size: 16px;
    font-weight: 500;
    position: relative;
}

@media (max-width: 767px) {
    .main--login .navLogin ul li a {
        height: 50px;
    }
}

.main--login .navLogin ul li a::after {
    position: absolute;
    top: calc(50% - 12px);
    right: 18px;
    content: "";
    width: 24px;
    height: 24px;
    display: block;
}

.main--login .navLogin ul li a.active {
    background: #e7fcfd;
    color: #34beef;
}

.main--login .navLogin ul li a.active::after {
    background: url(../images/icon-radio-tick.svg) no-repeat;
}

.main--login .navLogin ul li a span {
    height: 27px;
    margin-right: 12px;
}

.main--login .formInput {
    padding-top: 8px;
}

.main--login .formInput ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--login .formInput ul li {
    padding-left: 20px;
    width: 50%;
}

@media (max-width: 767px) {
    .main--login .formInput ul li {
        width: 100%;
    }
}

.main--login .formInput .formInput-group {
    margin-top: 24px;
}

.main--login .formInput .formInput-group .formInput-label {
    font-weight: 500;
    margin-bottom: 8px;
}

.main--login .formInput .formInput-group .formInput-label .required {
    color: #ff4451;
}

.main--login .formInput .formInput-group .form-control {
    height: 48px;
    font-size: 16px;
}

@media (max-width: 767px) {
    .main--login .formInput .formInput-group .form-control {
        height: 44px;
        font-size: 14px;
    }
}

.main--login .formInput .formInput-group .formInput-control {
    margin-top: 12px;
}

.main--login .formInput .formInput-group .formInput-control:first-of-type {
    margin-top: 0;
}

.main--login .formInput .formInput-group .formInput-control .custom-control {
    padding-right: 70px;
}

.main--login
    .formInput
    .formInput-group
    .formInput-control
    .custom-control:last-child {
    padding-right: 0;
}

.main--login .btnForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;
}

@media (max-width: 767px) {
    .main--login .btnForm {
        margin-top: 24px;
    }
}

.main--login .btnForm .back {
    color: #34beef;
    font-size: 16px;
    font-weight: 500;
}

.main--login .btnForm .btn {
    width: 270px;
    height: 48px;
    padding: 0;
}

@media (max-width: 767px) {
    .main--login .btnForm .btn {
        width: 100%;
    }
}

.main--login .copyright {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 16px;
    text-align: center;
    color: #aaa;
    font-size: 12px;
}

.main--login .formGroup {
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .main--login .formGroup {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px;
        border-bottom: 8px solid #f4f4f4;
    }
}

.main--login .formGroup > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--login .formGroup > ul > li {
    padding-left: 20px;
    padding-bottom: 24px;
    width: 50%;
}

@media (max-width: 767px) {
    .main--login .formGroup > ul > li {
        width: 100%;
    }
}

.main--login .formGroup .formUpload-thumb {
    margin-top: -12px;
}

.main--login .formGroup-wrap .formGroup-label {
    font-weight: 500;
    margin-bottom: 8px;
}

.main--login .formGroup-wrap .formGroup-label .required {
    color: #ff4451;
}

.main--login .formGroup-wrap .form-control {
    height: 48px;
    font-size: 16px;
}

@media (max-width: 767px) {
    .main--login .formGroup-wrap .form-control {
        height: 44px;
        font-size: 14px;
    }
}

.main--login .formGroup-wrap textarea.form-control {
    height: 152px;
}

.main--login .loginShare {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 24px;
}

.main--login .loginShare .loginShare-lable {
    font-weight: 500;
    padding-right: 42px;
}

@media (max-width: 767px) {
    .main--login .loginShare .loginShare-lable {
        color: #aaa;
        font-size: 13px;
    }
}

.main--login .loginShare ul {
    display: flex;
    flex-wrap: wrap;
}

.main--login .loginShare ul li {
    padding-right: 16px;
}

.main--login .loginShare ul li:last-child {
    padding-right: 0;
}

.main--login .loginShare ul li img {
    max-width: 24px;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 06-07-2021, 09:00:00
    Author     : HUNG.ND
*/
/*** show modal ***/
/*---MODAl Đánh Giá Nhanh---*/
.sku-modal-danh-gia .modal-dialog {
    margin-bottom: 80px;
}

.modal-quick-review .modal-dialog {
    max-width: 650px;
}

.modal-quick-review .product-review {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
}

.modal-quick-review .product-review .thumb {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 24px;
}

.modal-quick-review .product-review .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-quick-review .product-review .product-name {
    width: calc(100% - 40px);
    padding-left: 10px;
}

.modal-quick-review .star-list {
    margin-bottom: 27px;
}

.modal-quick-review .star-list li {
    padding-right: 4px;
}

.modal-quick-review .star-list li .svg {
    width: 26px;
    height: 26px;
}

.modal-quick-review .guide-upload-thumb {
    font-size: 12px;
    color: #666;
}

.modal-quick-review .text-guide-cmt {
    color: #ff7572;
    font-size: 12px;
}

.modal-quick-review .textarea-cmt {
    border: none;
    resize: none;
    min-height: 100px;
    font-size: 13px;
    color: black;
    width: 100%;
}

.modal-quick-review .textarea-cmt:focus {
    outline: none;
}

.modal--confirm .confirm-title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
}

.modal--confirm .confirm-icon img {
    width: 50px;
}

.modal-discount-code .input-discount-code {
    border: 1px solid #ddd;
    margin-bottom: 24px;
    height: 44px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 4px;
}

.modal-discount-code .input-discount-code .input-code {
    border: none;
    background-color: transparent;
    width: calc(100% - 90px);
    padding-left: 10px;
}

.modal-discount-code .input-discount-code .input-code:focus {
    outline: none;
}

.modal-discount-code .input-discount-code .btn-submit {
    width: 80px;
    height: 100%;
    background-color: #eee;
    color: #aaa;
    border-radius: 7px;
}

.modal-discount-code .discount {
    margin-bottom: 0;
}

.modal-discount-code .discount ul li {
    width: 100%;
}

.modal-discount-code .discount ul li:last-child {
    margin-bottom: 0;
}

.modal-question-creat .textarea-question {
    border: none;
    width: 100%;
    resize: none;
}

.modal-question-creat .textarea-question:focus {
    outline: none;
}

.modal-transport .select__transport li {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 16px 48px;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

@media (max-width: 767px) {
    .modal-transport .select__transport li {
        padding: 10px 10px 10px 40px;
    }
}

.modal-transport .select__transport li::after {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    position: absolute;
    left: 18px;
    top: 18px;
    content: "";
    border-radius: 50%;
    overflow: hidden;
}

@media (max-width: 767px) {
    .modal-transport .select__transport li::after {
        left: 12px;
        top: 12px;
    }
}

.modal-transport .select__transport li.active::after {
    background: url("../images/icon-radio-tick.svg") 50% 50% no-repeat;
    border: none;
}

.modal-transport .select__transport li:last-child {
    margin-bottom: 0;
}

.modal-transport .select__transport li .info {
    width: calc(100% - 100px);
}

@media (max-width: 767px) {
    .modal-transport .select__transport li .info {
        width: calc(100% - 75px);
        font-size: 12px;
    }
}

.modal-transport .select__transport li .info .transport-stt {
    font-weight: 600;
}

.modal-transport .select__transport li .info .transfer-unit {
    font-weight: 600;
    color: #666;
}

.modal-transport .select__transport li .info .delivery-date {
    font-size: 13px;
    color: #666;
}

@media (max-width: 767px) {
    .modal-transport .select__transport li .info .delivery-date {
        font-size: 12px;
    }
}

.modal-transport .select__transport li .price {
    color: #34beef;
    font-size: 16px;
    font-weight: 500;
    width: 100px;
    text-align: right;
}

@media (max-width: 767px) {
    .modal-transport .select__transport li .price {
        font-size: 12px;
        width: 70px;
    }
}

.main--photoStore {
    padding: 16px 0 66px;
}

.main--photoStore .post {
    font-size: 13px;
    margin-top: 24px;
    color: #666;
}

.main--photoStore .post span {
    color: #222;
}

.photoStore .photoStore-thumb img {
    /* width: 100%; */
}

.photoStore-lable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
}

.photoStore-lable span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}

.photoStore .photoStore-thumb .photoStore-lable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
}

.photoStore .photoStore-thumb .photoStore-lable span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}

.photoStore .photoStore-title {
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.photoStore a.photoStore-title {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    height: 52px;
}

.photoStore .photoStore-title a {
    color: #222;
    font-size: 16px;
    font-weight: 500;
}

.photoStore .photoStore-date {
    margin-top: 4px;
}

.photoStore .photoStore-date span {
    color: #666;
    font-size: 13px;
    margin-right: 20px;
}

.photoStore .photoStore-date span:last-child {
    margin-right: 0;
}

.photoStore-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 14px;
}

.photoStore-head__item {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
}

.photoStore-head__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photoStore-head__text {
    width: calc(100% - 32px);
    padding-left: 8px;
}

.photoStore-head__text .tlt {
    color: #444;
    font-weight: 500;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.photoStore-head__text .tlt a {
    color: #34beef;
    margin-left: 16px;
    font-size: 13px;
}

.photoStore-head__text .lable {
    color: #666;
    font-size: 12px;
    line-height: 16px;
}

.photoStore-head__text .lable.professional {
    height: 16px;
}

.photoStore-item {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}

.photoStore-item li {
    margin-left: 8px;
    margin-top: 8px;
    background: #f8f8f8;
    border-radius: 4px;
    padding: 2px 4px;
    color: #666;
    line-height: 20px;
}

.main--photoStoreDetail {
    padding-bottom: 66px;
    padding-top: 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail {
        padding-top: 0;
    }
}

.main--photoStoreDetail .photoStoreDetail {
    padding: 0 160px;
    position: relative;
}

.main--photoStoreDetail .photoStoreDetail table td {
    border: 1px solid #ccc;
}

@media (max-width: 991px) {
    .main--photoStoreDetail .photoStoreDetail {
        padding: 0;
    }
}

.main--photoStoreDetail .thumbDetail {
    position: relative;
    padding-top: 50%;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .thumbDetail {
        margin-bottom: 16px;
    }
}

.main--photoStoreDetail .thumbDetail img {
    position: absolute;
    top: 0;
    width: 100%;
}

.main--photoStoreDetail .thumbDetail .btn-save-bookmart {
    position: absolute;
    right: 14px;
    bottom: 14px;
    background-color: transparent;
    border: none;
    z-index: 10;
}

.main--photoStoreDetail .thumbDetail .btn-back {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 23px;
    z-index: 10;
}

.main--photoStoreDetail .thumbDetail .btn-back .svg path {
    fill: #fff;
}

.main--photoStoreDetail .photoStoreDetail-head {
    padding: 0px 0 12px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
}

/* @media (max-width: 767px) {
    .main--photoStoreDetail .photoStoreDetail-head {
        padding: 0px 0 12px;
    }
} */

.main--photoStoreDetail .photoStoreDetail-head .photoStore-head {
    margin-bottom: 0;
    width: calc(100% - 110px);
    padding-right: 10px;
}

.main--photoStoreDetail .photoStoreDetail-head .photoStore-head__item {
    width: 48px;
    height: 48px;
    padding-top: 0px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .photoStoreDetail-head .photoStore-head__item {
        width: 28px;
        height: 28px;
        padding-top: 0px;
    }
}

.main--photoStoreDetail .photoStoreDetail-head .photoStore-head__text {
    width: calc(100% - 48px);
}

@media (max-width: 767px) {
    .main--photoStoreDetail .photoStoreDetail-head .photoStore-head__text {
        width: calc(100% - 28px);
    }
}

.main--photoStoreDetail .photoStoreDetail-head .photoStore-head__text .tlt {
    font-size: 18px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .photoStoreDetail-head .photoStore-head__text .tlt {
        font-size: 12px;
    }
}

.main--photoStoreDetail .photoStoreDetail-head .btn {
    width: 110px;
    height: 32px;
    font-size: 13px;
}

.main--photoStoreDetail .photoStoreDetail-head .btn span {
    padding-right: 4px;
}

.main--photoStoreDetail .photoStoreDetail-title {
    margin-top: 18px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .photoStoreDetail-title {
        margin-top: 10px;
        font-size: 20px;
        line-height: 28px;
    }
}

.main--photoStoreDetail .photoStore-post {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #aaa;
}

.main--photoStoreDetail .photoStore-post img {
    margin-right: 6px;
    margin-top: -2px;
}

.main--photoStoreDetail .listDetail {
    background: #f8f8f8;
    border-radius: 4px;
    padding: 0 15px 16px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .listDetail {
        border: 1px solid #ddd;
        background: none;
        position: 4px 15px 16px;
        margin-top: 16px;
    }
}

.main--photoStoreDetail .listDetail .listDetail-info {
    width: 100%;
}

.main--photoStoreDetail .listDetail .listDetail-info ul {
    display: flex;
    flex-wrap: wrap;
}

.main--photoStoreDetail .listDetail .listDetail-info ul li {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    width: 50%;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .listDetail .listDetail-info ul li {
        padding-top: 12px;
        width: 100%;
    }
}

.main--photoStoreDetail .listDetail .listDetail-info ul li .title {
    color: #666;
    font-size: 13px;
    width: 150px;
    padding-right: 4px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .listDetail .listDetail-info ul li .title {
        font-size: 12px;
    }
}

.main--photoStoreDetail .listDetail .listDetail-info ul li .text {
    width: calc(100% - 150px);
    color: #444;
    font-size: 13px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .listDetail .listDetail-info ul li .text {
        font-size: 12px;
    }
}

.main--photoStoreDetail
    .listDetail
    .listDetail-info
    ul
    li
    .text
    span:not(:first-child) {
    margin-left: 15px;
}

.main--photoStoreDetail .detail-text {
    color: #444;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .detail-text {
        font-size: 13px;
    }
}

.main--photoStoreDetail .detail-thumb {
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .detail-thumb {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.main--photoStoreDetail .detail-thumb .detail-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .detail-thumb .detail-title {
        padding: 0 16px;
    }
}

@media (max-width: 767px) {
    .main--photoStoreDetail .detail-thumb .slider-sub-small {
        padding: 0 16px;
    }
}

.main--photoStoreDetail .detail-thumb img {
    width: 100%;
}

.main--photoStoreDetail .detail-thumb .detail-thumb-note {
    margin-top: 24px;
    font-size: 13px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .detail-thumb .detail-thumb-note {
        padding: 0 16px;
        margin-top: 12px;
        font-size: 12px;
    }
}

.main--photoStoreDetail .detail-thumb .detail-thumb-note span {
    color: #444;
    font-style: italic;
}

.main--photoStoreDetail .slider-ads {
    margin: 32px 0 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .slider-ads {
        width: calc(100% + 32px);
        margin: 24px 0 24px -16px;
    }
}

.main--photoStoreDetail .slider-ads .slick-dots {
    bottom: 5px;
    left: 20px;
}

.main--photoStoreDetail .slider-ads img {
    width: 100%;
}

.main--photoStoreDetail .groupCategory-title {
    margin-top: 36px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .groupCategory-title {
        margin-top: 24px;
    }
}

.main--photoStoreDetail .lstPhoto {
    /* margin-top: -24px; */
}

.main--photoStoreDetail .lstPhoto > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--photoStoreDetail .lstPhoto > ul > li {
    width: 33.333333%;
    padding-left: 20px;
    margin-top: 24px;
}

.main--photoStoreDetail .lstPhoto.lstProduct > ul > li {
    width: 25%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--photoStoreDetail .lstPhoto > ul > li {
        width: 100%;
    }

    .main--photoStoreDetail .lstPhoto.lstProduct > ul > li {
        width: 50%;
    }
}

.photoFollow {
    border-bottom: 1px solid #eee;
    /* border-top: 1px solid #eee; */
    margin-top: 24px;
    padding-bottom: 42px;
}

@media (max-width: 767px) {
    .photoFollow {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px 10px;
    }
}

.photoFollow .photoStoreDetail-head {
    border: none;
}

.photoFollow .photoStoreDetail-head .photoStore-head {
    padding: 0;
    width: 100%;
}

.photoFollow .slider-thumbFollow {
    overflow: hidden;
}

.photoFollow .slider-thumbFollow .slick-list {
    margin-left: -7px;
}

.photoFollow .slider-thumbFollow .slick-slide {
    padding-left: 7px;
}

.photoFollow .slider-thumbFollow .thumbFollow {
    border-radius: 4px;
    overflow: hidden;
}

.photoFollow .photoFollow-view {
    padding-top: 12px;
    font-size: 13px;
}

.photoFollow .photoFollow-view .view {
    padding: 2px 4px;
    background: #f8f8f8;
    border-radius: 24px;
    color: #34beef;
    font-weight: 600;
}

.photoFollow .photoFollow-view .view img {
    width: 13px;
    display: inline-block;
    margin: -3px 0 0 3px;
}

.photoFollow .photoFollow-view .number,
.photoFollow .photoFollow-view .maps {
    color: #666666;
    font-weight: 500;
    padding-left: 8px;
}

.photoFollow .photoFollow-view .maps img {
    display: inline-block;
    margin: -3px 2px 0 0;
    width: 11px;
}

.sliderPhoto .sliderPhoto-group {
    position: relative;
}

.sliderPhoto .sliderPhoto-group .pagingInfo-user {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 10px;
    color: #fff;
    position: absolute;
    z-index: 5;
    bottom: 16px;
    left: 48%;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: 2px;
}

.sliderPhoto .slider-thumb-modal .slick-arrow {
    top: calc(50% - 16px);
}

.sliderPhoto .slider-sub-small {
    /* overflow: hidden; */
    margin-top: 16px;
}

.sliderPhoto .slider-sub-small .slick-list {
    margin-left: -8px;
}

.sliderPhoto .slider-sub-small .slick-slide {
    padding-left: 8px;
}

/* .sliderPhoto
    .slider-sub-small
    .slick-slide.slick-current
    .photoGallery-small::after {
    border: 2px solid #34beef;
} */

.sliderPhoto .photoGallery-small {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    padding-top: 100%;
}

.sliderPhoto .photoGallery-small img {
    border-radius: 16px;
    position: absolute;
    top: 0;
}

.sliderPhoto .photoGallery-small::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 16px;
    border: 1px solid #ccc;
}

.sliderPhoto .photoGallery-large {
    position: relative;
    overflow-x: hidden;
}

.sliderPhoto .photoGallery-large .photoGallery-switch {
    position: absolute;
    top: 12px;
    right: 8px;
    width: 35px;
}

.sliderPhoto .photoGallery-large img {
    width: 100%;
}

.sliderPhoto .photoLocation {
    position: absolute;
    transform: translate(-10px, -10px);
    max-width: 50%;
}

.sliderPhoto .item {
    background: rgba(34, 34, 34, 0.3);
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sliderPhoto .item img {
    max-width: 23px;
}

.sliderPhoto .photoLocation .item img {
    width: 100%;
}

.sliderPhoto .item--box {
    background: #fff;
}

.sliderPhoto .photoLocation-sub {
    background: #fff;
    border-radius: 4px;
    padding: 8px;
    position: relative;
    z-index: 1;
    margin-left: 6px;
    min-width: 100px;
    transform: translate(calc(-50% + 8px), 0.625rem);
    min-width: 242px;
}

.sliderPhoto .photoLocation-sub.top-tag {
    transform: translate(-50%, -7.375rem);
}

.sliderPhoto .photoLocation-sub.right-tag {
    transform: translate(calc(-90% + 8px), 0.625rem);
}

.sliderPhoto .photoLocation-sub.right-tag::after {
    left: calc(90% - 3px);
}

.sliderPhoto .photoLocation-sub.left-tag {
    transform: translate(-5%, 0.625rem);
}

.sliderPhoto .photoLocation-sub.left-tag::after {
    left: calc(5% - 3px);
}

.sliderPhoto .photoLocation-sub.top-tag::after {
    display: none;
}

.sliderPhoto .photoLocation-sub::after {
    width: 0px;
    height: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    content: "";
    position: absolute;
    top: -6px;
    left: calc(50% - 3px);
}

.sliderPhoto .photoLocation-sub--mw165 {
    min-width: 165px;
}

.sliderPhoto .photoLocation-sub .photoLocation-text {
    font-size: 13px;
    font-weight: 500;
}

.sliderPhoto .photoLocation-sub .photoLocation-pro {
    display: flex;
    flex-wrap: wrap;
}

.sliderPhoto .photoLocation-sub .photoLocation-pro .photoLocation-pro_thumb {
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: 4px;
}

.sliderPhoto .photoLocation-sub .photoLocation-pro .photoLocation-pro_info {
    width: calc(100% - 52px);
    padding-left: 4px;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .tlt
    a {
    color: #222;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    padding-right: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .tlt
    a::after {
    position: absolute;
    top: 2px;
    right: -5px;
    content: "";
    width: 14px;
    height: 8px;
    display: block;
    background: url(../images/icon-arrow-listNav-active.svg) no-repeat;
    background-size: 10px auto;
    transform: rotate(-90deg);
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .lable-text {
    font-size: 12px;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .lable-text
    .price {
    color: #278ff7;
    font-weight: 500;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .lable-text
    .discount {
    font-weight: 500;
    color: #ff4451;
    padding-left: 4px;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .lable-text
    .view {
    font-weight: 600;
    color: #34beef;
    padding-left: 7px;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .lable-text
    .view
    img {
    max-width: 10px;
    display: inline-block;
    margin: -2px 0 0 2px;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .brand {
    display: flex;
    align-items: center;
    color: #444;
    font-size: 10px;
    font-weight: 500;
}

.sliderPhoto
    .photoLocation-sub
    .photoLocation-pro
    .photoLocation-pro_info
    .brand
    span {
    width: 12px;
    min-width: 12px;
    height: 12px;
    min-height: 12px;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 2px;
}

/**** groupComment ****/
.groupComment .groupComment-title {
    color: #444;
    font-size: 16px;
    font-weight: 500;
    padding: 24px 0 8px;
}

.groupComment .groupComment-lst {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.groupComment .groupComment-lst.groupComment-lst--box {
    background: #f8f8f8;
    border-radius: 16px;
    padding: 12px;
}

.groupComment .groupComment-lst .groupComment-thumb {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
}

.groupComment .groupComment-lst .groupComment-content {
    width: calc(100% - 32px);
    padding-left: 8px;
    position: relative;
}

.groupComment .groupComment-lst .groupComment-content .name {
    font-weight: 500;
    font-size: 14px;
    padding-right: 40px;
}

.groupComment .groupComment-lst .groupComment-content .text {
    color: #444;
    padding-right: 40px;
}

.groupComment .groupComment-lst .groupComment-tools {
    padding-top: 4px;
    font-size: 13px;
}

.groupComment .groupComment-lst .groupComment-tools .date {
    color: #aaa;
}

.groupComment .groupComment-lst .groupComment-tools .like,
.groupComment .groupComment-lst .groupComment-tools .reply {
    color: #666666;
    font-weight: 500;
    padding-left: 16px;
    cursor: pointer;
}

.groupComment .groupComment-lst .groupComment-tools .like.active,
.groupComment .groupComment-lst .groupComment-tools .reply.active {
    color: #34beef;
}

.groupComment .groupComment-lst .groupComment-tools .numberLike {
    color: #34beef;
    font-weight: 500;
    font-size: 12px;
    padding-left: 77px;
}

.groupComment .groupComment-lst .groupComment-tools .numberLike img {
    display: inline-block;
    width: 13px;
    margin: -3px 0 0 3px;
}

.groupComment .groupComment-drop {
    position: absolute;
    top: -5px;
    right: 0;
    width: 35px;
}

.groupComment .groupComment-drop .show .svg path {
    fill: #34beef;
}

.groupComment .groupComment-drop .btn {
    padding: 0;
    width: 100%;
    height: 35px;
}

.groupComment .groupComment-drop .btn:focus {
    box-shadow: none;
}

.groupComment .groupComment-drop .dropdown-menu {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 12px;
    padding: 0;
    margin: 0;
    min-width: 220px;
}

.groupComment .groupComment-drop .dropdown-menu ul li {
    cursor: pointer;
    padding: 12px 16px;
    color: #666;
    font-weight: 500;
}

.groupComment .groupComment-drop .dropdown-menu ul li.delete {
    color: #ff4451;
}

.groupComment .groupComment-drop .dropdown-menu ul li:hover {
    color: #34beef;
    background: #e7fcfd;
}

.groupComment .groupComment-input {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.groupComment .groupComment-input .groupComment-avatar {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
}

.groupComment .groupComment-input .groupComment-form {
    width: calc(100% - 32px);
    padding-left: 8px;
    position: relative;
}

.groupComment .groupComment-input .groupComment-form .form-control {
    border: 1px solid #eee;
    height: 40px;
    padding: 10px 125px 10px 12px;
}

.groupComment .groupComment-input .groupComment-form .tools {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 60px;
    display: flex;
}

.groupComment .groupComment-input .groupComment-form .tools .btn {
    height: 38px;
    width: 100%;
    padding: 0 5px;
    color: #34beef;
    font-size: 13px;
    background: #fff;
}

.groupComment .groupComment-input .groupComment-form .tools .btn:focus {
    box-shadow: none;
}

.groupComment .groupComment-more {
    text-align: center;
    margin-top: 24px;
}

.groupComment .groupComment-more span {
    cursor: pointer;
    color: #444;
}

.groupComment .groupComment-more img {
    margin-left: 8px;
}

.relatedAlbums {
    margin-top: -12px;
}

.relatedAlbums ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
}

.relatedAlbums ul li {
    width: 25%;
    padding-left: 7px;
    margin-top: 12px;
}

@media (max-width: 767px) {
    .relatedAlbums ul li {
        width: 50%;
    }
}

.relatedAlbums ul li a {
    display: block;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.relatedAlbums ul li a span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
}

.relatedAlbums .acc {
    margin-top: 8px;
}

.acc {
    display: flex;
    align-items: center;
    color: #444;
    font-weight: 500;
}

.acc span {
    line-height: normal;
}

.acc.acc-small span:first-child {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    margin-right: 4px;
}

.acc span img {
    width: 100%;
    border-radius: 50%;
}

.acc.acc-small .name {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.relatedPost {
    margin-top: -24px;
}

@media (max-width: 767px) {
    .relatedPost {
        margin-top: -16px;
    }
}

.relatedPost ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -34px;
}

.relatedPost ul li {
    width: 50%;
    padding-left: 34px;
    padding-top: 24px;
}

@media (max-width: 767px) {
    .relatedPost ul li {
        width: 100%;
        padding: 16px 0 16px 0px;
        margin-left: 34px;
        border-bottom: 1px solid #eee;
    }
}

.newsPost {
    display: flex;
    flex-wrap: wrap;
}

.newsPost .newsPost-thumb {
    width: 160px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
}

.newsPost .newsPost-content {
    width: calc(100% - 160px);
    padding-left: 12px;
}

.newsPost .newsPost-content .title a {
    color: #222;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.newsPost .newsPost-content .view {
    color: #666;
    font-size: 12px;
}

.newsPost .newsPost-content .newsPost-wrap {
    display: flex;
    align-items: center;
    margin-top: 2px;
}

.newsPost .newsPost-content .newsPost-wrap .date {
    color: #666;
    font-size: 12px;
    margin-left: auto;
}

.listPhoto {
    margin-top: -24px;
}

.listPhoto > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.listPhoto > ul > li {
    padding-left: 20px;
    padding-top: 24px;
    width: 50%;
}

@media (max-width: 767px) {
    .listPhoto > ul > li {
        width: 100%;
    }
}

.listPhoto .listPhoto-thumb {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.listPhoto .listPhoto-thumb .listPhoto-number {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(34, 34, 34, 0.7);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    padding: 2px 4px;
}

.listPhoto .listPhoto-title {
    margin-top: 12px;
}

.listPhoto .listPhoto-title a {
    font-weight: 500;
    font-size: 16px;
    color: #222;
}

.listPhoto .listPhoto-label {
    color: #666;
}

.listPhoto .listPhoto-tools {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-top: 12px;
}

.listPhoto .listPhoto-tools li {
    padding-left: 10px;
    width: 25%;
    display: flex;
    align-items: center;
    color: #666;
}

.listPhoto .listPhoto-tools li span {
    min-width: 26px;
    margin-right: 6px;
}

.oldThing {
    margin-top: -24px;
}

.oldThing > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.oldThing > ul > li {
    width: 50%;
    padding-left: 20px;
    padding-top: 24px;
}

@media (max-width: 767px) {
    .oldThing > ul > li {
        width: 100%;
    }
}

.oldThing .oldThing-info {
    display: flex;
    flex-wrap: wrap;
}

.oldThing .oldThing-info .oldThing-thumb {
    width: 200px;
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
}

.oldThing .oldThing-info .oldThing-thumb img {
    width: 100%;
}

.oldThing .oldThing-info .oldThing-content {
    width: calc(100% - 200px);
    padding-left: 12px;
}

.oldThing .oldThing-info .oldThing-content .oldThing-title a {
    font-weight: 600;
    font-size: 16px;
    color: #222;
}

.oldThing-title {
    margin-bottom: 5px;
    color: #444;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.oldThing .oldThing-info .oldThing-content .photoStore-item li {
    font-size: 12px;
}

.oldThing .oldThing-info .oldThing-content .oldThing-tools {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-top: 12px;
}

.oldThing .oldThing-info .oldThing-content .oldThing-tools li {
    padding-left: 10px;
    width: 33.333333%;
    display: flex;
    align-items: center;
    color: #666;
    font-size: 12px;
}

.oldThing .oldThing-info .oldThing-content .oldThing-tools li span {
    min-width: 16px;
    margin-right: 6px;
}

.oldThing .oldThing-info .oldThing-content .oldThing-tools li span img {
    max-width: 16px;
}

.toolsView {
    position: absolute;
    top: 0;
    right: -10px;
    width: 155px;
    background: transparent;
}

.toolsView-div {
    position: sticky;
    top: 20px;
    width: 100%;
}

@media (min-width: 992px) {
    .toolsView {
        height: 100%;
    }

    /* .toolsView ul {
        position: sticky;
        top: 20px;
    } */
}

@media (max-width: 991px) {
    .toolsView {
        position: fixed;
        top: inherit;
        right: inherit;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 8px 40px;
        background-color: white;
    }

    .toolsView-div {
        display: flex;
    }

    .toolsView .btnEdit {
        margin-left: 4px;
    }
}

@media (max-width: 767px) {
    .toolsView {
        padding: 8px 16px;
        background-color: white;
    }
}

@media (max-width: 991px) {
    .toolsView ul {
        display: flex;
        flex-wrap: wrap;
        width: 60%;
    }
}

.toolsView ul li {
    margin-top: 40px;
}

.toolsView ul li:first-child {
    margin-top: 0px;
}

@media (max-width: 991px) {
    .toolsView ul li {
        margin-top: 0;
        width: 20%;
    }
}

.toolsView .item .svg path {
    fill: #444;
}

.toolsView .item-heart img {
    width: 26px;
}

@media (max-width: 991px) {
    .toolsView .item-heart img {
        width: 20px;
    }
}

.toolsView .item-bookmark img {
    width: 20px;
}

@media (max-width: 991px) {
    .toolsView .item-bookmark img {
        width: 15px;
    }
}

.toolsView .item-comment img {
    width: 26px;
}

@media (max-width: 991px) {
    .toolsView .item-comment img {
        width: 20px;
    }
}

.toolsView .item-share img {
    width: 22px;
}

@media (max-width: 991px) {
    .toolsView .item-share img {
        width: 16px;
    }
}

.toolsView .item,
.toolsView .toolsView-number {
    display: block;
    text-align: center;
}

.toolsView .toolsView-number {
    margin-top: 6px;
    color: #444;
}

@media (max-width: 991px) {
    .toolsView .toolsView-number {
        font-size: 12px;
    }
}

.toolsView .btn {
    margin-top: 12px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding: 0 6px;
}

@media (max-width: 991px) {
    .toolsView .btn {
        margin-top: 0;
        width: 40%;
        height: 40px;
        font-size: 14px;
    }
}

.picturePhoto .picturePhoto-note {
    margin-top: 24px;
}

.picturePhoto .picturePhoto-note span {
    color: #444;
    font-style: italic;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 07-07-2021, 09:00:00
    Author     : HUNG.ND
*/
/*** show shop cart ***/
.main--information._shopCart .select-code {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: center;
    background: url("../images/icon-arrow-slider.svg") 100% 50% no-repeat;
    padding-right: 20px;
    cursor: pointer;
}

.main--information._shopCart .select-code .select-ttl {
    white-space: nowrap;
    color: #666;
}

.main--information._shopCart .select-code .select-ttl .icon {
    width: 25px;
    margin-right: 5px;
}

.main--information._shopCart .select-code .stt-code {
    cursor: pointer;
    color: #aaa;
}

.modal-don-hang .modal-dialog {
    margin-bottom: 80px;
}

.modal-don-hang .modal-body {
    padding: 0;
}

.main--information._shopCart .bodyInformation {
    border-bottom: none;
}

@media (max-width: 767px) {
    .main--information._shopCart .bodyInformation {
        border-top: 1px solid #eee;
    }
}

.main--information._shopCart .bodyInformation .wrap {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}

.main--information._shopCart .bodyInformation .main-info {
    width: calc(100% - 365px);
    padding-right: 20px;
}

@media (max-width: 991px) {
    .main--information._shopCart .bodyInformation .main-info {
        width: 100%;
        padding-right: 0;
    }
}

.main--information._shopCart .bodyInformation .main-info .btn-add-address {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding: 18px 10px;
    border-radius: 12px;
    border: 1px dashed #eee;
    width: 100%;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .btn-add-address
    .icon {
    margin-right: 7px;
}

.main--information._shopCart .bodyInformation .main-info .headbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main--information._shopCart .bodyInformation .main-info .headbox .btn-edit {
    color: #aaa;
    font-size: 12px;
    font-weight: 600;
    background-color: transparent;
    border: none;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .headbox
    .btn-edit:hover {
    text-decoration: underline;
}

.main--information._shopCart .bodyInformation .main-info .tlt-name {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .main--information._shopCart .bodyInformation .main-info .tlt-name {
        font-size: 12px;
        margin-top: 12px;
    }
}

.main--information._shopCart .bodyInformation .main-info .tlt-name .tlt-thumb {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .tlt-name
    .tlt-thumb
    img {
    position: absolute;
}

.main--information._shopCart .bodyInformation .main-info .select-code {
    margin-top: 36px;
    position: relative;
}

.main--information._shopCart .bodyInformation .main-info .select-code::after {
    content: "";
    width: calc(100% + 48px);
    left: -24px;
    height: 1px;
    background-color: #eee;
    position: absolute;
    top: -14px;
}

@media (max-width: 767px) {
    .main--information._shopCart
        .bodyInformation
        .main-info
        .select-code::after {
        width: calc(100% + 32px);
        left: -16px;
    }
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .selectPro
    li
    .selectPro-info
    .selectPro_content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .selectPro
    li
    .selectPro-info
    .selectPro_content
    .text-intro {
    padding-top: 7px;
    font-size: 12px;
    color: #ff4451;
}

.main--information._shopCart .bodyInformation .main-info .transportation_wrap {
    margin-top: 16px;
    background-color: #e7fcfd;
    width: calc(100% + 48px);
    margin-left: -24px;
    border-top: 1px solid #34beef;
    border-bottom: 1px solid #34beef;
    padding: 8px 24px;
}

@media (max-width: 767px) {
    .main--information._shopCart
        .bodyInformation
        .main-info
        .transportation_wrap {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .transportation_wrap
    .transportation {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: center;
    background: url("../images/icon-arrow-slider.svg") 100% 50% no-repeat;
    padding-right: 20px;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .transportation_wrap
    .transportation
    .transportation-info {
    display: flex;
    width: calc(100% - 100px);
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .transportation_wrap
    .transportation
    .transportation-info
    .icon {
    width: 41px;
    display: block;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .transportation_wrap
    .transportation
    .transportation-info
    .info {
    padding-left: 7px;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .transportation_wrap
    .transportation
    .transportation-info
    .info
    .delivery-time {
    color: #666;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .transportation_wrap
    .transportation
    .price {
    font-weight: 500;
    min-width: 100px;
    text-align: right;
}

.main--information._shopCart .bodyInformation .main-info .comment-shop {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 16px 24px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 767px) {
    .main--information._shopCart .bodyInformation .main-info .comment-shop {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding-left: 16px;
        padding-right: 16px;
        flex-flow: wrap;
    }
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .comment-shop
    .comment-input {
    width: calc(100% - 280px);
}

@media (max-width: 767px) {
    .main--information._shopCart
        .bodyInformation
        .main-info
        .comment-shop
        .comment-input {
        width: 100%;
        margin-bottom: 10px;
    }
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .comment-shop
    .total-price {
    padding-left: 10px;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .main--information._shopCart
        .bodyInformation
        .main-info
        .comment-shop
        .total-price {
        width: 100%;
        padding-left: 0px;
    }
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .comment-shop
    .total-price
    .total-product-by {
    color: #666;
}

.main--information._shopCart
    .bodyInformation
    .main-info
    .comment-shop
    .total-price
    .price {
    font-weight: 500;
    color: #34beef;
    padding-left: 20px;
}

.main--information._shopCart .bodyInformation .side-bar {
    width: 365px;
}

@media (max-width: 991px) {
    .main--information._shopCart .bodyInformation .side-bar {
        width: 100%;
    }
}

.main--information._shopCart .bodyInformation .side-bar .btn-order {
    height: 48px;
    width: 100%;
    font-size: 16px;
    margin-top: 12px;
    border-radius: 8px;
}

.main--information._shopCart .bodyInformation .side-bar .boxInformation {
    padding: 0;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .boxInformation
    .sideBar-title {
    padding: 16px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .boxInformation
    .sideBar-items {
    padding: 16px;
    border-bottom: 1px solid #eee;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .boxInformation
    .sideBar-items:last-child {
    border-bottom: none;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .promotional-input {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .promotional-input
    .hash-coin {
    color: #666;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .promotional-input
    .hash-coin
    .icon-coin {
    width: 21px;
    height: 21px;
    margin-right: 5px;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .promotional-input
    .form-coin {
    width: 113px;
    height: 36px;
    border-radius: 8px;
    border-color: #ddd;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .promotional-input
    .max-text {
    color: #34beef;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .text-intro {
    font-size: 12px;
    color: #aaa;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .promotional-code
    .text-intro
    span {
    color: #333;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li:last-child {
    padding-bottom: 0;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li
    .price-ttl {
    width: 49.5%;
    display: block;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li
    .price-text {
    width: 49.5%;
    display: block;
    text-align: right;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li.price-total
    .price-text {
    color: #222;
    font-weight: 500;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li.price-promo
    .price-text {
    color: #ff4451;
    font-weight: 500;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li.total-payment
    .price-text
    .price {
    color: #34beef;
    font-size: 20px;
    display: block;
    font-weight: 700;
}

.main--information._shopCart
    .bodyInformation
    .side-bar
    .price_pay
    .price-list
    li.total-payment
    .vat-stt {
    color: #666;
}

.main--information._shopCart .product-block {
    padding: 32px 0 55px;
}

.main--home {
    padding: 15px 0 40px;
}

.main--home .banner-main {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    margin-bottom: 60px;
}

@media (max-width: 991px) {
    .main--home .banner-main {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .main--home .banner-main {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .main--home .banner-main {
        display: none;
    }
}

.main--home .banner-main .banner-big {
    width: 71.8%;
}

@media (max-width: 767px) {
    .main--home .banner-main .banner-big {
        width: 100%;
        margin-bottom: 10px;
    }

    .banner-big .slick-slider {
        padding-bottom: 28px;
    }

    .banner-big .slick-dots li button {
        background-color: #aaa;
    }

    .banner-big .slick-dots li.slick-active button {
        background-color: #34beef;
    }
}

.main--home .banner-main .banner-big .slider-banner .items > img {
    display: inline-block;
    border-radius: 8px;
}

.main--home .banner-main .banner-big .slider-banner .slick-dots {
    bottom: 0;
    width: 100%;
}

/* .sliderFlashSale .slick-list {
    overflow: unset;
} */

.slick-dots button:before {
    display: none;
}

.main--home .banner-main .banner-small {
    width: 28.2%;
    padding-left: 20px;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    flex-flow: column;
}

@media (max-width: 767px) {
    .main--home .banner-main .banner-small {
        width: 100%;
        padding-left: 0;
        flex-flow: initial;
    }
}

.main--home .banner-main .banner-small .small-items {
    height: calc(50% - 0.4vw);
    display: block;
    width: 100%;
    border-radius: 8px;
    overflow-y: hidden;
}

@media (max-width: 767px) {
    .main--home .banner-main .banner-small .small-items {
        width: 48.5%;
        height: initial;
    }
}

.main--home .banner-main .banner-small .small-items img {
    /* height: 100%;
    width: 100%;
    object-fit: cover; */
}

.main--home .watch_wrap {
    margin-bottom: 30px;
}

.watch-most {
    display: flex;
    flex-flow: wrap;
    margin-left: -20px;
}

.watch-most > li:nth-child(7) {
    display: none;
}

.watch-most > li:nth-child(8) {
    display: none;
}

@media (max-width: 991px) {
    .watch-most > li {
        width: 33.33%;
    }
}

@media (max-width: 767px) {
    .watch-most > li {
        width: 50%;
        display: block;
    }

    .watch-most > li:nth-child(7) {
        display: block;
    }

    .watch-most > li:nth-child(8) {
        display: block;
    }
}

.watch-most > li .listPhoto-thumb .stt-number {
    background: url("../images/stt-number.svg") no-repeat 0 0;
    z-index: 5;
    position: absolute;
    left: 12px;
    top: 0;
    width: 21px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
}

.watch-most > li .listPhoto-tools li span {
    display: inline-block;
    width: 40%;
    min-width: initial;
}

.main--home .popular-industry {
    margin-bottom: 60px;
}

@media (max-width: 991px) {
    .main--home .popular-industry {
        margin-bottom: 30px;
    }
}

.main--home .popular-industry .serviceList ul {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -10px;
}

.main--home .popular-industry .serviceList ul li {
    width: 12.5%;
    padding-left: 10px;
    margin-bottom: 10px;
    text-align: center;
}

@media (max-width: 991px) {
    .main--home .popular-industry .serviceList ul li {
        width: 20%;
    }
}

@media (max-width: 767px) {
    .main--home .popular-industry .serviceList ul li {
        width: 30%;
    }
}

.main--home .popular-industry .serviceList ul li.more {
    display: none;
}

@media (max-width: 767px) {
    .main--home .popular-industry .serviceList ul li.more {
        display: block;
    }
}

@media (max-width: 767px) {
    .category-hide {
        display: none;
    }
}

.main--home .popular-industry .serviceList ul li .serviceList_thumb {
    height: 75px;
    align-items: center;
    justify-content: center;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main--home .popular-industry .serviceList ul li a {
    font-size: 12px;
    color: #666;
    display: block;
}

.main--home .popular-industry .serviceList ul li div {
    font-size: 12px;
    color: #666;
    display: block;
    cursor: pointer;
}

.main--home .popular-industry .serviceList ul li .serviceList_more {
    height: 75px;
    align-items: center;
    justify-content: center;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main--home .popular-industry .serviceList ul li .serviceList_more span {
    width: 3.4rem;
    height: 3.4rem;
    background: #e7fcfd;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main--home .product-hot {
    margin-bottom: 60px;
}

@media (max-width: 767px) {
    .main--home .product-hot {
        margin-bottom: 30px;
    }
}

.main--home .home-story {
    margin-bottom: 60px;
}

@media (max-width: 767px) {
    .main--home .home-story {
        margin-bottom: 30px;
    }
}

.main--home .slider-ads {
    margin: 32px 0 45px;
}

@media (max-width: 767px) {
    .main--home .slider-ads {
        width: calc(100% + 32px);
        margin: 0 0 30px -16px;
    }
}

.main--home .slider-ads .slick-dots {
    bottom: 5px;
    left: 2.8vw;
}

.main--home .slider-ads img {
    width: 100%;
}

.main--home .deadlhot__wrap {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .main--home .deadlhot__wrap {
        margin-bottom: 20px;
    }
}

.main--home .deadlhot__wrap .slider-dealhot .slick-list {
    width: calc(100% + 10px);
    margin-left: -5px;
}

@media (max-width: 767px) {
    .main--home .deadlhot__wrap .slider-dealhot .slick-list {
        margin-left: -50px;
        width: calc(100% + 50px);
    }
}

.main--home .deadlhot__wrap .slider-dealhot .slick-slide {
    padding: 5px 10px;
}

.main--home .deadlhot__wrap .slider-dealhot .items {
    display: block;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.main--home .deadlhot__wrap .slider-dealhot .items .thumb img {
    width: 100%;
}

.main--home .deadlhot__wrap .slider-dealhot .items .title {
    text-transform: uppercase;
    font-size: 16px;
    color: #222;
    padding: 12px 15px 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 767px) {
    .main--home .deadlhot__wrap .slider-dealhot .items .title {
        font-size: 14px;
    }
}

.main--home .gallery_wrap {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .main--home .gallery_wrap {
        margin-bottom: 20px;
    }
}

.main--home .market_old {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .main--home .market_old {
        padding-bottom: 5px;
        margin-bottom: 20px;
    }
}

.main--home .market_old .oldThing {
    margin-top: 0;
}

.main--home .market_old .oldThing > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--home .market_old .oldThing > ul > li {
    width: 33.33%;
    padding-left: 20px;
    padding-bottom: 24px;
    padding-top: 0;
}

@media (max-width: 767px) {
    .main--home .market_old .oldThing > ul > li {
        width: 100%;
    }
}

.list-tabs {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 60px;
}

@media (max-width: 991px) {
    .list-tabs {
        margin-bottom: 30px;
    }
}

.list-tabs li {
    width: 11.1%;
    /* width: 12.5%; */
    padding: 6px;
    text-align: center;
}

@media (max-width: 991px) {
    .list-tabs li {
        width: 25%;
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {
    .list-tabs li {
        padding: 0 4px;
    }
}

.list-tabs li a {
    display: block;
}

.list-tabs li a .tabs-icon {
    display: block;
    margin-bottom: 7px;
    position: relative;
}
.list-tabs li a .tabs-icon .text-icon {
    position: absolute;
    background-color: #FF4451;
    color: #ffffff;
    border-radius: 9px;
    word-wrap: break-word;
    padding: 0 8px;
    font-size: 13px;
    top: 4px;
    white-space: nowrap;
    overflow: hidden;
    left: 60%;
}
.list-tabs li a .tabs-icon img {
    height: 76px;
}

.list-tabs li a .tabs-icon img.marginLeft {
    margin-left: 13px;
}

.listPhoto-label {
    color: #666;
}

.lstPhoto > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.lstPhoto > ul > li {
    width: 33.333333%;
    padding-left: 20px;
    margin-top: 24px;
}

.lstPhoto-1 > ul > li {
    width: 100% !important;
}

.lstPhoto-3 > ul > li {
    width: 33.333333%;
}

.lstPhoto-2 > ul > li {
    width: 50% !important;
}

.li-3 {
    width: calc(100%/3) !important;
}

.li-4 {
    width: 25% !important;
}

@media (max-width: 767px) {
    .lstPhoto > ul > li {
        width: 100%;
    }

    .lstPhoto-2 > ul > li {
        width: 100% !important;
    }

    .li-1-mb {
        width: 100% !important;
    }

    .li-2-mb {
        width: 50% !important;
    }
    .list-tabs li a .tabs-icon .text-icon {
        font-size: 9px;
        top: 2px;
    }
}

.lstPhoto > ul > li .photoStore-thumb a img {
    max-height: inherit !important;
    width: 100%;
}

@media (max-width: 767px) {
    .list-tabs li a .tabs-icon img {
        height: 48px;
    }
}

.list-tabs li a .tabs-text {
    font-size: 13px;
    color: #666;
}

@media (max-width: 767px) {
    .list-tabs li a .tabs-text {
        font-size: 11px;
    }
}

.listPhoto-thumb {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    padding-top: calc(100% - 50%);
    background: #f2f2f2;
}

.listPhoto-thumb a {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listPhoto-thumb a img {
    max-height: 100%;
}

.listPhoto-thumb .listPhoto-bookmark {
    position: absolute;
    bottom: 14px;
    right: 14px;
    width: 16px;
}

.photoStore-thumb .play-button {
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
}

.photoStore-thumb .play-button img {
    cursor: pointer;
}

.play-button {
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
}

.play-button img {
    cursor: pointer;
}

.photoStore-thumb .read-views {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 3px 10px;
    color: #fff;
    font-size: 12px;
    left: 14px;
    bottom: 14px;
    display: flex;
}

.photoStore-thumb .read-views .svg {
    margin-right: 5px;
    width: 13px;
}

.photoStore-thumb .read-views .svg path {
    fill: #fff;
    stroke: #fff;
}

.listPhoto-thumb .read-views {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 3px 3px;
    color: #fff;
    font-size: 12px;
    left: 8px;
    bottom: 8px;
    display: flex;
}

.listPhoto-thumb .read-views .svg {
    margin-right: 5px;
    width: 13px;
}

.listPhoto-thumb .read-views .svg path {
    fill: #fff;
    stroke: #fff;
}

.listPhoto-thumb .listPhoto-number {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(34, 34, 34, 0.7);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    padding: 2px 4px;
}

.listPhoto-thumb .btn-save-bookmart {
    position: absolute;
    right: 14px;
    bottom: 14px;
    background-color: transparent;
    border: none;
    z-index: 10;
}

.listPhoto-thumb .listPhoto-new {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
}

.listPhoto-thumb .listPhoto-new span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}

.listPhoto-title {
    margin-top: 12px;
}

.listPhoto-title a {
    font-weight: 500;
    font-size: 16px;
    color: #222;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.listPhoto-label {
    color: #666;
}

.listPhoto-tools {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-top: 12px;
}

.listPhoto-tools li {
    padding-left: 10px;
    width: 25%;
    display: flex;
    align-items: center;
    color: #666;
    cursor: pointer;
}

.listPhoto-tools li span {
    min-width: 26px;
    margin-right: 6px;
}

.picturePhoto .picturePhoto-note {
    margin-top: 24px;
}

.picturePhoto .picturePhoto-note span {
    color: #444;
    font-style: italic;
}

.slider-user {
    overflow: hidden;
}

.slider-user .slick-list {
    width: calc(100% + 20px);
    margin-left: -10px;
}

@media (max-width: 767px) {
    .slider-user .slick-list {
        margin-left: -45px;
        width: calc(100% + 45px);
    }
}

.slider-user .slick-list .slick-slide {
    padding: 0 10px;
}

@media (max-width: 767px) {
    .slider-user .slick-list .slick-slide {
        padding: 0 5px;
    }
}

.slider-user .items {
    position: relative;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    overflow: hidden;
}

.slider-user .items .rate-star {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #e7fcfd;
    padding: 2px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #34beef;
}

.slider-user .items .rate-star img {
    margin-left: 5px;
    width: 12px;
}

.slider-user .items .user-info {
    padding: 15px;
    background-color: #f8f8f8;
}

@media (max-width: 767px) {
    .slider-user .items .user-info {
        padding: 10px 7px;
    }
}

.slider-user .items .user-info .user-thumb {
    width: 40px;
    height: 40px;
    text-align: center;
    margin: 0 auto 8px;
    border-radius: 50%;
}

.slider-user .items .user-info .user-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

@media (max-width: 767px) {
    .slider-user .items .user-info .member-level {
        font-size: 12px;
        padding: 5px;
    }
}

.slider-user .items .user-info .user-name {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-user .items .user-info .user-name .name {
    font-weight: 600;
    color: #222;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
}

.slider-user .items .user-info .user-name .user-stt {
    background-color: #34beef;
    width: 20px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #f0fbff;
    border-radius: 4px;
    line-height: 0;
    margin-left: 5px;
}

.slider-user .items .user-info .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    margin-bottom: 0;
    font-size: 12px;
    color: #666666;
}

.slider-user .items .btn-flow {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    color: #34beef;
    justify-content: center;
    padding: 8px;
}

.slider-user .items .btn-flow img {
    margin-right: 5px;
}

.event-highlight {
    display: flex;
    flex-flow: wrap;
    margin-left: -20px;
}

.event-highlight > li {
    width: 33.33%;
    padding-left: 20px;
    margin-bottom: 24px;
}

@media (max-width: 991px) {
    .event-highlight > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .event-highlight > li {
        width: 100%;
        margin-bottom: 16px;
        display: none;
    }
}

.event-highlight > li:nth-child(1),
.event-highlight > li:nth-child(2),
.event-highlight > li:nth-child(3) {
    display: block;
}

.event-highlight > li a {
    display: flex;
    justify-content: space-between;
}

.event-highlight > li a .event-thumb {
    width: 200px;
    height: 100px;
    overflow: hidden;
    border-radius: 6px;
}

.event-highlight > li a .event-thumb img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.event-highlight > li a .event-info {
    width: calc(100% - 200px);
    padding-left: 15px;
}

.event-highlight > li a .event-info .event-text {
    font-size: 16px;
    color: #666;
    font-weight: 600;
    margin-bottom: 7px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.event-highlight > li a .event-info .event-date {
    display: block;
    background: url("../images/icon-fire.png") no-repeat 0 0;
    padding-left: 25px;
    color: #aaa;
    font-size: 13px;
}

.main--photoStore {
    padding: 16px 0 66px;
}

.main--photoStore .lstPhoto > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--photoStore .lstPhoto > ul > li {
    width: 33.333333%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 991px) {
    .main--photoStore .lstPhoto > ul > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .main--photoStore .lstPhoto > ul > li {
        width: 100%;
    }
}

.main--photoStore .lstPhoto .photoStore .photoStore-thumb a img {
    max-height: 244px;
}

.main--photoStore .slider-ads {
    margin: 32px 0;
}

@media (max-width: 767px) {
    .main--photoStore .slider-ads {
        width: calc(100% + 32px);
        margin: 0 0 0 -16px;
    }
}

.main--photoStore .slider-ads .slick-dots {
    bottom: 5px;
    left: 20px;
}

.main--photoStore .slider-ads img {
    width: 100%;
}

.post {
    font-size: 13px;
    margin-top: 24px;
    color: #666;
}

.post span {
    color: #222;
}

.filterItem .dropdown-menu {
    padding: 0;
    margin: 0;
    border-radius: 0 0 12px 12px;
    border: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateZ(1px) !important;
    transform: translateZ(1px) !important;
}

/*.filterItem:last-child .dropdown-menu {*/
/*    !* transform: translate(-200px, 0px) !important; *!*/
/*    left: unset;*/
/*    right: 0;*/
/*}*/
.applyLastChild .dropdown-menu{
    left: unset;
    right: 0;
}

.filterItem:first-child .dropdown-menu {
    transform: translate(0px, 0px) !important;
}

.filterItem:nth-child(2) .dropdown-menu {
    transform: translate(calc(0px), 0px) !important;
}

.filterItem .dropdown-menu--mw210 {
    min-width: 210px;
}

.filterItem .dropdown-menu--mw290 {
    min-width: 290px;
}

.filterItem .dropdown-menu--mw300 {
    min-width: 300px;
}

.filterItem .dropdown-menu--mw375 {
    min-width: 375px;
}

.filterItem .dropdown-menu .filterBox .filterHeader {
    display: none;
    position: relative;
    padding: 22px 56px;
    min-height: 24px;
    text-align: center;
}

.filterItem .dropdown-menu .filterBox .filterHeader .filter-tlt {
    color: #444;
    font-size: 16px;
    font-weight: 600;
}

.filterItem .dropdown-menu .filterBox .filterHeader .filter-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.filterItem .dropdown-menu .filter-list {
    max-height: 300px;
    overflow-y: auto;
}

.filterItem .dropdown-menu .filter-list li {
    padding: 16px;
    color: #666;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
}

@media (max-width: 767px) {
    .filterItem .sp-only.dropdown-menu .filterBox .filterHeader {
        display: block;
    }

    .filterItem .dropdown-menu .filter-list li {
        padding: 10px 16px;
    }
}

.filterItem .dropdown-menu .filter-list li.active {
    background: #e7fcfd;
    color: #34beef;
}

.filterItem .dropdown-menu .filter-list li .filter-item {
    height: 20px;
    min-height: 20px;
    width: 20px;
    min-width: 20px;
    border: 1px solid #ddd;
    border-radius: 50%;
    margin-right: 8px;
    background: #fff;
}

.filterItem .dropdown-menu .filter-list--check li {
    padding-right: 44px;
}

.filterItem .dropdown-menu .filter-list--check li.active::after {
    background: url(../images/icon-radio-tick.svg) no-repeat;
    background-size: 20px;
}

.filterItem .dropdown-menu .filter-list--check li::after {
    position: absolute;
    top: calc(50% - 10px);
    right: 16px;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
}

@media (min-width: 767px) {
    .filterItem .dropdown-menu .filter-list li:hover {
        background: #e7fcfd;
        color: #34beef;
    }
}

.filterItem .filter-btn {
    padding: 24px 16px 16px;
}

.filterItem .filter-btn .btn {
    width: 100%;
    height: 40px;
}

.filterItem .filter-tabs {
    padding: 16px;
    margin-left: -8px;
    display: flex;
    flex-wrap: wrap;
}

.filterItem .filter-tabs li {
    width: 50%;
    padding-left: 8px;
}

.filterItem .filter-tabs li a {
    background: #f4f4f4;
    border-radius: 4px;
    padding: 6px 8px;
    display: block;
    color: #666;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
}

.filterItem .filter-tabs li a.active {
    background: #34beef;
    color: #fff;
}

.filterItem .filterSelect {
    padding-top: 16px;
}

.filterItem .filterSelect ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    max-height: 178px;
    overflow-y: auto;
    padding: 0 16px;
}

.filterItem .filterSelect ul li {
    padding-left: 8px;
    margin-top: 8px;
}

.filterItem .filterSelect ul li .filterLable {
    background: #f4f4f4;
    border-radius: 4px;
    padding: 8px 12px;
    color: #666;
    font-size: 14px;
    display: block;
    cursor: pointer;
}

@media (max-width: 767px) {
    .filterItem .filterSelect ul li .filterLable {
        font-size: 13px;
    }
}

.filterItem .filterSelect ul li .filterLable.active {
    background: #34beef;
    color: #fff;
}

.filterItem .filter-title {
    color: #444;
    font-size: 18px;
    font-weight: 600px;
    padding: 24px 16px 0;
}

@media (max-width: 767px) {
    .filterItem .filter-title {
        font-size: 16px;
        padding: 0 16px;
    }
}

.filterItem .filter-group {
    padding: 16px 16px 0;
}

.filterItem--select.show .btn--dropdown {
    background: #fff;
    color: #666;
    border: 1px solid #34beef;
}

.filterItem--select .btn--dropdown {
    background: #fff;
    border: 1px solid #ddd;
    width: 100%;
    color: #666;
    text-align: left;
    height: 44px;
}

.filterItem--select .btn--dropdown.btn--active {
    color: #222;
}

.filterItem--select .btn--dropdown:hover {
    background: #fff;
    color: #666;
}

.filterItem--select .btn--dropdown .required {
    color: #ff4451;
}

.filterItem--select .dropdown-menu {
    width: 100%;
}

/*****/
.filter-slider {
    background: #eeeeee;
    border-radius: 16px;
    height: 8px;
    position: relative;
}

.filter-slider .filter-slider-range {
    background: #65d2f5;
    border-radius: 16px;
    position: absolute;
    top: 0;
    height: 100%;
}

.filter-slider .filter-slider-handle {
    background: #34beef;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: -4px;
    z-index: 2;
    cursor: pointer;
}

.filter-slider .filter-slider-handle.filter-slider-state {
    margin-left: -16px;
}

.filter-content {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.filter-content span {
    color: #666;
}

.main--review {
    padding: 16px 0 66px;
}

.list-wrap > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.list-wrap > ul > li {
    width: 33.333333%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 991px) {
    .list-wrap > ul > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .list-wrap > ul > li {
        width: 100%;
    }
}

.list-wrap .slider-ads {
    margin: 32px 0;
}

@media (max-width: 767px) {
    .list-wrap .slider-ads {
        width: calc(100% + 32px);
        margin: 0 0 0 -16px;
    }
}

.list-wrap .slider-ads .slick-dots {
    bottom: 5px;
    left: 20px;
}

.list-wrap .slider-ads img {
    width: 100%;
}

.list-wrap-col2 {
    margin-top: -24px;
}

.list-wrap-col2 > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.list-wrap-col2 > ul > li {
    width: 50%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .list-wrap-col2 > ul > li {
        width: 100%;
    }
}

.reviewInfo .review-head {
    display: flex;
    flex-wrap: wrap;
}

.reviewInfo .review-acc {
    width: calc(100% - 96px);
    padding-right: 10px;
    display: flex;
    color: #444;
    font-weight: 500;
}

.reviewInfo .review-acc span {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    line-height: normal;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
}

.reviewInfo .review-acc span img {
    width: 100%;
}

.reviewInfo .review-start {
    display: flex;
    flex-wrap: wrap;
    width: 96px;
    margin-left: auto;
}

.reviewInfo .review-start li {
    margin-right: 4px;
}

.reviewInfo .review-start li:last-child {
    margin-right: 0;
}

.reviewInfo .review-start li img {
    width: 16px;
}

.reviewInfo .review-thumb {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 12px;
    padding-top: calc(100% - 35%);
    background: #f2f2f2;
}

.reviewInfo .review-thumb a {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviewInfo .review-thumb a img {
    max-height: 258px;
}

.reviewInfo .review-thumb .review-lable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
}

.reviewInfo .review-thumb .review-lable span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}

.reviewInfo .review-thumb .review-view {
    position: absolute;
    background: #222;
    border-radius: 6px;
    padding: 1px 5px;
    color: #fff;
    font-size: 10px;
    left: 8px;
    bottom: 8px;
    display: flex;
}

.reviewInfo .review-thumb .review-view img {
    width: 10px;
}

.reviewInfo .review-thumb .review-view .svg path {
    fill: #231f20;
}

.reviewInfo .review-thumb .review-view .number {
    margin-left: 3px;
}

.reviewInfo .review-content {
    padding-right: 30px;
    padding-top: 4px;
    position: relative;
    min-height: 20px;
}

.reviewInfo .review-content .tlt {
    margin-top: 6px;
    font-weight: 600;
}

.reviewInfo .review-content .tlt a {
    color: #222;
}

.reviewInfo .review-content .btn-save-bookmart {
    position: absolute;
    top: 12px;
    right: 0;
    width: 20px;
}

.reviewInfo .review-quote,
.reviewInfo .review-post {
    margin-top: 8px;
}

.reviewInfo .review-post .date,
.reviewInfo .review-post .like {
    color: #666;
}

.reviewInfo .review-post .date {
    font-size: 12px;
    padding-right: 15px;
}

.reviewInfo .review-post .like {
    font-size: 13px;
}

.main--discussion {
    padding: 0 0 66px;
}

.main--discussion .discussion-item {
    padding: 16px 0;
    border-bottom: 1px solid #eee;
}

@media (max-width: 767px) {
    .main--discussion .discussion-item {
        width: calc(100% + 32px);
        padding: 4px 16px 12px;
        margin-left: -16px;
    }
}

@media (max-width: 767px) {
    .main--discussion .discussion-item .photoStore-item li {
        font-size: 12px;
    }
}

.main--discussion .discussion-sort {
    margin-top: 16px;
}

.main--discussion .discussion-sort .filterItem.show .btn--dropdown {
    border: none;
}

.main--discussion .discussion-sort .filterItem .btn.btn--dropdown {
    background: none;
    border: none;
    padding: 0 34px 0 0;
}

@media (max-width: 767px) {
    .main--discussion .discussion-sort .filterItem .btn.btn--dropdown {
        font-size: 12px;
    }
}

.main--discussion .slider-ads {
    margin: 32px 0;
}

@media (max-width: 767px) {
    .main--discussion .slider-ads {
        width: calc(100% + 32px);
        margin: 0 0 0 -16px;
    }
}

.main--discussion .slider-ads .slick-dots {
    bottom: 5px;
    left: 20px;
}

.main--discussion .slider-ads img {
    width: 100%;
}

.main--discussion .lstDiscussion > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -24px;
}

.main--discussion .lstDiscussion > ul > li {
    padding-left: 24px;
    margin-top: 24px;
    width: 33.333333%;
}

@media (max-width: 991px) {
    .main--discussion .lstDiscussion > ul > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .main--discussion .lstDiscussion > ul > li {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .discussion .discussion-info {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .discussion .discussion-info .discussion-thumb {
        width: 80px;
        height: 80px;
        padding-top: 0;
    }

    .discussion .discussion-info .discussion-thumb img {
        min-height: 80px;
    }
}

@media (max-width: 767px) {
    .discussion .discussion-info .discussion-content {
        width: calc(100% - 80px);
        padding-left: 12px;
    }
}

.discussion .discussion-thumb {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    padding-top: calc(100% - 35%);
    background: #f2f2f2;
}

.discussion .discussion-thumb a {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.discussion .discussion-thumb a img {
    max-height: 235px;
}

.discussion .discussion-thumb .discussion-lable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
}

.discussion .discussion-thumb .discussion-lable span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}

.discussion .discussion-thumb .btn-save-bookmart {
    position: absolute;
    right: 14px;
    bottom: 14px;
    width: 18px;
    height: 22px;
    background-color: transparent;
    border: none;
    z-index: 10;
}

@media (max-width: 767px) {
    .discussion .discussion-thumb .btn-save-bookmart {
        right: 5px;
        bottom: 5px;
    }
}

.discussion .discussion-thumb .btn-save-bookmart img {
    min-height: inherit;
}

.discussion .photoStore-item {
    padding-top: 4px;
}

.discussion .tlt {
    margin-top: 8px;
    font-weight: 500;
    font-size: 16px;
}

@media (max-width: 767px) {
    .discussion .tlt {
        margin-top: 0;
    }
}

.discussion .tlt a {
    color: #222;
}

.discussion .discussion-post {
    margin-top: 8px;
}

.discussion .discussion-post .date,
.discussion .discussion-post .like {
    color: #666;
}

.discussion .discussion-post .date {
    font-size: 12px;
    padding-right: 15px;
}

.discussion .discussion-post .like {
    font-size: 13px;
}

.discussion-acc {
    display: flex;
    align-items: center;
    color: #444;
    font-weight: 500;
    margin-top: 8px;
}

@media (max-width: 767px) {
    .discussion-acc {
        font-size: 12px;
        font-weight: normal;
    }
}

.discussion-acc span {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;
}

@media (max-width: 767px) {
    .discussion-acc span {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
    }
}

.discussion-acc span img {
    width: 100%;
    border-radius: 50%;
}

.main--accountViewProFile {
    padding: 0 0 66px;
}

.main--accountViewProFile .wrap-row {
    padding-top: 24px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .wrap-row {
        padding-top: 0;
    }
}

.main--accountViewProFile .groupProFile .groupProFile-info {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .groupProFile .groupProFile-info {
        width: calc(100% + 32px);
        padding: 0 16px 16px;
        margin-left: -16px;
        margin-bottom: 16px;
        border-bottom: solid 6px #f4f4f4;
    }
}

@media (max-width: 991px) {
    .main--accountViewProFile
        .groupProFile
        .groupProFile-info
        .groupCategory-title {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .main--accountViewProFile
        .groupProFile
        .groupProFile-info
        .groupCategory-title {
        padding-top: 16px;
    }
}

.main--accountViewProFile .groupProFile .groupProFile-info:last-child {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .main--accountViewProFile .groupProFile .groupProFile-info:last-child {
        padding-bottom: 0;
        border: none;
    }
}

@media (max-width: 767px) {
    .main--accountViewProFile .groupCategory-title .groupCategory-tlt {
        font-size: 14px;
    }
}

.main--accountViewProFile
    .groupCategory-title
    .groupCategory-tlt
    .label-number {
    color: #34beef;
    padding-left: 6px;
}

.main--accountViewProFile .lstView {
    margin-top: -24px;
}

.main--accountViewProFile .lstView.lstView--changeFs .photoStore-item {
    padding-top: 4px;
}

.main--accountViewProFile .lstView.lstView--changeFs .photoStore-item li {
    font-size: 12px;
}

.main--accountViewProFile .lstView.lstView--changeFs .listPhoto-title {
    margin-top: 8px;
}

.main--accountViewProFile .lstView.lstView--changeFs .listPhoto-title a {
    font-size: 14px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView.lstView--Sp > ul > li {
        width: 100%;
    }
}

.main--accountViewProFile .lstView.lstView--col2 > ul > li {
    width: 50%;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView.lstView--col2 > ul > li {
        width: 100%;
    }
}

.main--accountViewProFile
    .lstView.lstView--col2
    .reviewInfo
    .review-thumb
    a
    img {
    max-height: 272px;
}

.main--accountViewProFile .lstView > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView > ul {
        margin-left: -12px;
    }
}

.main--accountViewProFile .lstView > ul > li {
    padding-left: 20px;
    margin-top: 24px;
    width: 33.333333%;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView > ul > li {
        width: 50%;
        padding-left: 12px;
    }
}

.main--accountViewProFile .lstView .listPhoto-thumb .read-views {
    bottom: 8px;
    left: 8px;
}

.main--accountViewProFile .lstView .listPhoto-label {
    font-size: 12px;
    margin-top: 4px;
}

.main--accountViewProFile .lstView .more {
    margin-top: 24px;
}

.main--accountViewProFile .lstView .more .btn {
    width: 100%;
}

.main--accountViewProFile .viewPhoto {
    margin-bottom: -4px;
}

.main--accountViewProFile .viewPhoto .viewPhoto-thumb {
    overflow: hidden;
    border-radius: 8px;
}

.main--accountViewProFile .viewPhoto ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .viewPhoto ul {
        margin-left: -2px;
    }
}

.main--accountViewProFile .viewPhoto ul li {
    width: 20%;
    padding-left: 4px;
    margin-top: 4px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .viewPhoto ul li {
        width: 33.333333%;
        margin-top: 2px;
        padding-left: 2px;
    }
}

.main--accountViewProFile .viewPhoto ul li img {
    width: 100%;
}

.main--accountViewProFile .viewPhoto .more {
    margin-top: 16px;
}

.main--accountViewProFile .viewPhoto .more .btn {
    width: 100%;
}

.main--accountViewProFile .lstDiscussion-col2 {
    margin-top: -24px;
}

@media (max-width: 991px) {
    .main--accountViewProFile .lstDiscussion-col2 {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstDiscussion-col2 {
        padding-top: 16px;
    }
}

.main--accountViewProFile .lstDiscussion-col2 > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--accountViewProFile .lstDiscussion-col2 > ul > li {
    width: 50%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstDiscussion-col2 > ul > li {
        width: 100%;
    }
}

.main--accountViewProFile
    .lstDiscussion-col2
    .discussion
    .discussion-thumb
    a
    img {
    max-height: 270px;
}

.main--accountViewProFile .filter--changePd16 {
    padding-top: 8px;
    padding-bottom: 24px;
}

/***/
table {
    width: 100%;
}

/**** radio checkbox ****/
.custom-control {
    display: inline-flex;
    min-height: 18px;
    padding-left: 24px;
    position: relative;
}

.custom-control .custom-control-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.custom-control .custom-control-input:checked ~ .custom-control-indicator {
    background-color: #34beef;
    border: 1px #34beef solid;
}

.custom-control .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(52, 190, 239, 0.25);
}

.custom-control .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #ddd;
    border: 1px #ccc solid;
    cursor: not-allowed;
}

.custom-control .custom-control-input:disabled ~ .custom-control-description {
    cursor: not-allowed;
}

.custom-control .custom-control-description {
    cursor: pointer;
}

.custom-control .custom-control-indicator {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    display: block;
    left: 0;
    position: absolute;
    top: calc(50% - 10px);
    cursor: pointer;
}

.custom-control.custom-checkbox .custom-control-indicator {
    border-radius: 4px;
    border: 1px #aaa solid;
    width: 18px;
    height: 18px;
}

.custom-control.custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-indicator {
    background: url(../images/iconCheckBox.svg) no-repeat 50% #34beef;
}

.custom-control.custom-checkbox
    .custom-control-input:disabled
    ~ .custom-control-indicator {
    background: url(../images/iconCheckBox.svg) no-repeat 50% #ddd;
}

.custom-control.custom-radio .custom-control-indicator {
    border-radius: 50%;
    border: 1px #c6c6c6 solid;
    width: 20px;
    height: 20px;
}

.custom-control.custom-radio
    .custom-control-input:checked
    ~ .custom-control-indicator {
    background: url(../images/icon-radio.svg) no-repeat 50%;
    border: 1px #34beef solid;
}

.custom-control.custom-radio
    .custom-control-input:disabled
    ~ .custom-control-indicator {
    background: #ddd;
    border: 1px #ccc solid;
    cursor: not-allowed;
}

/****/
/**** account ****/
.wrap-row {
    display: flex;
    flex-wrap: wrap;
}

.wrap-row .wrap-info {
    width: 270px;
}

@media (max-width: 991px) {
    .wrap-row .wrap-info {
        width: 100%;
    }
}

.wrap-row .wrap-container {
    width: calc(100% - 270px);
    padding-left: 40px;
}

.wrap-row .wrap-container.setting-form {
    /* width: calc(100% - 270px); */
    width: 100%;
    padding-left: 0px;
    margin: 0px auto;
}

@media (max-width: 991px) {
    .wrap-row .wrap-container {
        width: 100%;
        padding-left: 0;
    }
}

.account {
    background: #fff;
    border: 1px solid #ddd;
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .account {
        padding: 16px;
    }
}

@media (max-width: 767px) {
    .account {
        width: calc(100% + 32px);
        margin-left: -16px;
        border-radius: 0;
        border: none;
    }
}

@media (max-width: 991px) {
    .account .account-wrap {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 991px) {
    .account .account-heade {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 50%;
        padding-right: 10px;
    }
}

@media (max-width: 767px) {
    .account .account-heade {
        width: 100%;
        padding-right: 0;
    }
}

.account .account-heade .account-thumb {
    width: 64px;
    margin: 0 auto;
    position: relative;
}

@media (max-width: 991px) {
    .account .account-heade .account-thumb {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .account .account-heade .account-thumb {
        order: 2;
    }
}

.account .account-heade .account-thumb .thumb-avatar {
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
}

.account .account-heade .account-thumb .thumb-avatar img {
    width: 100%;
}

.account .account-heade .account-thumb .item-pen {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.account .account-heade .account-thumb .item-pen img {
    width: 100%;
}

.account .account-heade .account-content {
    padding-top: 16px;
    text-align: center;
}

@media (max-width: 991px) {
    .account .account-heade .account-content {
        width: calc(100% - 64px);
        padding: 0 0 0 16px;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .account .account-heade .account-content {
        order: 1;
        padding: 0 16px 0 0;
    }
}

.account .account-heade .account-content .tlt {
    font-weight: 600;
    font-size: 16px;
}

.account .account-heade .account-content .tlt .lable {
    display: inline-block;
    margin: -1px 0 0 3px;
}

.account .account-heade .account-content .service {
    font-size: 12px;
    color: #aaa;
}

@media (max-width: 991px) {
    .account .account-heade .account-content .service {
        margin-top: 4px;
    }
}

.account .account-heade .account-content .maps {
    font-size: 12px;
    font-weight: 500;
    color: #666;
}

@media (max-width: 991px) {
    .account .account-heade .account-content .maps {
        margin-top: 8px;
    }
}

.account .account-heade .account-content .maps span {
    display: inline-block;
    margin: -2px 4px 0 0;
}

.account .account-heade .account-content .follow {
    margin-top: 16px;
}

@media (max-width: 991px) {
    .account .account-heade .account-content .follow {
        margin-top: 8px;
    }
}

@media (max-width: 767px) {
    .account .account-heade .account-content .follow {
        margin-top: 0;
    }
}

.account .account-heade .account-content .follow li {
    display: inline-block;
    color: #666;
    padding: 0 4px;
    position: relative;
    font-size: 13px;
}

@media (max-width: 991px) {
    .account .account-heade .account-content .follow li:first-of-type {
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .account .account-heade .account-content .follow li {
        font-size: 12px;
    }
}

.account .account-heade .account-content .follow li::after {
    position: absolute;
    right: -3px;
    top: 5px;
    content: "";
    display: block;
    width: 1px;
    height: 12px;
    background: #aaa;
}

.account .account-heade .account-content .follow li:last-child::after {
    background: none;
    right: 0;
}

.account .account-heade .account-content .follow li span {
    color: #222;
}

.account .account-heade .account-content .account-btn {
    display: none;
}

@media (max-width: 767px) {
    .account .account-heade .account-content .account-btn {
        display: flex;
        margin-top: 8px;
    }
}

.account .account-btn {
    display: flex;
    margin-left: -8px;
    margin-top: 16px;
}

@media (max-width: 991px) {
    .account .account-btn {
        margin-top: 0;
    }
}

.account .account-btn .btn {
    width: 100%;
    margin-left: 8px;
    height: 32px;
    padding: 0 4px;
    font-size: 12px;
}

.account .account-btn .btn span {
    margin-right: 4px;
}

.account .account-btn .btn.btn.btn--yellow,
.account .account-btn .btn.btn--red {
    min-width: 120px;
}

.account .account-btn .btn.btn--blue {
    min-width: 92px;
}

.account .account-btnFirst {
    margin-top: 12px;
    text-align: center;
}

@media (max-width: 991px) {
    .account .account-btnFirst {
        text-align: right;
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .account .account-btnFirst {
        text-align: left;
    }
}

.account .account-btnFirst .btn--blueBold {
    padding: 0 8px;
    font-size: 10px;
    height: 24px;
}

@media (max-width: 991px) {
    .account .account-btnFirst .btn--blueBold {
        min-width: 130px;
        font-size: 12px;
        height: 32px;
    }
}

@media (max-width: 767px) {
    .account .account-btnFirst .btn--blueBold {
        min-width: inherit;
        font-size: 10px;
        height: 24px;
    }
}

.account .account-btnFirst .btn--blueBold span {
    display: inline-block;
    margin: 0 4px 0 0;
}

@media (max-width: 991px) {
    .account .account-info {
        width: 50%;
        padding-left: 10px;
    }
}

@media (max-width: 767px) {
    .account .account-info {
        width: 100%;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .account .account-info .account-btn {
        display: none;
    }
}

.account .account-info .rate-star {
    margin-top: 18px;
    padding-right: 0;
    justify-content: center;
}

@media (max-width: 991px) {
    .account .account-info .rate-star {
        justify-content: inherit;
        margin-top: 14px;
    }
}

@media (max-width: 767px) {
    .account .account-info .rate-star {
        /*display: none;*/
    }
}

.account .account-info .rate-star .star-list li img {
    width: 20px;
}

@media (max-width: 991px) {
    .account .account-info .rate-star .star-list li img {
        width: 16px;
    }
}

.account .account-info .point {
    margin-top: 18px;
    padding: 12px 18px 12px 0;
    border-top: 1px solid #f4f4f4;
    background: url(../images/icon-arrow-slider.svg) no-repeat center right;
}

@media (max-width: 991px) {
    .account .account-info .point {
        margin-top: 0;
        border: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .account .account-info .point {
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
        margin-top: 16px;
        justify-content: space-between;
    }
}

@media (max-width: 991px) {
    .account .account-info .point .point-text {
        margin-right: 4px;
    }
}

@media (max-width: 767px) {
    .account .account-info .point .point-text {
        font-size: 12px;
    }
}

.account .account-info .point .point-text,
.account .account-info .point .point-number {
    font-weight: 500;
}

.account .account-info .point .point-number {
    color: #34beef;
    font-size: 12px;
}

.account .myShop {
    border-top: 1px solid #f4f4f4;
    padding-top: 16px;
}

@media (max-width: 767px) {
    .account .myShop {
        /*display: none;*/
    }
}

.account .myShop .btn {
    height: 40px;
    font-weight: 600;
    width: 100%;
    max-width: 333px;
}

.account .myShop .btn span {
    margin-right: 8px;
}

.btn-message {
    padding-top: 12px;
}

@media (max-width: 991px) {
    .btn-message {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #f4f4f4;
        display: flex;
    }
}

@media (max-width: 767px) {
    .btn-message {
        margin-top: 0;
        padding-top: 24px;
        border-top: none;
    }
}

.btn-message .btn {
    margin-top: 12px;
    width: 100%;
    height: 40px;
}

@media (max-width: 991px) {
    .btn-message .btn {
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .btn-message .btn.btn--blueWhite {
        margin-left: 12px;
    }
}

@media (max-width: 767px) {
    .btn-message .btn.btn--blueWhite {
        margin-left: 8px;
    }
}

.account .account-btnSp {
    display: none;
    margin-top: 6px;
}

@media (max-width: 767px) {
    .account .account-btnSp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: calc(100% + 80px);
    }
}

.account .account-btnSp .rate-star {
    padding-right: 0;
}

.account .account-btnSp .account-btn-info {
    padding-right: 10px;
}

.account .account-btnSp .account-btn-info .account-btn {
    margin-top: 0;
}

.account .account-btnSp .account-btn-info .btn {
    padding: 0 8px;
    height: 24px;
    min-width: inherit;
    width: auto;
}

.account .account-btnSp .account-btn-info .btn span {
    margin-top: -1px;
    display: inline-block;
}

.account .account-btnSp .account-btn-info .btn span img {
    max-height: 16px;
}

.member-level {
    border-radius: 10px !important;
}

.review_list {
    display: flex;
    flex-flow: wrap;
    margin-left: -20px;
}

.review_list > li {
    width: 33.33%;
    padding-left: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .review_list > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .review_list > li {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .review_list > li:last-child {
        display: none;
    }
}

@media (max-width: 767px) {
    .review_list > li:last-child {
        display: flex;
    }
}

.review_list > li .review-thumb {
    width: 200px;
    height: 100px;
    overflow: hidden;
    border-radius: 6px;
}

.review_list > li .review-thumb img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.review_list > li .review-info {
    width: calc(100% - 200px);
    padding-left: 15px;
}

.review_list > li .star-list {
    margin-bottom: 5px;
}

.review_list > li .tag_list {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 8px;
}

.review_list > li .tag_list .tag-items {
    display: inline-block;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    background-color: #f8f8f8;
    color: #666;
    margin-right: 4px;
    cursor: pointer;
}

.review_list > li .tag_list .tag-items:last-child {
    margin-right: 0;
}

.review_list > li .desc {
    margin-bottom: 5px;
    color: #444;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.review_list > li .review-athor {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: #222;
}

/*** EXPERT CHUYEN GIA ***/
.main--expert {
    padding-top: 10px;
}

.main--expert .expert-head {
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .main--expert .expert-head {
        border-bottom: none;
        margin-bottom: 12px;
    }
}

.main--expert .filter {
    /* margin-bottom: 12px; */
}

.main--expert .slider-ads {
    position: relative;
    margin-bottom: 12px;
}

.main--expert .slider-ads .items img {
    width: 100%;
}

.main--expert .slider-ads .slick-dots {
    bottom: 5px;
    left: 2.8vw;
}

.expert-ctg {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
}

@media (max-width: 767px) {
    .expert-ctg {
        width: calc(100% + 16px);
    }
}

.expert-ctg li {
    display: inline-block;
    width: 76px;
    text-align: center;
    padding-right: 8px;
}

.expert-ctg li a {
    display: block;
}

.expert-ctg li a.active .ctg-thumb {
    border: 1px solid #278ff7;
}

.expert-ctg li a.active .ctg-name {
    color: #278ff7;
}

.expert-ctg li a .ctg-thumb {
    display: block;
    width: 56px;
    border-radius: 50%;
    height: 56px;
    border: 1px solid rgba(255, 255, 255, 0);
    background-color: #fff;
    padding: 4px;
    overflow: hidden;
    margin: 0 auto 8px;
}

.expert-ctg li a .ctg-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.expert-ctg li a .ctg-name {
    font-size: 13px;
    font-weight: 500;
    color: #444;
    display: block;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.products__featured-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .products__featured-list {
        display: inherit;
        overflow-x: auto;
        white-space: nowrap;
        margin-left: 0;
        margin-right: -16px;
    }
}

.products__featured-list > li {
    width: 25%;
    padding-left: 20px;
    margin-bottom: 24px;
}

@media (max-width: 991px) {
    .products__featured-list > li {
        width: 33.33%;
    }
}

@media (max-width: 767px) {
    .products__featured-list > li {
        width: 43%;
        display: inline-block;
        padding-right: 20px;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .products__featured-list > li:last-child {
        display: inline-block;
    }
}

.products__featured-list > li .product-thumb {
    margin-bottom: 12px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    padding-top: calc(100% - 35%);
    background: #f2f2f2;
}

.products__featured-list > li .product-thumb .thumb {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.products__featured-list > li .product-thumb .views-number {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(34, 34, 34, 0.7);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    padding: 2px 4px;
}

.products__featured-list > li .product-thumb .save-book {
    cursor: pointer;
    width: 16px;
    height: 20px;
    position: absolute;
    bottom: 10px;
    right: 12px;
}

.products__featured-list > li .product-thumb .logo-salon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    bottom: 8px;
    left: 12px;
}

.products__featured-list > li .product-thumb .logo-salon img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.products__featured-list > li .product-name {
    padding-bottom: 4px;
}

@media (max-width: 767px) {
    .products__featured-list > li .product-name {
        white-space: initial;
    }
}

.products__featured-list > li .product-name a {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: #222;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 767px) {
    .products__featured-list > li .product-name a {
        font-size: 12px;
    }
}

.products__featured-list > li .sub-ctg {
    display: flex;
    align-items: center;
}

.products__featured-list > li .sub-ctg li + li {
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid #666666;
}

.products__featured-list > li .sub-ctg li a {
    color: #666666;
    font-size: 14px;
    line-height: 100%;
    display: block;
}

@media (max-width: 767px) {
    .products__featured-list > li .sub-ctg li a {
        font-size: 12px;
    }
}

.comment__best-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .comment__best-list {
        overflow-x: auto;
        white-space: nowrap;
        margin-left: 0;
        width: calc(100% + 16px);
        display: block;
    }
}

.comment__best-list > li {
    width: 33.33%;
    padding-left: 20px;
    margin-bottom: 24px;
}

@media (max-width: 991px) {
    .comment__best-list > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .comment__best-list > li {
        display: inline-block !important;
        width: 75%;
        padding-left: 0;
        padding-right: 10px;
    }
}

@media (max-width: 991px) {
    .comment__best-list > li:last-child {
        display: block;
    }
}

.comment__best-list > li .inner {
    border: 1px solid #f3f3f3;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    height: 100%;
}

.comment__best-list > li .inner .comment-head {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.comment__best-list > li .inner .comment-head .user-thumb {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.comment__best-list > li .inner .comment-head .user-info {
    width: calc(100% - 40px);
    padding-left: 8px;
}

.comment__best-list > li .inner .comment-head .user-info .user-name {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #444;
}

.comment__best-list > li .inner .comment-body {
    display: flex;
    justify-content: space-between;
    height: 60px;
    overflow: hidden;
}

.comment__best-list > li .inner .comment-body .comment-text {
    width: calc(100% - 56px);
    padding-right: 16px;
    color: #666;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 767px) {
    .comment__best-list > li .inner .comment-body .comment-text {
        white-space: initial;
        font-size: 12px;
    }
}

.comment__best-list > li .inner .comment-body .commont-img {
    width: 56px;
    height: 56px;
}

.comment__best-list > li .inner .comment-body .commont-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.expert__featured-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.expert__featured-list > li {
    width: 33.33%;
    padding-left: 20px;
    margin-bottom: 48px;
}

.expert__featured-list > li:last-child {
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .expert__featured-list > li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .expert__featured-list > li {
        width: 100%;
    }
}

.expert__featured-list.coupon-pro-modal > li {
    width: 50%;
}

@media (max-width: 991px) {
    .expert__featured-list.coupon-pro-modal > li {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .expert__featured-list.coupon-pro-modal > li {
        width: 100%;
    }
}

.expert__featured-list > li .expert__user-head {
    display: flex;
    justify-content: space-between;
}

.expert__featured-list > li .expert__user-head .user-thumb {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.expert__featured-list > li .expert__user-head .user-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.expert__featured-list > li .expert__user-head .user-info {
    width: calc(100% - 40px);
    padding-left: 8px;
    margin-bottom: 12px;
}

.expert__featured-list > li .expert__user-head .user-info .info-row-1 {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 4px;
}

.expert__featured-list > li .expert__user-head .user-info .user-name {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    color: #222;
}

.expert__featured-list > li .expert__user-head .user-info .user-stt {
    display: inline-block;
    color: #f0fbff;
    background-color: #34beef;
    padding: 4px 3px;
    line-height: 100%;
    font-size: 12px;
    border-radius: 7px;
    margin: 0 4px 0 3px;
    border: 2px solid #e7fcfd;
}

.expert__featured-list > li .expert__user-head .user-info .btn-follow {
    position: relative;
    font-size: 13px;
    color: #34beef;
    font-weight: 500;
    padding-left: 10px;
    background-color: transparent;
    border: none;
}

.expert__featured-list > li .expert__user-head .user-info .btn-follow::focus {
    outline: none;
}

.expert__featured-list > li .expert__user-head .user-info .btn-follow::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: #aaaaaa;
    margin-top: -3px;
}

.expert__featured-list > li .expert__user-head .user-info .member-level {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: #444;
    align-items: center;
    margin-bottom: 0px;
}

.expert__featured-list > li .expert__user-head .user-info .member-level img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-top: -5px;
}

.expert__featured-list > li .expert__user-head .user-info .list-ctg {
    display: flex;
    flex-flow: wrap;
    align-items: center;
}

.expert__featured-list > li .expert__user-head .user-info .list-ctg li {
    position: relative;
    margin-right: 7px;
}

.expert__featured-list > li .expert__user-head .user-info .list-ctg li + li {
    padding-left: 13px;
}

.expert__featured-list
    > li
    .expert__user-head
    .user-info
    .list-ctg
    li
    + li::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    background-color: #aaaaaa;
    top: 50%;
    margin-top: -3px;
    left: 0;
    border-radius: 50%;
}

.expert__featured-list > li .expert__user-head .user-info .list-ctg li a {
    color: #aaa;
    font-size: 12px;
}

.expert__featured-list > li .expert__thumb-list {
    display: flex;
    margin-left: -4px;
    margin-bottom: 12px;
}

.expert__featured-list > li .expert__thumb-list li {
    position: relative;
    margin-left: 4px;
    width: calc(33.33% - 4px);
    overflow: hidden;
}

.expert__featured-list > li .expert__thumb-list li:last-child {
    border-radius: 0 8px 8px 0;
}

.expert__featured-list > li .expert__thumb-list li:first-child {
    border-radius: 8px 0 0 8px;
}

.expert__featured-list > li .expert__thumb-list li .view-more {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    z-index: 2;
    left: 0;
    top: 0;
    cursor: pointer;
}

.view-more-number {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    z-index: 2;
    left: 0;
    top: 0;
    cursor: pointer;
}

.expert__featured-list > li .expert__thumb-list li img {
    object-fit: cover;
}

.expert__featured-list > li .expert__info .rate {
    margin-bottom: 12px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
}

.expert__featured-list > li .expert__info .rate .rate-star {
    background-color: #e7fcfd;
    padding: 2px 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #34beef;
    margin-right: 12px;
}

.expert__featured-list > li .expert__info .rate .rate-star img {
    margin-left: 5px;
    width: 12px;
    margin-bottom: 3px;
}

.expert__featured-list > li .expert__info .rate .rate-view {
    display: flex;
    align-items: center;
    flex-flow: wrap;
}

.expert__featured-list > li .expert__info .rate .rate-view li {
    position: relative;
    margin-right: 7px;
    font-size: 12px;
    color: #666;
}

.expert__featured-list > li .expert__info .rate .rate-view li + li {
    padding-left: 13px;
}

.expert__featured-list > li .expert__info .rate .rate-view li + li::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    background-color: #aaaaaa;
    top: 50%;
    margin-top: -4px;
    left: 0;
    border-radius: 50%;
}

.expert__featured-list > li .expert__info .location__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;
}

.expert__featured-list > li .expert__info .location__wrap .location {
    font-size: 12px;
    font-weight: 500;
    color: #666;
}

.expert__featured-list > li .expert__info .location__wrap .voucher {
    color: #34beef;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
}

.expert__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .expert__list {
        margin-left: -16px;
    }
}

.expert__list > li {
    width: calc(100% / 6);
    padding-left: 20px;
    margin-bottom: 24px;
}

@media (max-width: 991px) {
    .expert__list > li {
        width: 22.5%;
    }
}

@media (max-width: 767px) {
    .expert__list > li {
        width: 45%;
        padding-left: 16px;
        margin-bottom: 16px;
    }

    .expert__list.mobile-2 > li {
        width: calc(100% / 3);
    }
}

.expert__list > li .items {
    position: relative;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    overflow: hidden;
}

.expert__list > li .items .rate-star {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #e7fcfd;
    padding: 2px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #34beef;
}

.expert__list > li .items .rate-star img {
    margin-left: 5px;
    width: 12px;
}

.expert__list > li .items .user-info {
    padding: 15px;
    background-color: #f8f8f8;
}

@media (max-width: 767px) {
    .expert__list > li .items .user-info {
        padding: 10px 7px;
    }
}

.expert__list > li .items .user-info .user-thumb {
    width: 40px;
    height: 40px;
    text-align: center;
    margin: 0 auto 8px;
    border-radius: 50%;
}

.expert__list > li .items .user-info .user-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

@media (max-width: 767px) {
    .expert__list > li .items .user-info .member-level {
        font-size: 12px;
        padding: 5px;
    }
}

.expert__list > li .items .user-info .user-name {
    display: flex;
    justify-content: center;
    align-items: center;
}

.expert__list > li .items .user-info .user-name .name {
    font-weight: 600;
    color: #222;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 30px);
}

.expert__list > li .items .user-info .user-name .vendor-name {
    font-weight: 600;
    color: #222;
    display: block;
}

.expert__list > li .items .user-info .user-name .user-stt {
    background-color: #34beef;
    width: 20px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #f0fbff;
    border-radius: 4px;
    line-height: 0;
    margin-left: 5px;
}

.expert__list > li .items .user-info .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    margin-bottom: 0;
    font-size: 12px;
    color: #666666;
}

.expert__list > li .items .btn-flow {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    color: #34beef;
    justify-content: center;
    padding: 8px;
}

.expert__list > li .items .btn-flow img {
    margin-right: 5px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin-bottom: 48px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview {
        border: none;
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 15px;
        border-bottom: 5px solid #f4f4f4;
        border-radius: 0;
        padding-bottom: 6px;
        padding-top: 12px;
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .showroom-over {
        width: 48.5%;
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .showroom-over {
        width: 100%;
    }
}

.expert__wrap .expert__wrap-overview .showroom-over .logo-showroow {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 12px;
    border-radius: 50%;
}

.logo-showroow img {
    border-radius: 50%;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .showroom-over .logo-showroow {
        width: 64px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .showroom-over .logo-showroow {
        order: 2;
    }
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .showroom-over .showroom-info {
        width: calc(100% - 64px);
        padding-left: 16px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .showroom-over .showroom-info {
        order: 1;
        padding-left: 0;
        padding-right: 16px;
    }
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-name {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 4px;
    justify-content: center;
}

@media (max-width: 991px) {
    .expert__wrap
        .expert__wrap-overview
        .showroom-over
        .showroom-info
        .showroom-name {
        justify-content: flex-start;
    }
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-name
    .user-name {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    color: #222;
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-name
    .user-stt {
    display: inline-block;
    color: #f0fbff;
    background-color: #34beef;
    padding: 4px 3px;
    line-height: 100%;
    font-size: 12px;
    border-radius: 7px;
    margin: 0 4px 0 3px;
    border: 2px solid #e7fcfd;
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-job {
    font-size: 12px;
    color: #aaa;
    text-align: center;
    margin-bottom: 5px;
}

@media (max-width: 991px) {
    .expert__wrap
        .expert__wrap-overview
        .showroom-over
        .showroom-info
        .showroom-job {
        text-align: left;
    }
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-location {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #666;
    margin-bottom: 16px;
}

@media (max-width: 991px) {
    .expert__wrap
        .expert__wrap-overview
        .showroom-over
        .showroom-info
        .showroom-location {
        margin-bottom: 0;
        text-align: left;
    }
}

.gallery__featured {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 12px;
}

.gallery__featured .gallery__featured-img {
    display: flex;
    flex-flow: wrap;
    margin-left: -4px;
}

.gallery__featured .gallery__featured-img li {
    width: calc(20% - 4px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0 2px 4px;
}

@media (max-width: 767px) {
    .gallery__featured .gallery__featured-img li {
        width: calc(33.33% - 4px);
    }
}

.gallery__featured .gallery__featured-img.column-3 li {
    width: calc(33.33% - 4px);
}

/***REVIEW Ảnh */
.reviewPicture {
    margin-top: -8px;
}

@media (max-width: 991px) {
    .reviewPicture {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .reviewPicture {
        padding-top: 16px;
        margin-top: -2px;
    }
}

.reviewPicture > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}

@media (max-width: 767px) {
    .reviewPicture > ul {
        margin-left: -2px;
    }
}

.reviewPicture > ul > li {
    width: 33.333333%;
    padding-left: 8px;
    margin-top: 8px;
}

@media (max-width: 767px) {
    .reviewPicture > ul > li {
        padding-left: 2px;
        margin-top: 2px;
    }
}

.reviewPicture > ul > li .thumb {
    padding-top: 100%;
    position: relative;
    background: #f2f2f2;
    border-radius: 8px;
    overflow: hidden;
}

.reviewPicture > ul > li a {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reviewPicture > ul > li a img {
    width: 100%;
}

/***/
.navArchive {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
}

@media (max-width: 767px) {
    .navArchive {
        border-top: none;
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.navArchive ul {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}

@media (max-width: 767px) {
    .navArchive ul {
        padding: 0 16px;
    }
}

.navArchive ul li {
    display: inline-block;
    padding-right: 24px;
}

.navArchive ul li:last-child {
    padding-right: 0;
}

@media (max-width: 991px) {
    .navArchive ul li {
        padding-right: 14px;
    }
}

@media (max-width: 767px) {
    .navArchive ul li {
        padding-right: 8px;
    }
}

.navArchive ul li a {
    padding: 16px 0;
    display: block;
    color: #444;
    font-weight: 600;
    position: relative;
}

@media (max-width: 767px) {
    .navArchive ul li a {
        font-size: 13px;
        padding: 14px 0 9px;
    }
}

.navArchive ul li a.active {
    color: #34beef;
}

.navArchive ul li a.active::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background: #34beef;
    display: block;
}

.navArchive ul li a span {
    color: #34beef;
}

/***/
.profileInformation .title {
    font-weight: 600;
    font-size: 20px;
}

@media (max-width: 991px) {
    .profileInformation .title {
        margin-top: 40px;
    }
}

.profileInformation .profileRegister {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 16px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 12px;
}

@media (max-width: 767px) {
    .profileInformation .profileRegister {
        display: block;
        padding: 12px;
        margin-top: 24px;
    }
}

.profileInformation .profileRegister .profileRegister-text .qa {
    font-weight: 600;
}

.profileInformation .profileRegister .profileRegister-text .lable {
    font-size: 13px;
    font-weight: 500;
    color: #7d7d7d;
}

@media (max-width: 767px) {
    .profileInformation .profileRegister .profileRegister-text .lable {
        font-size: 12px;
        font-weight: 400;
    }
}

.profileInformation .profileRegister .profileRegister-text .lable a {
    color: #34beef;
}

.profileInformation .profileRegister .profileRegister-btn .btn {
    width: 320px;
    height: 48px;
    font-size: 16px;
}

@media (max-width: 767px) {
    .profileInformation .profileRegister .profileRegister-btn .btn {
        width: 100%;
        margin-top: 16px;
    }
}

/***/
.profileAvatar {
    width: 120px;
    margin: 40px auto 0;
    position: relative;
}

@media (max-width: 767px) {
    .profileAvatar {
        width: 72px;
        margin: 14px auto 0;
    }
}

.profileAvatar .profile-thumb {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
}

@media (max-width: 767px) {
    .profileAvatar .profile-thumb {
        width: 72px;
        height: 72px;
    }
}

.profileAvatar .item-pen {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .profileAvatar .item-pen {
        width: 24px;
        height: 24px;
    }
}

.profileAvatar .item-pen img {
    width: 100%;
}

/****/
.profileForm {
    padding-top: 16px;
}

@media (max-width: 767px) {
    .profileForm {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .profileForm.profileForm--bodSp {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px 24px;
        border-bottom: 6px solid #f4f4f4;
    }
}

.profileForm > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.profileForm > ul > li {
    padding-left: 20px;
    width: 50%;
}

@media (max-width: 767px) {
    .profileForm > ul > li {
        width: 100%;
    }
}

.profileForm .profile-group {
    margin-top: 24px;
}

.profileForm .profile-group .profile-group-lable {
    font-weight: 500px;
}

.profileForm .profile-group .profile-group-lable .required {
    color: #ff4451;
    padding-left: 4px;
}

.profileForm .profile-group .profile-group-info {
    margin-top: 8px;
}

.profileForm .profile-group .profile-group-info .form-control {
    height: 44px;
    border-radius: 8px;
}

.profileForm .profile-group .profile-group-info textarea.form-control {
    height: 180px;
}

.profileForm .profile-group .profile-group-info .custom-control {
    padding-right: 50px;
}

.profileForm .profile-group .profile-group-info .custom-control:last-child {
    padding-right: 0;
}

.profileForm .formUpload-thumb {
    margin-top: -12px;
}

/****/
.profileShare {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
}

@media (max-width: 767px) {
    .profileShare {
        justify-content: space-between;
    }

    .profile-shop-products {
        margin-top: 48px;
    }
}

.profileShare .profileShare-lable {
    color: #aaa;
    padding-right: 40px;
}

.profileShare ul {
    display: flex;
    flex-wrap: wrap;
}

.profileShare ul li {
    margin-right: 16px;
}

.profileShare ul li:last-child {
    margin-right: 0;
}

.profileShare ul li img {
    width: 32px;
}

@media (max-width: 767px) {
    .profileShare ul li img {
        width: 24px;
    }
}

/****/
@media (max-width: 767px) {
    .lstRated .comment__best-list {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding-left: 16px;
    }
}

.lstRated .comment__best-list > li {
    width: 50%;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .lstRated .comment__best-list > li {
        width: 75%;
    }
}

.lstRated .comment__best-list > li:last-child {
    display: initial;
}

.lstRated .btn--blue-light {
    margin-top: 16px;
}

/****/
.settingGroup .profileAvatar {
    margin-top: 32px;
}

@media (max-width: 767px) {
    .settingGroup .profileAvatar {
        margin-top: 14px;
    }
}

.settingGroup .btnSave {
    margin-top: 40px;
}

.settingGroup .btnSave .btn {
    height: 48px;
    font-size: 16px;
    width: 310px;
}

.navSetting {
    /***/
}

@media (max-width: 767px) {
    .navSetting {
        border-top: 1px solid #f3f3f3;
    }
}

@media (max-width: 767px) {
    .navSetting.navSetting--changeSp {
        border-top: none;
    }
}

@media (max-width: 767px) {
    .navSetting.navSetting--changeSp .nav li a.active::after,
    .navSetting.navSetting--changeSp .nav li a.active::before {
        border: none;
    }

    .navSetting.navSetting--changeSp .nav li a.active::after {
        background: #34beef;
        width: 100%;
        height: 2px;
        left: 0;
    }
}

.navSetting .tab-content {
    border-bottom: 1px solid #f3f3f3;
}

.navSetting .nav {
    justify-content: center;
    border-bottom: 1px solid #f3f3f3;
}

.navSetting .nav li a {
    padding: 0 28px;
    line-height: 48px;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #444;
    position: relative;
}

@media (max-width: 767px) {
    .navSetting .nav li a {
        padding: 0 12px;
        font-size: 14px;
    }
}

.navSetting .nav li a.active {
    color: #34beef;
}

.navSetting .nav li a.active::after,
.navSetting .nav li a.active::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
}

.navSetting .nav li a.active::after {
    bottom: 0;
    left: calc(50% - 3px);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #f3f3f3;
}

.navSetting .nav li a.active::before {
    bottom: -1px;
    left: calc(50% - 3px);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    z-index: 1;
}

.navSetting .navHistory {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;
    width: 100%;
    text-align: center;
}

@media (max-width: 767px) {
    .navSetting .navHistory {
        width: calc(100% + 32px);
        padding: 0 16px;
        margin-left: -16px;
    }
}

.navSetting .navHistory li {
    padding-right: 44px;
    display: inline-block;
}

@media (max-width: 767px) {
    .navSetting .navHistory li {
        padding-right: 12px;
    }
}

.navSetting .navHistory li:last-child {
    padding-right: 0;
}

@media (max-width: 767px) {
    .navSetting .navHistory li:last-child {
        padding-right: 16px;
    }
}

.navSetting .navHistory li a {
    line-height: 48px;
    display: block;
    color: #444;
    font-weight: 500;
    position: relative;
}

.navSetting .navHistory li a.active {
    color: #34beef;
}

.navSetting .navHistory li a.active:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    background: #34beef;
}

.navSetting .navHistory li a.active .svg path {
    fill: #34beef;
}

.navSetting .navHistory li a span {
    color: #34beef;
    padding-left: 4px;
}

/*TaoBaiReviewSanPham*/
.main--createPost {
    background: #f8f8f8;
    padding: 0 0 66px;
}

@media (max-width: 767px) {
    .main--createPost {
        margin-bottom: -57px;
    }
}

.main--createPost .wrap-content {
    padding-top: 8px;
}

@media (max-width: 767px) {
    .main--createPost .wrap-content {
        padding: 0;
    }
}

.main--createPost .process {
    width: 50%;
}

.main--createPost .createPost-box {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 20px;
    margin-top: 16px;
}

@media (max-width: 767px) {
    .main--createPost .createPost-box {
        margin-top: 8px;
    }
}

.main--createPost .createPost-box.createPost--change .createPost-lst {
    margin-top: -16px;
}

@media (max-width: 767px) {
    .main--createPost .createPost-box {
        width: calc(100% + 32px);
        margin-left: -16px;
        border: none;
        border-radius: 0;
    }
}

.main--createPost .createPost-box .createPost-title .title {
    font-weight: 600;
    font-size: 18px;
}

.main--createPost .createPost-box .createPost-title .note {
    color: #666;
    margin-top: 4px;
    font-weight: 500;
}

.main--createPost .createPost-lst > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--createPost .createPost-lst > ul > li {
    width: 50%;
    padding-left: 20px;
}

@media (max-width: 767px) {
    .main--createPost .createPost-lst > ul > li {
        width: 100%;
    }
}

.main--createPost .createPost-lst .createPost-label {
    margin-top: 16px;
    font-weight: 500;
    display: flex;
}

.main--createPost .createPost-lst .createPost-label .createPost-number {
    color: #aaa;
    margin-left: auto;
}

.main--createPost .createPost-lst .createPost-label .poin_items {
    font-size: 10px;
    margin-left: 10px;
}

.main--createPost .createPost-lst .createPost-select {
    margin-top: 12px;
}

.main--createPost .createPost-lst .createPost-select .createPost-col {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}

.main--createPost .createPost-lst .createPost-select .createPost-col li {
    width: 50%;
    padding-left: 8px;
}

.main--createPost .createPost-lst .createPost-select .form-control {
    height: 44px;
}

.main--createPost .createPost-lst .star-list {
    margin-left: 0px;
    margin-top: 16px;
}

.main--createPost .createPost-lst .star-list li {
    padding-left: 0px;
    padding-right: 8px;
}

.main--createPost .createPost-lst .createPost-add .photoStore-head {
    margin-top: 12px;
    padding-right: 36px;
    position: relative;
}

.photoStore-head .new-product {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.main--createPost
    .createPost-lst
    .createPost-add
    .photoStore-head
    .photoStore-head__item {
    width: 40px;
    height: 40px;
}

.main--createPost
    .createPost-lst
    .createPost-add
    .photoStore-head
    .photoStore-head__text {
    width: calc(100% - 40px);
}

.main--createPost .createPost-lst .createPost-add .photoStore-head .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
    background: rgba(34, 34, 34, 0.2);
}

.main--createPost
    .createPost-lst
    .createPost-add
    .photoStore-head
    .btn-close:focus {
    box-shadow: none;
}

.main--createPost .createPost-lst .createPost-note {
    color: #666;
    font-size: 12px;
    margin-top: 12px;
    line-height: 16px;
}

.main--createPost .createPost-edit {
    margin-top: 24px;
}

.main--createPost .createPost-edit .color-gray {
    color: #aaa;
}

.main--createPost .createPost-edit .createPost-edit-tlt {
    font-weight: 500;
}

.main--createPost .createPost-edit .createPost-edit-title {
    font-size: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid #eee;
    margin-top: 8px;
}

/* .main--createPost .createPost-edit .tox-editor-header {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.main--createPost .createPost-edit .tox-toolbar-overlord {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.main--createPost .createPost-edit .tox-toolbar-overlord .tox-toolbar__primary {
    border: none !important;
    background: none;
}

.main--createPost .createPost-edit .tox-statusbar {
    display: none;
}

.main--createPost .createPost-edit .tox-tinymce {
    border: 1px solid black;
} */

.tox-notifications-container {
    display: none;
}

.tox-tinymce-aux {
    z-index: 2000;
}

.createPost-edit-note {
    background: #f8f8f8;
    border-radius: 12px;
    padding: 10px 12px;
    margin-top: 16px;
    font-size: 12px;
    color: #aaa;
    line-height: 16px;
    display: flex;
    align-items: center;
}

.createPost-edit-note span {
    min-width: 40px;
}

.main--createPost .searchTag {
    padding-top: 16px;
    border-top: 1px solid #eee;
}

.main--createPost .createPost-tag {
    display: flex;
    align-items: center;
    position: relative;
}

.main--createPost .createPost-tag .createPost-tag-other {
    min-width: 20px;
}

.main--createPost .createPost-tag .form-control {
    padding: 0;
    border: none;
}

.main--createPost .uploadFite {
    margin-top: 24px;
}

.main--createPost .uploadFite .custom-control {
    min-height: 194px;
}

.main--createPost .uploadFite .custom-control .btn {
    width: 100%;
    background: #f8f8f8;
    padding-top: 45px;
}

.main--createPost .uploadFite .custom-control .btn .tlt {
    margin-top: 16px;
    font-weight: 600;
    font-size: 18px;
}

.main--createPost .uploadFite .custom-control .btn .note {
    color: #666;
    margin-top: 8px;
}

.main--createPost .uploadFite-img {
    margin-top: 24px;
}

.main--createPost .uploadFite-img .custom-control {
    min-height: 60px;
}

.main--createPost .uploadFite-img .custom-control .btn {
    background: #f8f8f8;
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 16px 10px;
}

.dropdown-menu--tag {
    min-width: 300px;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 0;
}

@media (max-width: 767px) {
    .dropdown-menu--tag {
        border-radius: 0;
        position: fixed !important;
        transform: none !important;
        width: 100%;
        height: 100%;
        margin: 0;
        background: rgba(34, 34, 34, 0.2);
    }
}

.dropdown-menu--tag .boxTag {
    padding: 0 0 16px;
}

@media (max-width: 767px) {
    .dropdown-menu--tag .boxTag {
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 24px 24px 0px 0px;
        padding: 28px 0 30px;
    }
}

.dropdown-menu--tag .lstTag ul {
    max-height: 300px;
    min-height: 300px;
    overflow-y: auto;
}

.dropdown-menu--tag .lstTag ul li {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .dropdown-menu--tag .lstTag ul li {
        font-size: 13px;
    }
}

.dropdown-menu--tag .lstTag ul li.active .lstTag-text::after {
    position: absolute;
    top: 4px;
    right: 0;
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    background: url(../images/icon-radio-tick.svg) no-repeat top right;
    background-size: 15px;
}

.dropdown-menu--tag .lstTag ul li .lstTag-text {
    max-width: 60%;
    position: relative;
    padding-right: 20px;
    color: #666;
}

.dropdown-menu--tag .lstTag ul li .lstTag-number {
    color: #aaa;
}

.dropdown-menu--tag .headTab {
    position: relative;
}

.dropdown-menu--tag .headTab .filter-close {
    position: absolute;
    top: -6px;
    right: 20px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.dropdown-menu--tag .headTab {
    padding: 0 16px;
}

.dropdown-menu--tag .headTab .tlt {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
}

.dropdown-menu--tag .headTab .search {
    position: relative;
    margin: 24px 0 0;
}

.dropdown-menu--tag .headTab .search .form-control {
    padding: 8px 12px 8px 44px;
    border: 1px solid #ddd;
    height: 40px;
}

.dropdown-menu--tag .headTab .search .btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 44px;
    padding: 0;
}

.dropdown-menu--tag .headTab .search .btn:focus {
    box-shadow: none;
}

.dropdown-menu--tag .list-tag {
    padding: 4px 0 10px;
}

.dropdown-menu--tag .btnTag {
    padding: 16px 16px 0;
}

.dropdown-menu--tag .btnTag .btn {
    height: 40px;
    width: 100%;
    font-size: 14px;
}

.slider--update {
    margin-top: 8px;
}

.slider--update .slick-list {
    margin-left: -7px;
}

.slider--update .slick-list .slick-slide {
    padding-left: 7px;
}

.slider--update .photoGallery-reality {
    border-radius: 4px;
    overflow: hidden;
}

.slider--update .custom-control {
    height: 100%;
}

.slider--update .custom-control .btn {
    width: 100%;
    padding: 0;
}

.slider--update .custom-control img {
    width: 100%;
}

.lstPro {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding-right: 66px;
}

.lstPro .lstPro-thumb {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
}

.lstPro .lstPro-content {
    width: calc(100% - 40px);
    padding-left: 8px;
    font-size: 14px;
    font-weight: 500;
}

.lstPro .btn.btn--blue {
    width: 60px;
    position: absolute;
    top: 0;
    right: 0;
}

.btn {
    font-size: 14px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
}

.btn.btn--blue {
    background: #34beef;
    border: 1px solid #34beef;
    color: #fff;
}

.btn.btn--white {
    background: none;
    border: 1px solid #fff;
    color: #fff;
}

.btn.btn--yellow {
    background: #ffd000;
    border: 1px solid #ffd000;
    color: #fff;
}

.btn.btn--blueWhite {
    background: #fff;
    border: 1px solid #34beef;
    color: #34beef;
}

.btn.btn--gray {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    color: #aaa;
}

.btn.btn--redWhite {
    background: #fff;
    border: 1px solid #ff4451;
    color: #ff4451;
}

.btn.btn--green {
    background: #2cba4b;
    border: 1px solid #2cba4b;
    color: #fff;
}

.btn.btn--whiteGray {
    background: rgba(34, 34, 34, 0.2);
    border: 1px solid #fff;
    color: #fff;
}

.btn.btn-outline-white {
    border: 1px solid #ddd;
    background: #fff;
    color: #666;
}

.btn.btn--blue-light {
    background: #e7fcfd;
    border: 1px solid #e7fcfd;
    color: #34beef;
}

.btn.btn--red {
    background: #ff4451;
    border: 1px solid #ff4451;
    color: #fff;
}

.btn.btn--blueBold {
    background: #278ff7;
    border: 1px solid #278ff7;
    color: #fff;
}

.btn.btn--hg32 {
    height: 32px;
}

/*** photoStore ***/
.photoStore .photoStore-thumb {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-bottom: 4px;
    padding-top: calc(100% - 50%);
    background: #f2f2f2;
}

.photoStore .photoStore-thumb a {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photoStore .photoStore-thumb a img {
    max-height: 172px;
}

.photoStore .photoStore-thumb .btn-save-bookmart {
    position: absolute;
    right: 14px;
    bottom: 14px;
    background-color: transparent;
    border: none;
    /* z-index: 10; */
}

.photoStore .photoStore-thumb .photoStore-lable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
}

.photoStore .photoStore-thumb .photoStore-lable span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}

.photoStore .photoStore-thumb .review-view {
    position: absolute;
    bottom: 12px;
    left: 8px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 1px 5px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.photoStore .photoStore-thumb .review-view .svg {
    margin-right: 4px;
    width: 13px;
}

.photoStore .photoStore-thumb .review-view .svg path {
    fill: #fff;
}

.star-list {
    display: flex;
    padding-right: 5px;
}

.star-list li {
    width: inherit !important;
    display: flex;
    align-items: center;
}

.star-list li + li {
    padding-left: 2px;
}

.star-list li .svg {
    cursor: pointer;
}

.star-list li .svg path {
    fill: #dddddd;
}

.star-list li .svg.checked path {
    fill: #34beef;
}

.slick-active.slick-current {
    z-index: 100;
}

/* Chi tiết bài viết */
/* .old-format ul {
    list-style: inside;
    white-space: initial;
} */

.main--photoStoreDetail .old-format p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.main--photoStoreDetail ol,
ul,
dl {
    padding-left: 0;
    list-style: none;
    margin: 0;
}
.article-content ul{
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}
.article-content ol{
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}
.article-content li{
    margin-top: 0;
    margin-bottom: 1rem;
}
.article-content img{
    margin-bottom: 1rem;
}
.header .headerControl {
    display: flex;
    width: 45%;
    align-items: center;
}

@media (max-width: 991px) {
    .header .headerControl {
        width: inherit;
    }
}

@media (max-width: 767px) {
    .header .headerControl {
        width: calc(100% - 110px);
    }
}

.header .headerControl .search {
    position: relative;
    width: calc(100% - 212px);
}

@media (max-width: 767px) {
    /* .header .headerControl .search {
        width: 100%;
    } */
}

.header .headerControl .search.show_search {
    display: block !important;
}

.header .headerControl .search.show_search .dropdown-menu {
    display: block;
}

.header .headerControl .search.show {
    display: block;
}

.header .headerControl .search.show .form-control {
    border: 1px solid #34beef;
}

.header .headerControl .search .search_main {
    position: relative;
    z-index: 998;
}

.header .headerControl .search .form-control {
    background: #f8f8f8;
    border: none;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    height: 40px;
    padding-left: 44px;
}

@media (max-width: 991px) {
    .header .headerControl .search {
        display: block;
    }
}

@media (max-width: 767px) {
    .header .headerControl .search {
        display: block;
    }
}

@media (max-width: 991px) {
    .header .headerControl .search_main {
        display: none;
    }
}

.headerLog {
    padding-left: 20px;
    width: 212px;
    position: relative;
}

@media (max-width: 991px) {
    .headerLog {
        padding-left: 10px;
        width: 212px;
    }
}

.headerLog > ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.headerLog > ul > li {
    min-width: 22px;
}

.headerLog > ul > li.headerLog-info {
    padding-left: 12px;
    position: relative;
}

@media (max-width: 767px) {
    /* .headerLog > ul > li.headerLog-info {
        display: none;
    } */
}

.headerLog > ul > li.headerLog-info::after {
    position: absolute;
    top: 6px;
    right: -6px;
    content: "";
    display: block;
    background: #eaeaea;
    height: 12px;
    width: 1px;
}

.headerLog > ul > li.headerLog-info:last-child::after {
    right: 0;
    background: none;
}

.headerLog > ul > li.headerLog-info a {
    color: #444;
}

.headerLog > ul > li.headerLog-notification,
.headerLog > ul > li.headerLog-bookmark,
.headerLog > ul > li.headerLog-user {
    margin-left: 20px;
}

@media (max-width: 767px) {
    .headerLog > ul > li.headerLog-notification,
    .headerLog > ul > li.headerLog-bookmark,
    .headerLog > ul > li.headerLog-user {
        margin-left: 10px;
    }
}

.headerLog > ul > li.headerLog-notification {
    position: relative;
}

.header
    .headerControl
    .headerLog
    > ul
    > li.headerLog-notification
    .notification-show {
    display: none;
}

.header
    .headerControl
    .headerLog
    > ul
    > li.headerLog-notification.show
    .notification-fade {
    display: none;
}

.header
    .headerControl
    .headerLog
    > ul
    > li.headerLog-notification.show
    .notification-show {
    display: inherit;
}

.headerLog > ul > li.headerLog-user {
    position: relative;
    min-width: 50px;
}

.headerLog > ul > li.headerLog-user a {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}

.headerLog > ul > li.headerLog-user a span {
    margin-left: 8px;
}

.headerLog > ul > li.headerLog-user .thumb-user {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
}

/*****/
.dropdown-menu_search {
    width: 100%;
    box-shadow: none;
    border: none;
}

@media (max-width: 991px) {
    .dropdown-menu_search {
        position: fixed !important;
        width: 100vw;
        /*height: 100%;*/
        transform: none !important;
        z-index: 100000;
        padding-top: 0;
        margin-top: 0;
        top: 0;
        left: 0;
    }
}

.dropdown-menu_search::before {
    position: absolute;
    width: calc(100% + 30px);
    left: -15px;
    top: -60px;
    background-color: #fff;
    height: calc(100% + 60px);
    content: "";
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all ease 0.3s;
}

.search__scroll-max {
    position: relative;
    z-index: 2;
    max-height: 500px;
    width: calc(100% + 30px);
    margin-left: -15px;
    overflow-y: auto;
}

@media (max-width: 991px) {
    .search__scroll-max {
        width: 100%;
        margin-left: 0;
        height: calc(100vh - 60px);
        max-height: calc(100vh - 60px);
        padding-top: 86px;
    }
}

.search__scroll-max > div {
    border-bottom: 1px solid #f3f3f3;
}

@media (max-width: 991px) {
    .search__scroll-max > div {
        border-width: 5px;
    }
}

.search__scroll-max > div:last-child {
    border-bottom: none;
}

.search__scroll-max .head-search {
    display: none;
    background: url("/images/search-head-bg.png") no-repeat 0 -20px #34beef;
    padding: 0px 15px 15px;
    border-bottom: none;
}

@media (max-width: 991px) {
    .search__scroll-max .head-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 86px;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 5;
    }
}

.search__scroll-max .head-search .head-search-wrap {
    width: calc(100% - 50px);
    background-color: #fff;
    border-radius: 8px;
    padding: 3px 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search__scroll-max .head-search .head-search-wrap .head-search-btn {
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
}

.search__scroll-max .head-search .head-search-wrap .head-search-text {
    border: none;
    width: calc(100% - 30px);
    padding-left: 10px;
    background-color: transparent;
    font-size: 14px;
}

.search__scroll-max .head-search .head-search-wrap .head-search-text:focus {
    outline: none;
}

.search__scroll-max .head-search .filter-close {
    color: #fff;
    cursor: pointer;
}

.search__scroll-max .search-suggest {
    position: relative;
    padding: 0 15px;
}

.search__scroll-max .search-suggest .suggest-list {
    /* margin-bottom: 7px; */
}

.search__scroll-max .search-suggest .suggest-list li {
    cursor: pointer;
    padding: 15px 0 15px 30px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 14px;
    font-weight: 400;
    background: url("/images/icon-clock-2.svg") no-repeat 0 50%;
}

.search__scroll-max .search-suggest .suggest-list li:last-child {
    border-bottom: none;
}

.search__scroll-max .search-popula {
    padding: 24px 15px 16px;
}

.search__scroll-max .search-popula .search-popula-list {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    cursor: pointer;
}

.search__scroll-max .search-popula .search-popula-list li {
    margin-bottom: 8px;
    overflow: hidden;
    height: 56px;
    border: 1px solid #f3f3f3;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 48.5%;
}

.search__scroll-max .search-popula .search-popula-list li .thumb {
    width: 56px;
}

.search__scroll-max .search-popula .search-popula-list li .text {
    font-size: 13px;
    width: calc(100% - 56px);
    padding: 3px 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.search__scroll-max .search-ctg {
    padding: 24px 15px 10px;
}

.search__scroll-max .search-ctg .search-ctg-list {
    display: flex;
    flex-flow: wrap;
    margin-left: -1%;
    margin-right: -1%;
}

.search__scroll-max .search-ctg .search-ctg-list li {
    width: 33.33%;
    padding: 0 1%;
    margin-bottom: 6px;
}

.search__scroll-max .search-ctg .search-ctg-list li a {
    border-radius: 8px;
    display: block;
    overflow: hidden;
    position: relative;
}

.search__scroll-max .search-ctg .search-ctg-list li a::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
}

.search__scroll-max .search-ctg .search-ctg-list li a img {
    border-radius: 8px;
    width: 100%;
}

.search__scroll-max .search-ctg .search-ctg-list li a .text {
    position: absolute;
    z-index: 5;
    width: 100%;
    padding: 8px;
    bottom: 0;
    left: 0;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
}

.search__scroll-max .view-more {
    font-weight: 500;
    color: #34beef;
}

.search__scroll-max .search-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 15px;
}

.search__scroll-max .search-head .search-title {
    font-weight: 600;
    font-size: 16px;
}

.search__scroll-max .search-head .view-more {
    font-size: 13px;
    font-weight: 400;
}

/*** navigation ***/
.headerNav {
    border-bottom: 1px solid #f3f3f3;
}

.headerMain {
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .headerMain {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.headerMain .navigation {
    max-width: 100%;
    width: calc(100% - 40px);
    padding-right: 12px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .headerMain .navigation {
        width: 100%;
        padding-right: 0;
    }
}

.headerMain .navigation ul {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
}

@media (max-width: 991px) {
    .headerMain .navigation ul {
        padding: 0 16px;
    }
}

.headerMain .navigation ul li {
    display: inline-block;
    padding-right: 48px;
}

@media (max-width: 991px) {
    .headerMain .navigation ul li {
        padding-right: 28px;
    }
}

@media (max-width: 767px) {
    .headerMain .navigation ul li {
        padding-right: 20px;
    }
}

.headerMain .navigation ul li.cong-dong {
    display: inline-block;
    padding-right: 26px;
}

@media (max-width: 991px) {
    .headerMain .navigation ul li.cong-dong {
        padding-right: 22px;
    }
}

@media (max-width: 767px) {
    .headerMain .navigation ul li.cong-dong {
        padding-right: 20px;
    }
}

.headerMain .navigation ul li:last-child {
    padding-right: 0;
}

@media (max-width: 767px) {
    .headerMain .navigation ul li:last-child {
        padding-right: 16px;
    }
}

@media (max-width: 767px) {
    .headerMain .navigation ul li.navigation-Spnone {
        display: none;
    }
}

.headerMain .navigation ul li a {
    padding: 16px 0 12px;
    display: block;
    color: #444;
    position: relative;
}

@media (max-width: 767px) {
    .headerMain .navigation ul li a {
        padding: 0 0 7px;
    }
}

.headerMain .navigation ul li a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 0;
    height: 0;
    background: #34beef;
    transition: 300ms all;
}

.headerMain .navigation ul li a:hover {
    color: #34beef;
}

.headerMain .navigation ul li a:hover::after {
    height: 2px;
    width: 100%;
}

.headerMain .navigation ul li a.active {
    color: #34beef;
}

.headerMain .navigation ul li a.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background: #34beef;
    transition: 300ms all;
}

.btnDangBai {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    z-index: 1;
    text-align: right;
    /* border-radius: 50%; */
    /* text-indent: -9999px; */
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
}

.btnDangBai .img-dang-bai {
    width: 40px;
    height: 40px
}

@media (max-width: 767px) {
    .btnDangBai {
        position: fixed;
        top: unset;
        bottom: 65px;
        right: 24px;
        width: 44px;
        height: 44px;
        padding-top: 0;
        z-index: 999;
        background: unset;
    }

    .btnDangBai .img-dang-bai {
        width: 44px;
        height: 44px
    }
}

@media (max-width: 767px) {
    .btnDangBai .btn {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        text-indent: -9999px;
        background: url("../images/icon-edit.svg") no-repeat 50% 50% #34beef;
    }
}

.btnDangBai .item-edit {
    padding-right: 8px;
}

.btnDangBai .item-arrow {
    padding-left: 8px;
}

.btnDangBai .dropdown-menu {
    width: 385px;
    right: -5px;
    padding: 10px 24px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
    .btnDangBai .dropdown-menu {
        width: 100vw;
        max-height: 80vh;
        bottom: 57px;
        left: 0;
        top: initial !important;
        transform: none !important;
        position: fixed !important;
        overflow-y: auto;
    }
}

.btnDangBai .dropdown-menu .title {
    display: none;
}

@media (max-width: 767px) {
    .btnDangBai .dropdown-menu .title {
        display: block;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        padding-top: 10px;
    }
}

.btnDangBai .dropdown-menu .filter-close {
    display: none;
}

@media (max-width: 767px) {
    .btnDangBai .dropdown-menu .filter-close {
        display: inline-block;
        height: 40px;
        width: 40px;
        background-image: url("../images/icon-close.svg");
        background-size: 18px 18px;
    }
}

.btnDangBai .dropdown-menu .list-post {
    width: 100%;
}

.btnDangBai .dropdown-menu .list-post li {
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
}

.btnDangBai .dropdown-menu .list-post li:last-child {
    border-bottom: none;
}

.btnDangBai .dropdown-menu .list-post li a {
    padding: 16px 0;
    display: block;
    background: url("/images/icon-edit-pen.svg") no-repeat 100% 50%;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnDangBai .dropdown-menu .list-post li a .icon {
    width: 40px;
}

.btnDangBai .dropdown-menu .list-post li a .info {
    width: calc(100% - 40px);
    padding-left: 7px;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    font-size: 16px;
    color: #222;
    font-weight: 400;
}

.btnDangBai .dropdown-menu .list-post li a .info .poin {
    margin-left: 10px;
    color: #2cba4b;
    font-size: 13px;
    font-weight: 600;
    padding: 4px 8px;
    display: block;
    border-radius: 6px;
    background-color: #edfbea;
}

.btnDangBai .dropdown-menu .list-post li a .info .poin-xu {
    margin-left: 10px;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 4px 8px;
    display: block;
    border-radius: 6px;
    background-color: #2cba4b;
}

.happy-xu {
    background: url(../images/bg-Membership.png) no-repeat top right #4e87cc;
}

.happy-xu .thumb img {
    width: 72px;
    height: 72px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 5px solid #21a8ec;
}

.happy-xu .name {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
}

/*** headerSp ****/
.headerSp {
    background: #fff;
}

.headerSp .wrap {
    padding-top: 10px;
    padding-bottom: 10px;
}

.headerSp .headerNav .wrap {
    padding-top: 0;
    padding-bottom: 0;
}

.headerSp .headerWrap div {
    display: inline-block;
}

.headerSp .headerWrap .logo {
    max-width: 110px;
    margin-right: auto;
}

.headerSp .headerWrap .header-col62 {
    width: 62px;
}

.headerSp .headerWrap .header-col62 .btn-back {
    padding: 0;
}

.headerSp .headerWrap .header-col62 .btn-back:focus {
    box-shadow: none;
}

.headerSp .headerWrap .header-col62 > ul {
    display: flex;
    flex-wrap: wrap;
}

.headerSp .headerWrap .header-col62 > ul > li {
    width: 50%;
}

.headerSp .headerWrap .header-col62 .searchTb .btn {
    padding: 0;
}

.headerSp .headerWrap .header-col62 .searchTb .btn:focus {
    box-shadow: none;
}

.headerSp .headerWrap .header-col62 .cart a {
    position: relative;
}

.headerSp .headerWrap .header-col62 .cart a span {
    position: absolute;
    top: -5px;
    right: -3px;
    width: 12px;
    height: 12px;
    background: #ff4451;
    justify-content: center;
    overflow: hidden;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
}

.headerSp .headerWrap .header-col62 .cart a .svg path {
    fill: #666;
}

.headerSp .headerWrap .header-title {
    padding-left: 4px;
}

.headerSp .headerWrap .header-title .header-tlt {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 23px;
}

.headerSp .headerWrap .header-post {
    float: right;
    text-align: center;
}

.headerSp .headerWrap .header-post .btn {
    padding-top: 0;
    padding-bottom: 0;
    color: #34beef;
    vertical-align: baseline;
}

.headerSp .headerWrap .header-post .btn.btn--colorGray {
    color: #aaa;
}

.headerSp .headerWrap .header-post .btn:focus {
    box-shadow: none;
}

.headerSp .headerWrap .header-icon {
    margin-left: auto;
}

.headerSp .headerWrap .header-icon > ul {
    display: flex;
    flex-wrap: wrap;
}

.headerSp .headerWrap .header-icon > ul > li {
    min-width: 20px;
    margin-left: 16px;
}

.headerSp .headerWrap .header-icon > ul > li img {
    max-width: 24px;
}

.headerSp .headerWrap .header-icon > ul > li a {
    position: relative;
}

.headerSp .headerWrap .header-icon > ul > li a span {
    position: absolute;
    top: -4px;
    right: -4px;
    background: #ff4451;
    width: 12px;
    height: 12px;
    overflow: hidden;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerSp .headerWrap .btn-myShop {
    margin-left: -16px;
}

.headerSp .headerWrap .btn-myShop .btn {
    border-radius: 0 16px 16px 0;
    padding: 0 12px;
    height: 32px;
    width: 116px;
    font-size: 12px;
}

.headerSp .headerWrap .btn-myShop .btn span {
    padding-right: 4px;
}

/*** navUser ***/
.navUser {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 10px 0 0;
    padding: 0;
    border: none;
    border-radius: 12px;
    min-width: 208px;
}

.navUser ul li a {
    color: #222;
    padding: 16px 16px;
    display: block;
    font-size: 14px;
}

.navUser ul li a:hover {
    background: #e7fcfd;
    color: #34beef;
}

/*** header -- requestReturn ***/
.requestReturn {
    background: #fff;
    padding: 24px 0;
}

.requestReturn .requestReturn-top {
    display: flex;
    justify-content: space-between;
}

.requestReturn .logo {
    display: flex;
    align-items: center;
}

.requestReturn .logo a {
    border-right: solid 2px #ddd;
    padding-right: 14px;
}

.requestReturn .logo .title {
    color: #666;
    padding-left: 14px;
}

.requestReturn .requestReturn-btn {
    display: flex;
}

.requestReturn .requestReturn-btn .btn {
    width: 136px;
    height: 40px;
    margin-left: 12px;
}

.requestReturn .requestReturn-btn .btn span {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid #eaeaea;
}

.close-search {
    position: absolute;
    height: 30px;
    width: 35px;
    background-color: transparent;
    right: 12px;
    top: 50%;
    margin-top: -5px;
    cursor: pointer;
}

.btnEdit .dropdown-menu .d-block {
    display: block !important;
}

.list-tag {
    display: flex;
    flex-wrap: wrap;
}

.list-tag li {
    background: #f4f4f4;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 8px;
    padding: 4px 20px 4px 6px;
    color: #666;
    font-size: 14px;
    position: relative;
}

@media (max-width: 767px) {
    .list-tag li {
        font-size: 12px;
    }
}

.list-tag li:last-child {
    margin-right: 0;
}

.list-tag li .closeTag {
    width: 12px;
    height: 12px;
    background: url("/images/icon-close02.svg") no-repeat center;
    display: block;
    position: absolute;
    top: 8px;
    right: 4px;
    cursor: pointer;
}

/*    tao-bai-review-san-pham*/
.pro-acc {
    margin-top: 12px;
}

.pro-acc .lstPro .btn {
    padding: 0;
}

.pro-acc .lstPro .btn.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(34, 34, 34, 0.2);
}

.pro-acc .lstPro .btn.btn--repair {
    position: absolute;
    top: 0;
    right: 0;
    color: #666;
}

.pro-acc .lstPro .btn:focus {
    box-shadow: none;
}

.createPost-group {
    margin-top: 32px;
}

.createPost-group .createPost-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 24px;
}

.createPost-group .thumb img {
    width: 100%;
}

.createPost-group .createPost-thumb {
    position: relative;
}

.createPost-group .createPost-thumb .createPost-play {
    position: absolute;
    top: calc(50% - 23px);
    right: calc(50% - 23px);
    width: 46px;
    height: 46px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.createPost-thumb .createPost-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.createPost-thumb .createPost-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
}

.createPost-group .createPost-btn .btn {
    height: 32px;
    font-size: 13px;
    padding-top: 0;
    padding-bottom: 0;
}

.createPost-btn.tag-btn {
    /* padding: 0;
    margin-left: 16px; */
}

.createPost-group .createPost-btn .btn span {
    margin: 4px 4px 0 0;
    display: inline-flex;
}

.createPost-group .createPost-btn .btn span img {
    max-width: 12px;
}

.createPost-group .addDescription {
    border: 1px solid #eee;
    padding: 16px 10px;
    margin-top: 16px;
    text-align: center;
}

.createPost-group .addDescription .addDescription-p {
    font-size: 13px;
    color: #444;
    font-style: italic;
}

.createPost-group .createPost-select {
    margin-top: 16px;
}

.createPost-group .createPost-select .filterItem .btn--dropdown {
    border: 1px solid #eee;
    background: #fff;
    color: #666;
}

.createPost-group .createPost-select .filterItem .btn--dropdown::after {
    background: url("../images/icon-drrow-down-gray.svg") no-repeat;
}

.createPost-search {
    position: relative;
    width: calc(50% - 10px);
    margin-top: 12px;
}

@media (max-width: 767px) {
    .createPost-search {
        width: 100%;
    }
}

.createPost-search-area > div {
    display: inline-block;
}

.createPost-search .form-control {
    height: 40px;
    padding-left: 44px;
}

.createPost-search .btn.btn--search {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 40px;
}

.createPost-search .btn.btn--search:focus {
    box-shadow: none;
}

.createPost-search .dropdown-menu {
    width: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 16px;
    padding: 0;
}

.createPost-search .list-experts > ul {
    overflow-y: auto;
    max-height: 300px;
}

.createPost-search .list-experts > ul > li {
    padding: 8px 16px;
}

.createPost-search .photoStore-head {
    padding-right: 66px;
    margin-bottom: 0;
    position: relative;
}

.createPost-search .photoStore-head .photoStore-head__item {
    width: 40px;
    height: 40px;
}

.createPost-search .photoStore-head .photoStore-head__text {
    width: calc(100% - 40px);
}

.createPost-search .photoStore-head .photoStore-head__text .tlt {
    color: #222;
    font-size: 14px;
}

.createPost-search .photoStore-head .btn {
    width: 60px;
    position: absolute;
    top: 0;
    right: 0;
}

.createPost-search .notFound {
    padding: 30px 16px;
    text-align: center;
}

.createPost-search .notFound .notFound-txt {
    color: #666;
    font-size: 14px;
}

.createPost-search .notFound .notFound-lable {
    font-weight: 500;
    font-size: 16px;
    margin-top: 8px;
}

.createPost-search .notFound .btn {
    margin-top: 24px;
    height: 40px;
    font-size: 14px;
}

.filterItem .btn--dropdown {
    background: #F8F8F8;
    padding: 8px 34px 8px 12px;
    color: #444;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filterItem .btn--dropdown::after {
    position: absolute;
    top: calc(50% - 4px);
    right: 12px;
    content: "";
    display: block;
    background: url(../images/icon-arrow-listNav.svg) no-repeat;
    width: 14px;
    height: 8px;
}

.filterItem .btn--dropdown.active::after {
    position: absolute;
    top: calc(50% - 10px);
    right: 6px;
    content: "";
    display: block;
    background: url(../images/icon-radio-tick.svg) no-repeat;
    background-size: 20px;
    width: 20px;
    height: 20px;
}

.filterItem .btn--dropdown:hover {
    background: #e7fcfd;
    color: #34beef;
}

.filterItem .btn--dropdown--white {
    background: #fff;
    color: #444;
}

.filterItem .btn--dropdown--white:hover {
    background: #fff;
    color: black;
}

.filterItem .btn--dropdown:hover::after {
    position: absolute;
    top: calc(50% - 4px);
    right: 12px;
    content: "";
    display: block;
    background: url(../images/icon-arrow-listNav-active.svg) no-repeat;
    width: 14px;
    height: 8px;
}

.filterItem .btn--dropdown:focus {
    box-shadow: none;
}

.createPost-upFile .custom-control {
    /* min-height: 440px; */
    height: auto;
    width: 100%;
}

@media (max-width: 767px) {
    .createPost-upFile .custom-control {
        /* min-height: 190px; */
        height: auto;
    }
}

.createPost-upFile .custom-control span {
    display: block;
    padding: 70px 0;
    width: 100%;
    background: #f8f8f8;
}

@media (max-width: 767px) {
    .createPost-upFile .custom-control span {
        padding: 38px;
    }
}

.createPost-upFile .custom-control .tlt {
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
}

.createPost-upFile .custom-control .note {
    color: #666;
    margin-top: 8px;
}

.createPost-upFile .button-upload {
    width: 100%;
    padding-top: 100%;
}

.createPost-upFile .button-upload img {
    top: 0;
    left: 0;
    position: absolute;
}

.createPost-sider {
    position: relative;
}

@media (max-width: 767px) {
    .createPost-sider {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-top: -20px;
    }
}

.createPost-sider .img-detail {
    width: 100%;
    position: relative;
    padding-top: 50%;
}

.createPost-sider .pagingInfo {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 10px;
    color: #fff;
    position: absolute;
    z-index: 5;
    bottom: 16px;
    left: 48%;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: 2px;
}

.requestReturn-btn-fix {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: none;
}

@media (max-width: 767px) {
    .requestReturn-btn-fix {
        display: flex;
    }
}

.detail-content {
    position: relative;
}

.detail-content .pagingInfo {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 10px;
    color: #fff;
    position: absolute;
    z-index: 5;
    bottom: 16px;
    left: 48%;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: 2px;
}

.requestReturn-btn-fix .btn {
    width: 100%;
    height: 40px;
}

.requestReturn-btn-fix .btn span {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid #eaeaea;
}

.requestReturn-btn-fix .btn.btn--blue {
    margin-left: 12px;
}

.reviewInfo .review-head {
    display: flex;
    flex-wrap: wrap;
}

.reviewInfo .review-acc {
    width: calc(100% - 96px);
    padding-right: 10px;
    display: flex;
    color: #444;
    font-weight: 500;
}

.reviewInfo .review-acc span {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    line-height: normal;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
}

.reviewInfo .review-acc span img {
    width: 100%;
}

.expert__wrap {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: flex-start;
    padding-bottom: 50px;
}

.expert__wrap .navSetting {
    border-top: none;
    width: calc(100% + 32px);
    margin-left: -16px;
}

.expert__wrap .expert__wrap-overview {
    width: 300px;
    border: 1px solid #ddd;
    padding: 32px 24px 24px;
    border-radius: 8px;
}

@media (min-width: 991px) {
    .expert__wrap .expert__wrap-overview {
        position: sticky;
        top: 138px;
    }
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin-bottom: 48px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview {
        border: none;
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 15px;
        border-bottom: 5px solid #f4f4f4;
        border-radius: 0;
        padding-bottom: 6px;
        padding-top: 12px;
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .showroom-over {
        width: 48.5%;
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .showroom-over {
        width: 100%;
    }
}

.expert__wrap .expert__wrap-overview .showroom-over .logo-showroow {
    text-align: center;
    margin-bottom: 12px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .showroom-over .logo-showroow {
        width: 64px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .showroom-over .logo-showroow {
        order: 2;
    }
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .showroom-over .showroom-info {
        width: calc(100% - 64px);
        padding-left: 16px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .showroom-over .showroom-info {
        order: 1;
        padding-left: 0;
        padding-right: 16px;
    }
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-name {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    margin-bottom: 4px;
    justify-content: center;
}

@media (max-width: 991px) {
    .expert__wrap
        .expert__wrap-overview
        .showroom-over
        .showroom-info
        .showroom-name {
        justify-content: flex-start;
    }
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-name
    .user-name {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    color: #222;
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-name
    .user-stt {
    display: inline-block;
    color: #f0fbff;
    background-color: #34beef;
    padding: 4px 3px;
    line-height: 100%;
    font-size: 12px;
    border-radius: 7px;
    margin: 0 4px 0 3px;
    border: 2px solid #e7fcfd;
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-job {
    font-size: 12px;
    color: #aaa;
    text-align: center;
    margin-bottom: 5px;
}

@media (max-width: 991px) {
    .expert__wrap
        .expert__wrap-overview
        .showroom-over
        .showroom-info
        .showroom-job {
        text-align: left;
    }
}

.expert__wrap
    .expert__wrap-overview
    .showroom-over
    .showroom-info
    .showroom-location {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #666;
    margin-bottom: 16px;
}

@media (max-width: 991px) {
    .expert__wrap
        .expert__wrap-overview
        .showroom-over
        .showroom-info
        .showroom-location {
        margin-bottom: 0;
        text-align: left;
    }
}

.expert__wrap .expert__wrap-overview .follow-over {
    border-top: 1px solid #f3f3f3;
    padding-top: 16px;
    margin-bottom: 24px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .follow-over {
        width: 48.5%;
        border-top: none;
        margin-bottom: 0;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-overview .follow-over {
        width: 100%;
    }
}

.expert__wrap .expert__wrap-overview .follow-over .follow-list {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .follow-over .follow-list {
        justify-content: flex-start;
    }
}

.expert__wrap .expert__wrap-overview .follow-over .follow-list li {
    font-size: 13px;
    line-height: 100%;
    padding-right: 7px;
}

.expert__wrap .expert__wrap-overview .follow-over .follow-list li + li {
    padding-left: 7px;
    border-left: 1px solid #aaa;
}

.expert__wrap .expert__wrap-overview .follow-over .showroom-level {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .follow-over .showroom-level {
        justify-content: flex-start;
        margin-bottom: 12px;
    }
}

.expert__wrap .expert__wrap-overview .follow-over .showroom-level .level-items {
    border-radius: 8px;
    margin-right: 5px;
}

.showroom-level .rate-star {
    padding-right: 0;
}

.expert__wrap .expert__wrap-overview .list-overview {
    /* display: flex; */
    /* flex-flow: wrap; */
    /* margin-left: -11px; */
    margin-bottom: 24px;
    text-align: center;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .list-overview {
        margin-bottom: 0;
    }
}

.expert__wrap .expert__wrap-overview .list-overview li {
    margin-left: 11px;
    display: inline-block;
}

.expert__wrap .expert__wrap-overview .list-overview li:first-child {
    margin-left: 0px;
}

.expert__wrap .expert__wrap-overview .list-overview li.items-number {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #34beef;
    background-color: #e7fcfd;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #34beef;
    font-weight: 600;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .button__wrap {
        width: 100%;
        border-top: 1px solid #f3f3f3;
        padding-top: 12px;
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
    }
}

.expert__wrap .expert__wrap-overview .button__wrap .btn-msg {
    margin-bottom: 12px;
    width: 100%;
    height: 40px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .button__wrap .btn-msg {
        width: calc(33.33% - 12px);
    }
}

.expert__wrap .expert__wrap-overview .button__wrap .contact-button-group {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-overview .button__wrap .contact-button-group {
        width: 66.66%;
    }
}

.expert__wrap .expert__wrap-overview .button__wrap .contact-button-group li {
    width: calc(50% - 6px);
}

.expert__wrap
    .expert__wrap-overview
    .button__wrap
    .contact-button-group
    li
    .btn {
    width: 100%;
    height: 40px;
}

.expert__wrap .expert__wrap-content {
    width: calc(100% - 300px);
    padding-left: 40px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-content {
        width: 100%;
        padding-left: 0;
    }
}

.expert__wrap .expert__wrap-content .comment__best-list li {
    display: inline-block !important;
    width: 50%;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content .comment__best-list li {
        width: 75%;
    }
}

.expert__wrap .expert__wrap-content .lstPhoto > ul > li {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 0;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content .lstPhoto > ul > li {
        width: 100%;
        margin-bottom: 20px;
    }
}

.expert__wrap .expert__wrap-content.expert-review .list-wrap > ul > li {
    width: 50%;
    margin-top: 0;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-review .list-wrap > ul > li {
        width: 100%;
    }
}

.expert__wrap .expert__wrap-content.expert-voucher .voucher-none {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding-top: 150px;
}

@media (max-width: 991px) {
    .expert__wrap .expert__wrap-content.expert-voucher .voucher-none {
        padding-top: 70px;
    }
}

.expert__wrap .expert__wrap-content.expert-voucher .voucher-none .thumb {
    margin-bottom: 10px;
}

.expert__wrap .expert__wrap-content.expert-voucher .voucher-none .text {
    font-size: 14px;
    columns: #666;
    text-align: center;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-voucher .discount {
        padding-top: 20px;
    }
}

.expert__wrap .expert__wrap-content.expert-voucher .discount > ul > li {
    width: 50%;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-voucher .discount > ul > li {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-review .lstDiscussion {
        padding-top: 20px;
    }
}

.expert__wrap .expert__wrap-content.expert-review .lstDiscussion > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.expert__wrap .expert__wrap-content.expert-review .lstDiscussion > ul > li {
    padding-left: 20px;
    margin-bottom: 20px;
    width: 50%;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-review .lstDiscussion > ul > li {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-save {
        padding-top: 20px;
    }
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-introduce {
        padding-top: 0px;
    }
}

.expert__wrap .expert__wrap-content.expert-introduce .table-introduce {
    margin-bottom: 24px;
    border-bottom: 5px solid #f3f3f3;
    width: 100%;
    padding-bottom: 15px;
}

.expert__wrap
    .expert__wrap-content.expert-introduce
    .table-introduce
    tr:last-child
    th,
.expert__wrap
    .expert__wrap-content.expert-introduce
    .table-introduce
    tr:last-child
    td {
    border-bottom: none;
    padding-bottom: 25px;
}

.expert__wrap .expert__wrap-content.expert-introduce .table-introduce th,
.expert__wrap .expert__wrap-content.expert-introduce .table-introduce td {
    padding: 16px 0;
    border-bottom: 1px solid #f3f3f3;
}

.expert__wrap .expert__wrap-content.expert-introduce .table-introduce th {
    width: 180px;
    font-weight: 400;
    color: #aaa;
}

@media (max-width: 767px) {
    .expert__wrap .expert__wrap-content.expert-introduce .table-introduce th {
        width: 140px;
    }
}

.expert__wrap .expert__wrap-content.expert-introduce .table-introduce td {
    font-size: 14px;
    font-weight: 500;
}

.expert__wrap
    .expert__wrap-content.expert-introduce
    .table-introduce
    .list-armorial {
    display: flex;
    flex-flow: wrap;
    margin-left: -16px;
    max-width: 300px;
    width: 100%;
}

@media (max-width: 767px) {
    .expert__wrap
        .expert__wrap-content.expert-introduce
        .table-introduce
        .list-armorial {
        margin-left: -8px;
    }
}

.expert__wrap
    .expert__wrap-content.expert-introduce
    .table-introduce
    .list-armorial
    li {
    padding-left: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
}

@media (max-width: 767px) {
    .expert__wrap
        .expert__wrap-content.expert-introduce
        .table-introduce
        .list-armorial
        li {
        padding-left: 8px;
    }
}

.expert__wrap
    .expert__wrap-content.expert-introduce
    .table-introduce
    .list-social {
    display: flex;
    flex-flow: wrap;
}

.expert__wrap
    .expert__wrap-content.expert-introduce
    .table-introduce
    .list-social
    li {
    margin-right: 16px;
}

@media (max-width: 767px) {
    .expert__wrap
        .expert__wrap-content.expert-introduce
        .table-introduce
        .list-social
        li {
        margin-right: 8px;
    }
}

.expert__wrap .review_list li {
    width: 50%;
}

@media (max-width: 767px) {
    .expert__wrap .review_list li {
        width: 100%;
    }
}

.save-gallery {
    margin-left: -20px;
    display: flex;
    flex-flow: wrap;
}

@media (max-width: 767px) {
    .save-gallery {
        margin-left: -10px;
    }
}

.save-gallery.cols-2 li {
    width: 50%;
}

.save-gallery li {
    text-align: center;
    padding-left: 20px;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .save-gallery li {
        padding-left: 10px;
        margin-bottom: 20px;
    }
}

.save-gallery li .save-title {
    font-size: 14px;
    font-weight: 600;
}

.save-gallery li .save-title a {
    color: #222;
}

.save-gallery li .save-total {
    font-size: 12px;
    color: #666;
}

.save-gallery li .save-view {
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
}

.save-gallery li .save-view .inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}

.save-gallery li .save-view .inner .items {
    width: calc(50% - 1px);
    height: calc(50% - 1px);
    position: relative;
}

.save-gallery li .save-view .inner .items img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.save-gallery li .save-view .inner .items .view-more {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    left: 0;
    top: 0;
}

.save-gallery li .save-view .inner .items:nth-child(3),
.save-gallery li .save-view .inner .items:nth-child(4) {
    margin-top: 2px;
}

.modal-popup .modal-dialog {
    width: 100%;
    margin-top: -54px;
}

.modal-popup .image-popup {
    max-height: 80vh;
}

.modal-popup .modal-content {
    margin-bottom: 0px;
    background: transparent;
    border: none;
}

.modal-popup .modal-body {
    padding: 0;
}

.modal-popup .modal-header {
    padding: 0;
    position: relative;
}

.modal-popup .modal-header .close {
    padding: 0;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 2;
}

.modal-popup .close-position {
    display: block;
    position: absolute;
    right: 10px;
    top: 6px;
    opacity: 0.8;
}

.modal-popup .modal-body {
    width: fit-content;
    margin: 0 auto;
}

.modal_conditions-code .modal-body {
    background: url("../images/voucher-bg.png") no-repeat 50% -30px;
    background-size: 100% auto;
}

.modal_conditions-code .discount {
    margin-bottom: 0;
    padding-top: 15px;
}

.modal_conditions-code .mtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
}

.modal_conditions-code .text {
    font-size: 14px;
    color: #262626;
    margin-bottom: 20px;
}

.modal_conditions-code .btn-save {
    width: 100%;
    height: 48px;
}

.fixed-banner {
    position: sticky;
    bottom: 62px;
    width: 100vw;
    height: 50vw;
    padding: 0 !important;
    overflow: hidden;
    z-index: 1001;
}

.fixed-banner .close-position {
    display: block;
    position: absolute;
    right: 10px;
    top: 6px;
    opacity: 0.8;
}

.fixed-banner .close-position span img {
    background-color: #fff;
    border-radius: 50%;
}

.modal-popup .close-position span img {
    background-color: #fff;
    border-radius: 50%;
}

.fixed-banner a img {
    width: 100vw;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1;
}

.wrap-next-image {
    /* background: #f2f2f2; */
    position: relative;
}

.wrap-next-image img {
    width: auto !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 100% !important;
    object-fit: cover;
}

.wrap-next-image-not-crop {
    background-color: #f2f2f2;
    padding-top: 100%;
}

.wrap-next-image-not-crop img {
    width: auto !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 100% !important;
    object-fit: contain;
}

.modal-add-address-new .setting-default .switch .switch-round:before {
    background-image: none;
}

.slick-list > div {
    margin-left: 0;
}

.lstPro-col3.lstPro-col3--16 {
    margin-top: -16px;
}

@media (max-width: 767px) {
    .lstPro-col3.lstPro-col3--16 {
        margin-top: 0;
    }
}

.lstPro-col3 > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .lstPro-col3 > ul {
        margin-left: -12px;
    }
}

.lstPro-col3 > ul > li {
    width: 25%;
    padding-left: 20px;
    margin-top: 32px;
}

@media (max-width: 767px) {
    .lstPro-col3 > ul > li {
        width: 50%;
        padding-left: 12px;
        margin-top: 16px;
    }
}

body *::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    display: none;
}

/* body *:hover::-webkit-scrollbar {
    display: block;
} */

/* width */
/* ::-webkit-scrollbar {
    width: 8px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

/* Ho so: Change pass */
.form-change-password {
    width: 100%;
    max-width: 366px;
    margin: 0 auto;
}

.form-change-password .form-icon .icon {
    opacity: 0.5;
}

.form-change-password .form-icon .form-control:focus ~ .icon {
    opacity: 1;
}

.form-change-password .btn-change-pass {
    height: 48px;
    margin-top: 32px;
    width: 100%;
}

/* Ho so: address */
.address-form {
    width: 100%;
    max-width: 670px;
    margin: 0 auto;
}

.address-form .choose-address li {
    padding-left: 16px;
}

.address-form .choose-address li::after {
    display: none;
}

.choose-address li {
    margin-bottom: 12px;
    padding: 16px 60px 16px 48px;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.choose-address-modal li {
    margin: 12px 0;
    padding: 16px 16px 16px 48px;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    position: relative;
}

.cursor-pointer {
    cursor: pointer;
}

.color-main {
    color: #34beef;
}

.choose-address-modal li .cb-address {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    position: absolute;
    left: 18px;
    top: 18px;
    content: "";
    border-radius: 50%;
    overflow: hidden;
}

.choose-address-modal li.active .cb-address {
    background: url("../images/icon-radio-tick.svg") 50% 50% no-repeat;
    border: none;
}

@media (max-width: 767px) {
    .choose-address li {
        padding: 10px 50px 10px 40px;
    }
}

.choose-address li::after {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    position: absolute;
    left: 18px;
    top: 18px;
    content: "";
    border-radius: 50%;
    overflow: hidden;
}

@media (max-width: 767px) {
    .choose-address li::after {
        left: 12px;
        top: 12px;
    }
}

.choose-address li.active::after {
    background: url("../images/icon-radio-tick.svg") 50% 50% no-repeat;
    border: none;
}

.choose-address li .user-name {
    font-weight: 600;
    display: block;
}

@media (max-width: 767px) {
    .choose-address li .user-name {
        font-size: 12px;
    }
}

.choose-address li .user-number {
    font-weight: 600;
    color: #666;
}

@media (max-width: 767px) {
    .choose-address li .user-number {
        font-size: 12px;
    }
}

.choose-address li .user-address {
    color: #666;
    font-size: 13px;
}

@media (max-width: 767px) {
    .choose-address li .user-address {
        font-size: 12px;
    }
}

.choose-address li .setup-default {
    margin-top: 10px;
    color: #34beef;
}

.choose-address li .setup-default img {
    margin-right: 5px;
}

.choose-address li .setup-address {
    position: absolute;
    right: 20px;
    top: 15px;
}

@media (max-width: 767px) {
    .choose-address li .setup-address {
        right: 10px;
        top: 7px;
    }
}

.choose-address li .setup-address .btn-show {
    border: none;
    background-color: transparent;
    padding: 0;
}

.choose-address li .setup-address .btn-show .svg {
    width: 17px;
}

.choose-address li .setup-address .btn-show .svg path {
    fill: #231f20;
}

.choose-address li .setup-address .dropdown-menu {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: none;
}

.choose-address li .setup-address .dropdown-menu a {
    font-size: 14px;
    border-bottom: 1px solid #eee;
}

.choose-address li .setup-address .dropdown-menu a:last-child {
    border-bottom: none;
}

.manager-notif {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
}

.manager-notif .group-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #222;
}

.manager-notif .text {
    color: #666;
    font-size: 14px;
    margin-bottom: 24px;
}

.manager-notif .select-receive {
    font-size: 12px;
    cursor: pointer;
}

.manager-notif .select-receive button {
    color: #34beef;
}

.manager-notif .select-receive .arrow {
    vertical-align: middle;
    display: inline-block;
    padding-top: 8px;
    width: 10px;
    transform: rotate(-90deg);
}

.manager-notif .group-notif {
    border-bottom: 8px solid #f4f4f4;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.manager-notif .group-notif:last-child {
    border-bottom: none;
}

.manager-notif .group-notif .items-notif {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 24px;
    margin-bottom: 24px;
}

.manager-notif .group-notif .items-notif:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.manager-notif .group-notif .items-notif .notif-title {
    font-weight: 500;
    width: calc(100% - 60px);
    font-size: 16px;
}

.manager-notif .receive-checked {
    display: flex;
    flex-flow: wrap;
}

.manager-notif .receive-checked li {
    white-space: normal;
    color: #666666;
    font-size: 13px;
    padding: 2px 5px;
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-right: 8px;
}

.manager-notif .switch .switch-round:before {
    background: #fff;
}

@media (max-width: 767px) {
    .manager-notif .group-notif {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }

    .manager-notif .group-title {
        font-size: 16px;
    }

    .manager-notif .group-notif .items-notif .notif-title {
        font-size: 14px;
    }

    .manager-notif .text {
        font-size: 12px;
    }

    .manager-notif .receive-checked li {
        font-size: 12px;
    }
}

html {
    scroll-behavior: smooth;
}

.btn-add-address {
    width: 100%;
    text-align: left;
    color: #34beef;
    padding: 16px 16px 16px 18px;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    cursor: pointer;
}

.main--shop .serviceList .active a {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
}

.choosePhoto ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    overflow-y: scroll;
    max-height: 300px;
}

.choosePhoto ul li {
    margin-top: 2px;
    padding-left: 2px;
    width: 25%;
}

@media (max-width: 767px) {
    .choosePhoto ul li {
        width: 33%;
    }
}

.choosePhoto .custom-control {
    height: 100%;
}

.choosePhoto .custom-control .btn {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
}

.choosePhoto .choosePhoto-thumb {
    position: relative;
    cursor: pointer;
}

.choosePhoto .choosePhoto-thumb img {
    width: 100%;
}

.choosePhoto .choosePhoto-thumb .choosePhoto-select {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 20px;
    height: 20px;
    background: rgba(34, 34, 34, 0.2);
    border: 1.5px solid #fff;
    border-radius: 50%;
}

.choosePhoto .choosePhoto-thumb--check .choosePhoto-select {
    background: url(../images/icon-radio-tick.svg) no-repeat 50%;
    background-size: 24px 24px;
    border: none;
}

.btn-footer {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-top: 24px;
}

.btn-footer > li {
    width: 50%;
    padding-left: 12px;
}

.btn-footer > li .btn {
    width: 100%;
    height: 40px;
}

.btn-footer > li .custom-control .btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.photoPosts-flex {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.photoPosts-flex .photoPosts-thumb {
    width: 60%;
}

@media (max-width: 767px) {
    .photoPosts-flex .photoPosts-thumb {
        width: 100%;
    }
}

.photoPosts-flex .photoPosts-content {
    width: 40%;
    padding-left: 3%;
}

@media (max-width: 767px) {
    .photoPosts-flex .photoPosts-content {
        width: 100%;
        padding-left: 0;
        padding-top: 20px;
    }
}

.photoPosts-flex .photoPosts-content .title {
    font-weight: 600;
    font-size: 16px;
}

.photoPosts-flex .sliderPhoto-group .sliderPhoto-select {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

@media (max-width: 767px) {
    .photoPosts-flex .sliderPhoto-group .sliderPhoto-select {
        position: static;
        padding-top: 6px;
    }
}

.photoPosts-flex
    .sliderPhoto-group
    .sliderPhoto-select
    .filterItem
    .btn--dropdown {
    background: #34beef;
    color: #fff;
    font-size: 13px;
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
}

.photoPosts-flex
    .sliderPhoto-group
    .sliderPhoto-select
    .filterItem
    .btn--dropdown::after {
    background: url(../images/icon-drrow-down-white.svg) no-repeat;
}

.photoPosts-flex .createPost-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.photoPosts-flex .photoGallery-small .createPost-close {
    width: 20px;
    height: 20px;
}

.photoPosts-flex .photo-thumb {
    position: relative;
}

.photoPosts-flex .photo-thumb .photo-img img {
    width: 100%;
}

.photoPosts-flex .photoPostsShow {
    position: relative;
}

.photoPosts-flex .photoPostsShow .slick-arrow {
    top: calc(50% - 16px);
}

.photoPosts-flex .photoPostsShow .sliderPhoto-select {
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.photoPosts-flex
    .photoPostsShow
    .sliderPhoto-select
    .filterItem
    .btn--dropdown {
    background: rgba(34, 34, 34, 0.2);
    border: 1px solid #fff;
    color: #fff;
    font-size: 13px;
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
}

.photoPosts-flex
    .photoPostsShow
    .sliderPhoto-select
    .filterItem
    .btn--dropdown::after {
    background: url(../images/icon-drrow-down-white.svg) no-repeat;
}

.photoPosts-flex .photoPosts-note {
    margin-top: 24px;
    background: #f8f8f8;
    border-radius: 12px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
}

.photoPosts-flex .photoPosts-note .photoPosts-note-item {
    min-width: 24px;
}

.photoPosts-flex .photoPosts-note .photoPosts-note-item .svg path {
    fill: #444;
}

.photoPosts-flex .photoPosts-note .photoPosts-note-text {
    padding: 0 0 0 25px;
}

.photoPosts-flex .photoPosts-note .photoPosts-note-text li {
    color: #444;
    font-size: 12px;
    font-weight: 500;
    list-style: disc;
}

.photoPosts-flex .search-pro {
    margin-top: 24px;
    position: relative;
}

.photoPosts-flex .search-pro .form-control {
    height: 40px;
    padding-left: 45px;
    border-radius: 8px;
}

.photoPosts-flex .search-pro .btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    padding: 0;
}

.photoPosts-flex .search-pro .btn:focus {
    box-shadow: none;
}

.photoPosts-flex .search-pro .search-text {
    text-align: center;
    color: #34beef;
    font-weight: 600;
    margin-top: 16px;
}

.photoPosts-flex .noData {
    text-align: center;
    margin-top: 36px;
}

.photoPosts-flex .noData p {
    margin-top: 8px;
    color: #666;
}

.photoPosts-flex .listSearch ul li {
    display: flex;
    flex-wrap: wrap;
    padding: 13px 0;
}

.photoPosts-flex .listSearch ul li .listSearch-thumb {
    width: 58px;
    height: 58px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
}

.photoPosts-flex .listSearch ul li .listSearch-thumb .listSearch-txt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.photoPosts-flex .listSearch ul li .listSearch-thumb .listSearch-txt span {
    background: rgba(34, 34, 34, 0.3);
    border-radius: 65px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    padding: 0 4px;
}

.photoPosts-flex .listSearch ul li .listSearch-content {
    width: calc(100% - 58px);
    padding-left: 12px;
    position: relative;
    padding-right: 80px;
}

.photoPosts-flex .listSearch ul li .listSearch-content .listSearch-title {
    font-weight: 500;
}

.photoPosts-flex .listSearch ul li .listSearch-content .listSearch-title a {
    color: #222;
}

.photoPosts-flex .listSearch ul li .listSearch-content .listSearch-view {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.photoPosts-flex .listSearch ul li .listSearch-content .listSearch-view .view {
    color: #34beef;
    font-size: 13px;
    font-weight: 600;
    padding-right: 10px;
}

.photoPosts-flex
    .listSearch
    ul
    li
    .listSearch-content
    .listSearch-view
    .view
    img {
    display: inline-block;
    margin: -3px 0 0 3px;
}

.photoPosts-flex .listSearch ul li .listSearch-content .listSearch-view .label {
    font-weight: 500;
    font-size: 13px;
}

.photoPosts-flex
    .listSearch
    ul
    li
    .listSearch-content
    .listSearch-view
    .label
    img {
    width: 16px;
    display: inline-block;
    margin: -2px 3px 0 0;
}

.photoPosts-flex .listSearch ul li .listSearch-content .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 32px;
    font-size: 13px;
}

/**** modal-photo-posts ****/
.modal-photo-posts {
    padding: 0 !important;
}

.modal-photo-posts .modal-dialog {
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.modal-photo-posts .modal-dialog .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border: none;
}

.modal-photo-posts .closeBtn {
    padding-top: 15px;
    margin-bottom: -16px;
    min-height: 32px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .modal-photo-posts .closeBtn {
        padding-top: 20px;
    }
}

@media (max-width: 767px) {
    .modal-photo-posts .modal-body {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.modal-photo-posts .closeBtn .close {
    width: 32px;
    height: 32px;
    background: #d3d3d3;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.modal-photo-posts .closeBtn .close img {
    max-width: 21px;
}

/**** main--accountViewProFile ****/
.main--accountViewProFile {
    padding: 0 0 66px;
}

.main--accountViewProFile .wrap-row {
    padding-top: 24px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .wrap-row {
        padding-top: 0;
    }
}

.main--accountViewProFile .groupProFile .groupProFile-info {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .groupProFile .groupProFile-info {
        width: calc(100% + 32px);
        padding: 0 16px 16px;
        margin-left: -16px;
        margin-bottom: 16px;
        border-bottom: solid 6px #f4f4f4;
    }
}

@media (max-width: 991px) {
    .main--accountViewProFile
        .groupProFile
        .groupProFile-info
        .groupCategory-title {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .main--accountViewProFile
        .groupProFile
        .groupProFile-info
        .groupCategory-title {
        padding-top: 16px;
    }
}

.main--accountViewProFile .groupProFile .groupProFile-info:last-child {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .main--accountViewProFile .groupProFile .groupProFile-info:last-child {
        padding-bottom: 0;
        border: none;
    }
}

@media (max-width: 767px) {
    .main--accountViewProFile .groupCategory-title .groupCategory-tlt {
        font-size: 14px;
    }
}

.main--accountViewProFile
    .groupCategory-title
    .groupCategory-tlt
    .label-number {
    color: #34beef;
    padding-left: 6px;
}

.main--accountViewProFile .lstView {
    margin-top: -24px;
}

.main--accountViewProFile .lstView.lstView--changeFs .photoStore-item {
    padding-top: 4px;
}

.main--accountViewProFile .lstView.lstView--changeFs .photoStore-item li {
    font-size: 12px;
}

.main--accountViewProFile .lstView.lstView--changeFs .listPhoto-title {
    margin-top: 8px;
}

.main--accountViewProFile .lstView.lstView--changeFs .listPhoto-title a {
    font-size: 14px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView.lstView--Sp > ul > li {
        width: 100%;
    }
}

.main--accountViewProFile .lstView.lstView--col2 > ul > li {
    width: 50%;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView.lstView--col2 > ul > li {
        width: 100%;
    }
}

.main--accountViewProFile
    .lstView.lstView--col2
    .reviewInfo
    .review-thumb
    a
    img {
    max-height: 272px;
}

.main--accountViewProFile .lstView > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView > ul {
        margin-left: -12px;
    }
}

.main--accountViewProFile .lstView > ul > li {
    padding-left: 20px;
    margin-top: 24px;
    width: 33.333333%;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstView > ul > li {
        width: 50%;
        padding-left: 12px;
    }
}

.main--accountViewProFile .lstView .listPhoto-thumb .read-views {
    bottom: 8px;
    left: 8px;
}

.main--accountViewProFile .lstView .listPhoto-label {
    font-size: 12px;
    margin-top: 4px;
}

.main--accountViewProFile .lstView .more {
    margin-top: 24px;
}

.main--accountViewProFile .lstView .more .btn {
    width: 100%;
}

.main--accountViewProFile .viewPhoto {
    margin-bottom: -4px;
}

.main--accountViewProFile .viewPhoto .viewPhoto-thumb {
    overflow: hidden;
    border-radius: 8px;
}

.main--accountViewProFile .viewPhoto ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .viewPhoto ul {
        margin-left: -2px;
    }
}

.main--accountViewProFile .viewPhoto ul li {
    width: 20%;
    padding-left: 4px;
    margin-top: 4px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .viewPhoto ul li {
        width: 33.333333%;
        margin-top: 2px;
        padding-left: 2px;
    }
}

.main--accountViewProFile .viewPhoto ul li img {
    width: 100%;
}

.main--accountViewProFile .viewPhoto .more {
    margin-top: 16px;
}

.main--accountViewProFile .viewPhoto .more .btn {
    width: 100%;
}

.main--accountViewProFile .lstDiscussion-col2 {
    margin-top: -24px;
}

@media (max-width: 991px) {
    .main--accountViewProFile .lstDiscussion-col2 {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstDiscussion-col2 {
        padding-top: 16px;
    }
}

.main--accountViewProFile .lstDiscussion-col2 > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--accountViewProFile .lstDiscussion-col2 > ul > li {
    width: 50%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstDiscussion-col2 > ul > li {
        width: 100%;
    }
}

.main--accountViewProFile
    .lstDiscussion-col2
    .discussion
    .discussion-thumb
    a
    img {
    max-height: 270px;
}

.main--accountViewProFile .filter--changePd16 {
    padding-top: 8px;
    padding-bottom: 24px;
}

.breadcrumbList {
    padding: 16px 0;
}

@media (max-width: 767px) {
    .breadcrumbList {
        padding: 8px 0;
    }
}

.breadcrumbList ul {
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbList ul li {
    padding-right: 24px;
    color: #666;
    position: relative;
}

@media (max-width: 767px) {
    .breadcrumbList ul li {
        padding-right: 16px;
    }
}

.breadcrumbList ul li::after {
    position: absolute;
    top: 5px;
    right: 9px;
    content: "";
    display: block;
    width: 8px;
    height: 12px;
    background: url(../images/icon-arrow-breadcrumb.svg) no-repeat;
}

@media (max-width: 767px) {
    .breadcrumbList ul li::after {
        background: #bbb;
        width: 1px;
        right: 6px;
    }
}

.breadcrumbList ul li:last-child::after {
    background: none;
    right: 0;
}

.breadcrumbList ul li a {
    color: #666;
    font-size: 13px;
}

.groupProFile-title {
    padding: 8px 0 16px;
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 16px;
}

.groupProFile-title .tlt {
    font-weight: 600;
    font-size: 20px;
}

.lstView-pro > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.lstView-pro > ul > li {
    padding-left: 20px;
    margin-top: 24px;
    width: 33.333333%;
}

@media (max-width: 767px) {
    .lstView-pro > ul > li {
        width: 100%;
    }
}

/******/
.modal-mw688 {
    max-width: 688px;
}

.chooseTheme .chooseTheme-tlt {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.chooseTheme .chooseTheme-note {
    color: #aaa;
    text-align: center;
    margin-top: 16px;
}

.chooseTheme .chooseTheme-note span {
    color: #7d7d7d;
    font-weight: 700;
}

.chooseTheme .lst-thumb {
    padding-top: 12px;
}

.chooseTheme .chooseTheme-thumb {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    overflow-y: auto;
    max-height: 321px;
}

.chooseTheme .chooseTheme-thumb li {
    padding-left: 12px;
    margin: 6px 0;
    width: 20%;
}

@media (max-width: 767px) {
    .chooseTheme .chooseTheme-thumb li {
        width: 33.333333%;
    }
}

.chooseTheme .chooseTheme-thumb .thumb {
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.chooseTheme .chooseTheme-thumb .thumb:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 50%,
        rgba(0, 0, 0, 0.85) 100%
    );
}

.chooseTheme .chooseTheme-thumb .thumb img {
    width: 100%;
}

.chooseTheme .chooseTheme-thumb .thumb .chooseTheme-check {
    background: url(../images/icon-radio-tick.svg) no-repeat 50%;
    background-size: 20px 20px;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    display: block;
    z-index: 2;
}

.chooseTheme .chooseTheme-thumb .thumb .thumb-tlt {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    padding: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.chooseTheme .chooseTheme-btn {
    margin-top: 24px;
    text-align: right;
}

.chooseTheme .chooseTheme-btn .btn {
    width: 279px;
    height: 40px;
}

@media (max-width: 767px) {
    .chooseTheme .chooseTheme-btn .btn {
        width: 100%;
    }
}

/*****/
.detail-evaluate {
    padding: 12px 0 0;
}

.detail-evaluate .tlt {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .detail-evaluate .tlt {
        font-size: 14px;
        font-weight: 500;
    }
}

.detail-evaluate .text-level {
    font-size: 16px;
    font-weight: 500;
    color: #444;
    margin-top: 17px;
}

@media (max-width: 767px) {
    .detail-evaluate .text-level {
        font-size: 14px;
    }
}

.detail-evaluate .review-article {
    margin-top: 20px;
    font-size: 16px;
    color: #aaa;
}

@media (max-width: 767px) {
    .detail-evaluate .review-article {
        font-size: 12px;
    }
}

.detail-evaluate .star-list {
    padding-top: 10px;
}

.detail-evaluate .star-list li img {
    width: 26px;
}

.detail-evaluate .photoStore-head {
    margin-top: 16px;
    padding-right: 30px;
    background: url(../images/icon-arrow-slider.svg) no-repeat center right;
}

.detail-evaluate .photoStore-head .photoStore-head__item {
    width: 40px;
    height: 40px;
}

.detail-evaluate .photoStore-head .photoStore-head__text {
    width: calc(100% - 40px);
}

.detail-evaluate .productReviews {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
    padding-right: 30px;
    background: url(../images/icon-arrow-slider.svg) no-repeat center right;
}

.detail-evaluate .productReviews .productReviews-thumb {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
}

.detail-evaluate .productReviews .productReviews-content {
    width: calc(100% - 40px);
    padding-left: 8px;
}

.detail-evaluate .productReviews .productReviews-content .tlt {
    color: #444;
    font-size: 16px;
    font-weight: 500;
}

.detail-evaluate .slider--photo {
    margin-top: 8px;
}

.detail-evaluate .slider--photo .slick-list {
    margin-left: -7px;
}

.detail-evaluate .slider--photo .slick-list .slick-slide {
    padding-left: 7px;
}

.detail-evaluate .slider--photo .photoGallery-reality {
    border-radius: 4px;
    overflow: hidden;
}

.red-noti {
    position: absolute;
    top: -5px;
    right: -3px;
    width: 12px;
    height: 12px;
    background: #ff4451;
    justify-content: center;
    overflow: hidden;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    display: -moz-flex;
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
}

/*** ***/
.main--notification {
    padding: 32px 0 66px;
    background: #f8f8f8;
}

@media (max-width: 991px) {
    .main--notification {
        padding-top: 16px;
    }
}

@media (max-width: 767px) {
    .main--notification {
        padding-top: 0;
    }
}

.main--notification .notification-box {
    background: #fff;
    padding: 24px;
    border-radius: 4px;
}

@media (max-width: 991px) {
    .main--notification .notification-box {
        margin-top: 16px;
    }
}

@media (max-width: 767px) {
    .main--notification .notification-box {
        border-radius: 0;
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 24px 0;
    }
}

.main--notification .notification-box .nav {
    border-bottom: 1px solid #f4f4f4;
}

@media (max-width: 767px) {
    .main--notification .notification-box .nav {
        padding: 0 16px;
    }
}

.main--notification .notification-box .nav li {
    padding-right: 32px;
}

.main--notification .notification-box .nav li:last-child {
    padding-right: 0;
}

.main--notification .notification-box .nav li a {
    padding: 0 0 16px;
    display: block;
    color: #444;
    font-weight: 600;
    position: relative;
}

.main--notification .notification-box .nav li a::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    display: block;
}

.main--notification .notification-box .nav li a.active {
    color: #34beef;
}

.main--notification .notification-box .nav li a.active::after {
    background: #34beef;
}

.main-settingUser .wrapContent {
    padding: 32px 0 66px;
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    display: block;
}

.main-settingUser .user-thumb {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto 40px;
    position: relative;
    background-color: #ccc;
}

.main-settingUser .user-thumb .thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.main-settingUser .user-thumb .btn-change {
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: #34beef;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    right: 0px;
    bottom: 0;
}

.bg-primary {
    background: #34beef !important;
}

.color-white {
    color: white !important;
}

.main-settingUser .form-title {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
}

.main-settingUser .user-form {
    /* margin-bottom: 24px; */
}

.main-settingUser .user-form .select-action {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 32px;
    margin-bottom: 32px;
}

.main-settingUser .user-form .select-action li {
    width: calc(50% - 10px);
    border-radius: 8px;
    padding: 2px 40px;
    height: 64px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: #666;
    cursor: pointer;
    position: relative;
}

@media (max-width: 767px) {
    .main-settingUser .user-form .select-action li {
        width: 100%;
        margin-bottom: 16px;
    }
}

.main-settingUser .user-form .select-action li.active {
    background-color: #e7fcfd;
    color: #34beef;
}

.main-settingUser .user-form .select-action li.active::after {
    content: "";
    width: 24px;
    height: 24px;
    background: url("../images/icon-radio-tick.svg") no-repeat;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -12px;
}

.main-settingUser .user-form .select-action li.active .icon .svg {
    max-width: 26px;
}

.main-settingUser .user-form .select-action li.active .icon .svg path {
    fill: #34beef;
}

.main-settingUser .user-form .select-action li .icon {
    display: block;
    margin-right: 6px;
}

.main-settingUser .user-form .row {
    margin-left: -10px;
    margin-right: -10px;
}

.main-settingUser .user-form .row > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
}

.main-settingUser .user-form .form-icon .icon img {
    max-width: 18px;
}

.main-settingUser .user-form .formUpload-thumb li {
    margin-top: 0;
}

.main-settingUser .user-description {
    margin-bottom: 16px;
}

.main-settingUser .user-description .title-desc {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
}

.main-settingUser .user-description .desc-box {
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #ededed;
}

.main-settingUser .user-description .desc-box textarea {
    border: none;
    height: 180px;
}

.main-settingUser .user-description .desc-box textarea:hover {
    border: none;
}

.main-settingUser .social-link {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    margin-bottom: 46px;
}

@media (max-width: 767px) {
    .main-settingUser .social-link {
        justify-content: space-between;
    }
}

.main-settingUser .social-link .social-tit {
    color: #aaa;
    width: 90px;
}

.main-settingUser .social-link .list-social {
    display: flex;
    flex-flow: wrap;
}

.main-settingUser .social-link .list-social li {
    margin-right: 16px;
}

@media (max-width: 767px) {
    .main-settingUser .social-link .list-social li {
        width: 24px;
        margin-right: 8px;
    }
}

.main-settingUser .social-link .list-social li:last-child {
    margin-right: 0;
}

.main-settingUser .btn-save {
    height: 48px;
    width: 100%;
    max-width: 310px;
}

@media (max-width: 767px) {
    .main-settingUser .btn-save {
        max-width: 100%;
    }
}

.wrap-support {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 56px;
    align-items: flex-start;
}

.wrap-support .menubar {
    width: 32.1%;
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 24px;
}

@media (min-width: 768px) {
    .wrap-support .menubar {
        position: sticky;
        top: 100px;
    }
}

@media (max-width: 767px) {
    .wrap-support .menubar {
        width: 100%;
        margin-bottom: 20px;
    }
}

.wrap-support .menubar .menubar-list > li {
    border-bottom: 1px solid #f3f3f3;
}

.wrap-support .menubar .menubar-list > li:first-child > a {
    padding-top: 0;
}

.wrap-support .menubar .menubar-list > li:last-child {
    border-bottom: none;
}

.wrap-support .menubar .menubar-list > li:last-child > a {
    padding-bottom: 0;
}

.wrap-support .menubar .menubar-list > li > a {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    color: #222222;
    font-weight: 500;
    padding: 16px 0;
}

.wrap-support .menubar .menubar-list > li > a:hover,
.wrap-support .menubar .menubar-list > li > a.active {
    color: #34beef;
}

.wrap-support .menubar .menubar-list > li > a:hover .icon .svg path,
.wrap-support .menubar .menubar-list > li > a.active .icon .svg path {
    stroke: #34beef;
}

.wrap-support .menubar .menubar-list > li > a .icon {
    margin-right: 6px;
    display: flex;
    align-items: center;
    width: 19px;
}

.wrap-support .menubar .menubar-list > li > a .icon .svg path {
    transition: all ease 0.3s;
}

.wrap-support .menubar .menubar-list > li > a.hashsub {
    position: relative;
    padding-right: 30px;
}

.wrap-support .menubar .menubar-list > li > a.hashsub::after {
    position: absolute;
    width: 14px;
    height: 7px;
    content: "";
    right: 0;
    top: 24px;
    transition: all ease 0.3s;
    background: url("../images/icon-arrow-listNav.svg") no-repeat 0 0;
}

.wrap-support
    .menubar
    .menubar-list
    > li
    > a.hashsub[aria-expanded="true"]::after {
    transform: rotate(-180deg);
}

.wrap-support .menubar .menubar-list .submenu {
    padding-left: 26px;
}

.wrap-support .menubar .menubar-list .submenu li {
    border-bottom: 1px solid #f3f3f3;
}

.wrap-support .menubar .menubar-list .submenu li:first-child a {
    padding-top: 4px;
}

.wrap-support .menubar .menubar-list .submenu li:last-child {
    border-bottom: none;
}

.wrap-support .menubar .menubar-list .submenu li a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    padding: 16px 0;
}

.wrap-support .menubar .menubar-list .submenu li a:hover,
.wrap-support .menubar .menubar-list .submenu li a.active {
    color: #34beef;
}

.wrap-support .menubar .setting-view-save {
    border-top: 8px solid #f4f4f4;
    padding-top: 28px;
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.wrap-support .menubar .setting-view-save .text {
    width: calc(100% - 55px);
    display: block;
    font-size: 14px;
    font-weight: 500;
}

.wrap-support .support-content {
    width: 64.5%;
}

@media (max-width: 767px) {
    .wrap-support .support-content {
        width: 100%;
    }
}

.wrap-support .support-content .faq-list {
    margin-top: -24px;
}

.wrap-support .support-content .faq-list li {
    border-bottom: 1px solid #f4f4f4;
}

.wrap-support .support-content .faq-list li:last-child {
    border-bottom: none;
}

.wrap-support .support-content .faq-list li .faq-title {
    background: url("../images/icon-Chat.png") no-repeat 0 24px;
    padding: 24px 40px 24px 28px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
}

.wrap-support .support-content .faq-list li .faq-title::after {
    position: absolute;
    width: 14px;
    height: 7px;
    content: "";
    right: 0;
    top: 24px;
    transition: all ease 0.3s;
    background: url("../images/icon-arrow-listNav.svg") no-repeat 0 0;
}

.wrap-support
    .support-content
    .faq-list
    li
    .faq-title[aria-expanded="true"]::after {
    transform: rotate(-180deg);
}

.wrap-support .support-content .faq-list li .faq-body p {
    margin-bottom: 16px;
}

.professional-project-image {
    height: 200px;
}

@media (max-width: 767px) {
    .professional-project-image {
        height: 150px;
    }
}

@media (max-width: 767px) {
    .professional-project-image {
        height: 100px;
    }

    .hide-in-mobile {
        display: none;
    }
}

.btn-more-goi-y:focus {
    outline: none;
    box-shadow: none;
}

/*------------------Edittor-------------------*/
.demo-dfree {
    position: relative;
    width: 100%;
    /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2); */
    text-align: left;
    /* color: #626262; */
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 0px;
}

.demo-dfree *[contentEditable="true"] {
    margin-top: 10px;
}

/* .demo-dfree *[contentEditable="true"]:hover {
    outline: 1px solid #1976D2;
  } */
.demo-dfree h1 {
    /* color: #1976D2; */
    font-size: 2em;
}

.demo-dfree h2 {
    /* color: #1976D2; */
    font-size: 2em;
}

.demo-dfree h3 {
    font-size: 1.5em;
    /* color: #403f42; */
    /* color: inherit; */
}

.demo-dfree h2,
.demo-dfree h3 {
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 40px;
}

.demo-dfree ul,
.demo-dfree ol {
    padding-left: 20px;
}

.demo-dfree ul {
    list-style: disc;
}

.demo-dfree ol {
    list-style: decimal;
}

.demo-dfree .mce-content-body a {
    text-decoration: underline;
}

.demo-dfree textarea {
    display: none;
}

.demo-dfree .dfree-header {
    font-size: 2.4em;
    text-align: center;
    margin: 10px;
}

.demo-dfree .dfree-body p {
    margin-bottom: 10px;
}

.demo-dfree .dfree-body blockquote {
    margin-left: 30px;
    padding-left: 10px;
    margin-right: 40px;
    font-style: italic;
    border-left: 1px solid #696969;
}

.demo-dfree .dfree-body .mce-content-body a {
    text-decoration: underline;
}

.demo-dfree .dfree-body td,
.demo-dfree .dfree-body tr {
    border: 1px solid #696969;
}

.tox-tinymce {
    z-index: 2000;
}

.modal-backdrop {
    z-index: 1050;
}

.modalCenter {
    display: flex !important;
    align-items: center;
    height: 100vh;
    z-index: 10000;
}

#__next {
    height: auto;
    /* overflow-x: hidden; */
}

.header {
    position: sticky;
    position: -webkit-sticky;
    /* position: fixed; */
    z-index: 101;
    top: 0;
    background-color: white;
    left: 0;
    right: 0;
}

.not-sticky-header {
    position: unset;
}

.main {
    /* margin-top: 134px; */
}

.navView-add {
    /* top: 134px; */
}

.main-with-one-menu {
    /* margin-top: 81px; */
}

.mce-content-body {
    min-height: 100px;
}

@media (max-width: 767px) {
    .main {
        /* margin-top: 105px; */
        /* padding-bottom: 62px; */
    }

    .navView-add {
        /* top: 105px; */
    }

    .main-with-one-menu {
        /* margin-top: 67px; */
    }
}

.navView {
    border-bottom: 1px solid #f4f4f4;
    position: sticky;
    z-index: 100;
    top: 81px;
    background-color: white;
    left: 0;
    right: 0;
}

@media (max-width: 767px) {
    .navView {
        top: 62px;
        width: calc(100%);
        margin-left: -16px;
    }
}

.navView ul {
    text-align: center;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}

.navView ul li {
    display: inline-block;
    padding: 0 24px;
}

@media (max-width: 991px) {
    .navView ul li {
        padding: 0 14px;
    }
}

@media (max-width: 767px) {
    .navView ul li {
        padding: 0 8px;
    }
}

.navView ul li a {
    display: block;
    padding: 14px 0;
    color: #444;
    font-weight: 500;
    position: relative;
}

@media (max-width: 767px) {
    .navView ul li a {
        font-size: 13px;
        padding: 14px 0 9px;
    }
}

.navView ul li a.active {
    color: #34beef;
}

.navView ul li a.active::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    background: #34beef;
}

.navView ul li a.active .svg path {
    fill: #34beef;
}

.navView ul li a span {
    color: #34beef;
}

.anchor-link {
    scroll-margin-top: 180px;
}

@media (max-width: 767px) {
    .anchor-link {
        scroll-margin-top: 131px;
    }
}

@media (max-width: 991px) {
    .wrap_banner .wrap_bnr {
        width: 100%;
        display: none !important;
    }
}

.luu-tru-pro .lstPhoto > ul > li {
    width: 50% !important;
    padding-top: 24px !important;
    margin-bottom: 20px !important;
}

.wrapMembership {
    display: flex;
    flex-wrap: wrap;
}

.wrapMembership .wrapMembership-info {
    width: 365px;
}

@media (max-width: 991px) {
    .wrapMembership .wrapMembership-info {
        width: 100%;
    }
}

.wrapMembership .wrapMembership-content {
    width: calc(100% - 365px);
    padding-left: 40px;
}

@media (max-width: 991px) {
    .wrapMembership .wrapMembership-content {
        width: 100%;
        padding-left: 0;
    }
}

.wrapMembership .back {
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 1;
}

.wrapMembership .back .btn:focus {
    box-shadow: none;
}

.wrapMembership .boxMembership {
    border-radius: 16px;
    margin-top: 24px;
    min-height: 240px;
    position: sticky;
    top: 140px;
}

@media (max-width: 767px) {
    .wrapMembership .boxMembership {
        margin-top: 0;
        border-radius: 0;
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.wrapMembership .boxMembership.boxMembership--blue {
    background: url(../images/bg-Membership.png) no-repeat top right #4e87cc;
}

.wrapMembership .boxMembership.boxMembership--red {
    background: url(../images/bg-Membership01.png) no-repeat top right #c64a4a;
}

.wrapMembership .boxMembership.boxMembership--yellow {
    background: url(../images/bg-Membership02.png) no-repeat top right #dfad2e;
}

.wrapMembership .member-item {
    text-align: center;
    padding: 60px 16px 0;
}

@media (max-width: 991px) {
    .wrapMembership .member-item {
        padding-top: 40px;
    }
}

@media (max-width: 767px) {
    .wrapMembership .member-item {
        padding-top: 40px;
    }
}

.wrapMembership .member-item .thumb {
    display: block;
}

.wrapMembership .member-item .thumb img {
    max-width: 72px;
}

.wrapMembership .member-item .name {
    margin-top: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.wrapMembership .member-item .lable {
    font-size: 13px;
    color: #fff;
    margin-top: 6px;
}

@media (max-width: 991px) {
    .wrapMembership .member-item .lable {
        font-size: 12px;
    }
}

.wrapMembership .transcript {
    background: #fff;
    border-radius: 16px;
    min-height: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 24px 24px 8px;
    margin-top: 26px;
}

@media (max-width: 767px) {
    .wrapMembership .transcript {
        border-radius: 16px 16px 0 0;
        box-shadow: none;
        border-bottom: 6px solid #f4f4f4;
    }
}

.wrapMembership .transcript .existingPoints {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 20px;
}

@media (max-width: 991px) {
    .wrapMembership .transcript .existingPoints {
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 991px) {
    .wrapMembership .transcript .existingPoints .existingPoints-info {
        width: calc(100% - 316px);
        padding-right: 12px;
    }
}

@media (max-width: 767px) {
    .wrapMembership .transcript .existingPoints .existingPoints-info {
        width: 100%;
        padding-right: 0;
    }
}

.wrapMembership .transcript .existingPoints .existingPoints-info .tlt {
    color: #444;
}

.wrapMembership .transcript .existingPoints .existingPoints-info .number {
    margin-top: 4px;
}

.wrapMembership .transcript .existingPoints .existingPoints-info .number span {
    color: #34beef;
    font-size: 28px;
    line-height: 28px;
    font-weight: 600px;
    vertical-align: bottom;
}

.wrapMembership .transcript .existingPoints .existingPoints-info .dateTime {
    color: #aaa;
    font-size: 12px;
    margin-top: 4px;
}

.wrapMembership .transcript .existingPoints .existingPoints-btn {
    display: flex;
    margin-top: 24px;
    margin-left: -12px;
}

@media (max-width: 991px) {
    .wrapMembership .transcript .existingPoints .existingPoints-btn {
        width: 316px;
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .wrapMembership .transcript .existingPoints .existingPoints-btn {
        width: 100%;
        margin-top: 24px;
    }
}

.wrapMembership .transcript .existingPoints .existingPoints-btn .btn {
    width: calc(50% - 12px);
    height: 40px;
    margin-left: 12px;
}

.wrapMembership .transcript .existingPoints .existingPoints-btn .btn span {
    margin-right: 10px;
    display: inline-block;
    margin-top: -2px;
}

.wrapMembership .transcript .accumulatePoints {
    padding: 20px 0 0;
}

.wrapMembership .transcript .accumulatePoints .congrats {
    text-align: center;
    padding-top: 16px;
}

.wrapMembership .transcript .accumulatePoints .congrats .congrats-item img {
    max-width: 32px;
}

.wrapMembership .transcript .accumulatePoints .congrats .congrats-note {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
}

.wrapMembership .transcript .accumulatePoints .congrats .congrats-note span {
    color: #34beef;
}

.wrapMembership .transcript .accumulatePoints .tlt {
    color: #444;
}

.wrapMembership .transcript .accumulatePoints .note {
    margin-top: 16px;
    text-align: center;
    color: #666;
    font-size: 12px;
}

.wrapMembership .transcript .accumulatePoints .pointsSlider {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
}

.wrapMembership
    .transcript
    .accumulatePoints
    .pointsSlider
    .pointsSlider-slider {
    width: calc(100% - 32px);
    padding-right: 8px;
}

.wrapMembership .transcript .accumulatePoints .pointsSlider .pointsSlider-icon {
    width: 32px;
}

.wrapMembership .transcript .accumulatePoints .pointsSlider .slider-points {
    background: #ddd;
    height: 2px;
    display: block;
    position: relative;
}

.wrapMembership
    .transcript
    .accumulatePoints
    .pointsSlider
    .slider-points
    .slider-handle {
    background: #34beef;
    height: 2px;
    display: block;
}

.wrapMembership
    .transcript
    .accumulatePoints
    .pointsSlider
    .slider-points
    .slider-range {
    width: 6px;
    height: 6px;
    background: #34beef;
    position: absolute;
    top: -2px;
    border-radius: 50%;
    cursor: pointer;
}

.wrapMembership .transcript .navPoints ul li {
    border-bottom: 1px solid #f4f4f4;
}

.wrapMembership .transcript .navPoints ul li:last-child {
    border-bottom: none;
}

.wrapMembership .transcript .navPoints ul li a {
    color: #666;
    padding: 16px 0;
    display: block;
}

.wrapMembership .transcript .navPoints ul li a span {
    background: url(../images/icon-arrow-slider.svg) no-repeat right center;
    background-size: 7px;
    padding-right: 16px;
}

.wrapMembership .boxInterest {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0 24px 32px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest {
        border: none;
        border-bottom: 6px solid #f4f4f4;
        margin-top: 0;
        border-radius: 0;
        padding: 0 0 32px;
        margin-left: -16px;
        width: calc(100% + 32px);
    }
}

.wrapMembership .boxInterest .navInterest {
    display: flex;
    border-bottom: 1px solid #f4f4f4;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .navInterest li {
        width: 33.333333%;
    }
}

.wrapMembership .boxInterest .navInterest li a {
    padding: 13px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
    font-weight: 500;
    font-size: 13px;
    position: relative;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .navInterest li a {
        flex-wrap: wrap;
        text-align: center;
        font-size: 12px;
        padding: 13px 13px;
        color: #666;
    }
}

.wrapMembership .boxInterest .navInterest li a.active {
    color: #34beef;
}

.wrapMembership .boxInterest .navInterest li a.active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    height: 2px;
    width: 100%;
    display: block;
    background: #34beef;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .navInterest li a.active::after {
        width: 32px;
        height: 4px;
        border-radius: 2px;
        left: calc(50% - 16px);
    }
}

.wrapMembership .boxInterest .navInterest li a span {
    min-width: 32px;
    margin-right: 8px;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .navInterest li a span {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-bottom: 4px;
    }
}

.wrapMembership .boxInterest .navInterest li a span img {
    max-width: 32px;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .navInterest li a span img {
        max-width: 24px;
    }
}

.wrapMembership .boxInterest .interest-info {
    padding-top: 24px;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .interest-info {
        padding: 32px 24px 0;
    }
}

.wrapMembership .boxInterest .interest-info .tlt {
    font-weight: 600;
    font-size: 20px;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .interest-info .tlt {
        font-size: 14px;
        font-weight: 500;
    }
}

.wrapMembership .boxInterest .interest-info ul li {
    padding-top: 18px;
    /* display: flex; */
    color: #444;
}

.wrapMembership .boxInterest .interest-info ul li a {
    color: #34beef;
}

@media (max-width: 767px) {
    .wrapMembership .boxInterest .interest-info ul li {
        font-size: 13px;
    }
}

.wrapMembership .boxInterest .interest-info ul li span {
    min-width: 20px;
    margin-right: 10px;
    margin-top: -2px;
    display: inline-block;
}

.wrapMembership .boxInterest .interest-info ul li span img {
    width: 20px;
}

.groupDeals .titleDeals {
    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.groupDeals .titleDeals:first-of-type {
    padding-top: 24px;
}

.groupDeals .titleDeals .tlt {
    font-weight: 600;
    font-size: 20px;
}

@media (max-width: 767px) {
    .groupDeals .titleDeals .tlt {
        font-size: 16px;
    }
}

.groupDeals .titleDeals .more {
    color: #34beef;
    font-weight: 500;
}

@media (max-width: 767px) {
    .groupDeals .titleDeals .more {
        padding-left: 30px;
    }
}

.lstDeals {
    overflow-y: auto;
    padding: 0 2px;
}

@media (max-width: 767px) {
    .lstDeals {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px;
    }
}

.lstDeals > ul {
    white-space: nowrap;
    margin-left: -20px;
    padding-bottom: 4px;
}

@media (max-width: 767px) {
    .lstDeals > ul {
        margin-left: 0;
    }
}

.lstDeals > ul > li {
    width: calc(33.333333% - 3px);
    padding-left: 20px;
    display: inline-block;
    margin-top: 16px;
}

@media (max-width: 767px) {
    .lstDeals > ul > li {
        width: calc(70% - 5px);
        padding-left: 0;
        padding-right: 12px;
    }
}

.dealsInfo {
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.dealsInfo .dealsInfo-thumb {
    position: relative;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    padding-top: calc(100% - 27%);
    background: #f2f2f2;
}

.dealsInfo .dealsInfo-thumb a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dealsInfo .dealsInfo-thumb a img {
    max-height: 230px;
}

@media (max-width: 767px) {
    .dealsInfo .dealsInfo-thumb a img {
        max-height: 495px;
        width: 100%;
    }
}

.dealsInfo .dealsInfo-content {
    padding: 12px;
    height: 87px;
    white-space: normal;
}

.dealsInfo .dealsInfo-content .dealsInfo-label {
    color: #aaa;
    font-size: 12px;
}

.dealsInfo .dealsInfo-content .dealsInfo-tlt {
    font-weight: 600;
    margin-top: 4px;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dealsInfo .dealsInfo-content .dealsInfo-tlt a {
    color: #222;
}

.dealsInfo .dealsInfo-content .dealsInfo-point {
    color: #444;
    font-size: 13px;
    margin-top: 8px;
}

.dealsInfo .dealsInfo-content .dealsInfo-point span {
    color: #34beef;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    top: 2px;
}

.main--accountViewProFile .lstPhoto > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
}

.main--accountViewProFile .lstPhoto > ul > li {
    width: 50%;
    padding-left: 20px;
    margin-top: 24px;
}

@media (max-width: 767px) {
    .main--accountViewProFile .lstPhoto > ul > li {
        width: 100%;
    }
}

.slick-next:before {
    display: none;
}

.slick-prev:before {
    display: none;
}

.new-pro .new-membership {
    position: absolute;
    bottom: 0;
    padding: 0;
    right: 0;
}

.new-pro .new-membership .member-level {
    border-radius: 0px !important;
    border-top-left-radius: 8px !important;
    font-size: 12px;
    padding: 2px 8px 2px 8px;
}

.new-pro .user-info-thumb {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.new-pro .user-info-thumb > img {
    width: auto !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 100% !important;
    object-fit: contain;
}

.new-pro .new-avatar {
    position: absolute;
    top: 0;
}

.new-pro .user-info {
    padding: 12px 0px 16px 0px !important;
}

.wrapProdetail {
    /*display: flex;
	flex-wrap: wrap;*/
    padding-top: 0px;
    /* overflow: hidden;  */
}

.wrapProdetail .wrapPro-info {
    width: 365px;
    float: right;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapPro-info {
        width: 100%;
    }
}

.wrapProdetail .wrapPro-content {
    width: calc(100% - 365px);
    padding-right: 40px;
    float: left;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapPro-content {
        width: 100%;
        padding-right: 0;
    }
}

.wrapProdetail .wrapPro-content .title-detail {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

@media (max-width: 767px) {
    .wrapProdetail .wrapPro-content .title-detail {
        font-size: 20px;
        line-height: 28px;
    }
}

.wrapProdetail .wrapPro-content .text-detail {
    color: #444;
    margin-top: 28px;
}

.wrapProdetail .wrapPro-content .headFollow {
    display: none;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapPro-content .headFollow {
        display: flex;
    }
}

.wrapProdetail .boxPhoto {
    background: #f8f8f8;
    border-radius: 16px;
    padding: 24px 16px;
}

@media (max-width: 991px) {
    .wrapProdetail .boxPhoto {
        display: none;
    }
}

.wrapProdetail .headFollow {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .wrapProdetail .headFollow {
        border-bottom: 1px solid #f4f4f4;
        padding-bottom: 12px;
        margin-bottom: 12px;
    }
}

.wrapProdetail .headFollow .head-acc {
    width: calc(100% - 110px);
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
}

.wrapProdetail .headFollow .head-acc .head-item {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
}

.wrapProdetail .headFollow .head-acc .head-item img {
    width: 48px;
}

.wrapProdetail .headFollow .head-acc .head-info {
    width: calc(100% - 48px);
    padding-left: 8px;
}

.wrapProdetail .headFollow .head-acc .head-info .tlt {
    color: #444;
    font-size: 18px;
    font-weight: 500;
}

.wrapProdetail .headFollow .head-acc .head-info .label {
    color: #666;
    font-size: 13px;
}

.wrapProdetail .headFollow .btn.btn--blue {
    width: 110px;
    height: 32px;
    font-size: 13px;
    padding: 0 10px;
}

.wrapProdetail .headFollow .btn.btn--blue span {
    padding-right: 4px;
}

.wrapProdetail .otherPhoto {
    display: flex;
    flex-wrap: wrap;
    padding-top: 14px;
    margin-left: -2px;
}

.wrapProdetail .otherPhoto li {
    width: 50%;
    margin-top: 2px;
    padding-left: 2px;
}

.wrapProdetail .otherPhoto li a {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.wrapProdetail .otherPhoto li a img {
    position: absolute;
    top: 0;
}

.wrapProdetail .otherPhoto li a span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #fff;
    font-weight: 500;
}

.wrapProdetail .wrapShare {
    padding-top: 32px;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        padding: 8px 40px;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .wrapProdetail .wrapShare {
        padding: 8px 16px;
    }
}

.wrapProdetail .wrapShare > ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare > ul {
        width: 50%;
    }
}

.wrapProdetail .wrapShare > ul > li {
    width: 25%;
    padding-left: 10px;
    text-align: center;
}

.wrapProdetail .wrapShare span {
    display: block;
}

.wrapProdetail .wrapShare span.item img {
    height: 26px;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare span.item img {
        height: 20px;
    }
}

.wrapProdetail .wrapShare span.item .svg path {
    fill: #444;
}

.wrapProdetail .wrapShare span.item.show .svg path {
    fill: #34beef;
}

.wrapProdetail .wrapShare span.toolsView-number {
    margin-top: 6px;
    color: #444;
}

.wrapProdetail .wrapShare .dropdown-menu {
    width: 228px;
    padding: 12px 16px;
    margin: 0;
    border: none;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.wrapProdetail .wrapShare .listShare {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -6px;
}

.wrapProdetail .wrapShare .listShare li {
    width: 25%;
    padding-left: 6px;
}

.wrapProdetail .wrapShare .listShare li img {
    width: 100%;
}

.wrapProdetail .wrapShare .wrapShare-btn {
    margin-top: 32px;
    display: flex;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare .wrapShare-btn {
        width: 50%;
        margin-top: 0;
    }
}

.wrapProdetail .wrapShare .wrapShare-btn .btn {
    padding: 0 6px;
}

.wrapProdetail .wrapShare .wrapShare-btn .btn.btn--blue {
    width: 100%;
    height: 48px;
    font-size: 16px;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare .wrapShare-btn .btn.btn--blue {
        height: 40px;
        font-size: 13px;
    }
}

.wrapProdetail .wrapShare .wrapShare-btn .btn.btn-outline-white {
    height: 40px;
    width: 40px;
    margin-left: 8px;
    display: none;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare .wrapShare-btn .btn.btn-outline-white {
        display: block;
    }
}

.wrapProdetail .wrapShare .wrapShare-btn .btn.btn-outline-white img {
    width: 18px;
}

.wrapProdetail .wrapShare .wrapShare-more {
    margin-top: 16px;
    display: flex;
}

@media (max-width: 991px) {
    .wrapProdetail .wrapShare .wrapShare-more {
        display: none;
    }
}

.wrapProdetail .wrapShare .wrapShare-more .btn {
    height: 48px;
    font-size: 16px;
    padding: 0 6px;
    width: 100%;
}

.wrapProdetail .wrapShare .wrapShare-more .btn:first-of-type {
    width: 48px;
    margin-right: 8px;
}

.wrapProdetail .wrapShare .wrapShare-more .btn img {
    width: 18px;
}

.wrapShare .item img {
    cursor: pointer;
}

.product-description ul {
    list-style: inside;
    white-space: initial;
}

a.link-text {
    color: #222;
}

a.link-text:hover {
    color: #222;
}

#header-switch-version {
    padding: 0;
    margin: 0;
    background: #34beef;
}

.switch-version {
    color: #ffffff;
    /* padding: 7px; */
    text-align: center;
    display: block;
    justify-content: center;
}

.max-1320px {
    max-width: 1320px;
    margin: 0 auto;
    width: 100%;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.switch-version p {
    display: inline;
    line-height: 32px;
}

.switch-version .btn-switch-version {
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
    background: transparent;
    /* width: 109px; */
    padding: 4px 8px;
    height: 32px;
    flex: none;
    order: 2;
    flex-grow: 0;
    /* margin: 0px 16px; */
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
}

.switch-version .btn-switch-version:hover {
    background-color: #31aad5;
}

@media (max-width: 767px) {
    .switch-version p {
        font-size: 13px;
        line-height: 24px;
    }

    .switch-version .btn-switch-version {
        height: 24px;
    }

    /* .switch-version br {
        display: block;
    } */

    /* .switch-version .btn-switch-version {
        font-size: 13px;
        line-height: 18px;
    } */
}

@media (max-width: 991px) {
    .old-format iframe {
        width: 100%;
        height: 50vw;
    }
}

.img-absolute {
    padding-top: 63.7%;
    position: relative;
}

.img-absolute.emagazine {
    padding-top: 50%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.img-absolute > img {
    position: absolute;
    top: 0px;
    width: auto !important;
    height: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 0px !important;
    max-height: 100% !important;
    object-fit: cover;
}

.news-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 0.5)
    );
}

.img-absolute.emagazine .news-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(0, 0, 0, 1)
    );
}

.news-col {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    padding: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.news-col .news-col-left {
    width: 70%;
}

.news-col .news-col-left.emagazine {
    width: 60%;
}

.news-content .news-col-left img {
    display: inline-block;
    margin-right: 4px;
}

.news-content .news-col-left p {
    height: auto;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
    margin: 5px 0 0;
    color: #fff;
    display: block;
}

.news-content .news-col-left.emagazine p {
    height: auto;
    font-size: 32px;
    line-height: 40px;
    overflow: hidden;
    margin-bottom: 18px;
    color: #fff;
    display: block;
}

.news-col .news-col-right {
    width: 30%;
    padding-left: 10px;
    overflow: hidden;
}

.news-col-right .share-number {
    float: right;
}

.news-col-left .share-number {
    margin-left: 8px;
}

.share-number {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.share-number li {
    color: #fff;
}

.share-number li {
    padding-right: 28px;
}

.share-number li:last-child {
    padding-right: 0;
}

.share-number li > img {
    display: inline-block;
    margin-right: 4px;
    width: 18px;
    height: 18px;
}

.emagazine .share-number li > img {
    display: inline-block;
    margin-right: 4px;
    width: 20px;
    height: 20px;
}

@media (max-width: 991px) {
    .news-col {
        bottom: 8px;
        padding: 12px;
    }

    .news-content .news-col-left p {
        font-size: 16px;
        line-height: 22px;
    }

    .share-number li {
        padding-right: 12px;
    }

    .share-number li > img {
        margin-right: 2px;
        width: 16px;
        height: 16px;
    }

    .news-col .news-col-left.emagazine {
        width: 100%;
    }

    .emagazine .share-number li > img {
        width: 16px;
        height: 16px;
    }

    .news-content .news-col-left.emagazine p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0px;
    }
}

@media (max-width: 767px) {
    .news-content .news-col-left {
        width: 50%;
    }

    .news-content .news-col-right {
        width: 50%;
    }
}

.dropdown-menu {
    z-index: 100;
}

@media (max-width: 767px) {
    .filterItem .sp-only.dropdown-menu {
        width: 100vw;
        max-height: 80vh;
        bottom: 57px;
        left: 0;
        top: initial !important;
        transform: none !important;
        position: fixed !important;
        overflow-y: auto;
        z-index: 1000;
        border-radius: 12px 12px 0px 0px;
    }

    .backdrop-filter {
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.8);
    }
}

.product-tag-small {
    position: relative;
}

.tuong-tu {
    position: absolute;
    top: 2px;
    width: 100%;
    text-align: center;
}

.tuong-tu p {
    font-size: 11px;
    margin: 0 auto;
    background-color: rgba(34, 34, 34, 0.4);
    font-weight: 500;
    color: white;
    width: 60px;
    margin-bottom: 0 !important;
    border-radius: 50px;
}

@media (max-width: 767px) {
    .tuong-tu p {
        font-size: 10px;
        font-weight: 400;
        width: 46px;
    }
}

a {
    color: #34beef;
}

a:hover {
    color: #34beef;
}

.slider-sub-small .slick-arrow {
    background: #fff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    top: 100px;
    z-index: 1;
}

.slider-sub-small .slick-arrow.slick-prev {
    left: -16px;
    background: url(../images/icon-arrow-slider-mobile.svg) no-repeat #fff
        center;
    transform: rotate(180deg);
    top: calc(50% - 12px);
}

.slider-sub-small .slick-arrow.slick-next {
    right: -16px;
    top: calc(50%);
    background: url(../images/icon-arrow-slider-mobile.svg) no-repeat #fff
        center;
}

.empty-data {
    text-align: center;
}

@media (min-width: 767px) {
    .empty-data {
        padding-top: 50px;
    }
}

.doi-tra-tai-lieu {
    position: relative;
    padding-top: 20%;
    width: 20%;
    background: none;
}

@media (max-width: 767px) {
    .doi-tra-tai-lieu {
        padding-top: 25%;
        width: 25% !important;
    }
}

.dealHot .slick-arrow.slick-prev {
    left: -16px;
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
    transform: rotate(180deg);
    top: calc(50% - 32px);
}

.dealHot .slick-arrow.slick-next {
    right: -16px;
    top: calc(50% - 16px);
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
}

.tag-list-search {
    max-height: 80vh;
    overflow: scroll;
}

@media (max-width: 767px) {
    .tag-list-search {
        max-height: 50vh;
    }
}

.tag-list-search .see-more .more {
    width: 100%;
}

.toolsView .btnMore {
    display: flex;
}

@media (max-width: 991px) {
    .toolsView .btnMore {
        width: 155px;
        margin-right: 15px;
    }
}

@media (max-width: 768px) {
    .toolsView .btnMore {
        margin-right: 0px;
    }
}

.toolsView .btnMore .btn.btn--blue {
    height: 48px;
    font-size: 16px;
    margin-top: 40px;
}

@media (max-width: 991px) {
    .toolsView .btnMore .btn.btn--blue {
        margin-top: 0;
        height: 40px;
        font-size: 14px;
        width: 100%;
    }

    .toolsView .btnEdit .btn.btn-outline-white {
        margin-top: 0;
        height: 40px;
        font-size: 14px;
        width: 100%;
    }
}

.toolsView .btnMore .btn.btn-outline-white {
    display: none;
    width: 40px;
    height: 40px;
    margin-left: 8px;
}

@media (max-width: 991px) {
    .toolsView .btnMore .btn.btn-outline-white {
        display: block;
    }
}

.toolsView .btnMore-sp {
    display: none;
    width: 50%;
}

@media (max-width: 991px) {
    .toolsView .btnMore-sp {
        display: flex;
    }
}

.toolsView .btnMore-sp .btn {
    width: 100%;
    padding: 0 6px;
    height: 40px;
}

.toolsView .btnMore-sp .btn.btn-outline-white {
    width: 40px;
    margin-left: 8px;
}

.custom-img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
}

.unset-img {
    width: 100%;
}

.unset-img > span {
    position: unset !important;
}

/* @media (min-width: 768px) { */
.filter-m-p {
    padding-bottom: 400px;
    margin-bottom: -400px;
}

/* } */

.coupon-display-box {
    width: 100%;
    text-align: right;
}

.coupon-display-box .coupon-display {
    display: inline-block;
    position: relative;
    background-color: #f0fbff;
    /* border: 1px solid #34beef; */
    border-radius: 3px;
}

.coupon-display-inner::after {
    right: -3px;
}

.coupon-display-inner::before {
    left: -3px;
}

.coupon-display-inner::before,
.coupon-display-inner::after {
    content: "";
    width: 6px;
    height: calc(100% - 5px);
    position: absolute;
    top: 2.5px;
    background-image: radial-gradient(#fff 2px, transparent 0);
    background-size: 6px 6px;
    background-position-x: -6px;
}

.chuyen-gia-content {
    margin-bottom: 32px;
}

@media (max-width: 768px) {
    .chuyen-gia-content {
        margin-bottom: 0px;
        margin-top: 24px;
    }
}

@media (max-width: 768px) {
    .display-button-slide-sp {
        padding: 0 10px;
    }
}

.titleHistory {
    font-weight: 600;
    font-size: 20px;
    margin-top: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f4f4f4;
}

.navTabs.navTabs--pt16 {
    padding-top: 16px;
}

.navTabs {
    border-bottom: 1px solid #f4f4f4;
}

.navTabs li {
    padding-right: 32px;
}

.navTabs li a.active {
    color: #34beef;
}

.navTabs li a {
    color: #444;
    font-weight: 600;
    padding-bottom: 16px;
    display: block;
    position: relative;
}

.navTabs li .tab-text.active {
    cursor: pointer;
    color: #34beef;
    border-bottom: 2px solid;
}

.navTabs li .tab-text {
    cursor: pointer;
    color: #444;
    font-weight: 600;
    padding-bottom: 16px;
    display: block;
    position: relative;
}

.tab-content > .active {
    display: block;
}

.lstCoins ul li {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    border-bottom: 1px solid #f4f4f4;
}

.lstCoins ul li .lstCoins-thumb {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    overflow: hidden;
}

.lstCoins ul li .lstCoins-thumb img {
    width: 100%;
}

.lstCoins ul li .lstCoins-info {
    width: calc(100% - 48px);
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
}

.lstCoins ul li .lstCoins-info .lstCoins-number.lstCoins-number--blue {
    color: #34beef;
}

.lstCoins ul li .lstCoins-info .lstCoins-number {
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
}

.lstCoins ul li .lstCoins-info .lstCoins-content .tlt {
    font-size: 16px;
    font-weight: 600;
}

.lstCoins ul li .lstCoins-info .lstCoins-content .txt {
    color: #444;
    margin-top: 4px;
}

.display-arrow .slick-arrow.slick-prev {
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
    transform: rotate(180deg);
    top: calc(50% - 32px);
}

.display-arrow .slick-arrow.slick-next {
    top: calc(50% - 16px);
    background: url(../images/icon-arrow-slider.svg) no-repeat #fff center;
}

/* .img-detail {
    width: 100%;
    position: relative;
    padding-top: 50%;
}

.img-detail img {
    position: absolute;
    top: 0;
    width: 100%;
} */

.display-1-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.display-2-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.dealhot-layout {
    max-width: 850px;
    margin: 0 auto;
}

.poll-card {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 40px 40px;
    gap: 24px;
    /* Neutral/100 */
    background: #ffffff;
    /* Neutral/500 */
    border: 1px solid #dddddd;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 9;
    align-self: stretch;
    flex-grow: 0;
}

@media (max-width: 768px) {
    .poll-card {
        padding: 24px 16px;
        gap: 16px;
    }
}

.poll-card > div {
    align-items: flex-start;
    width: 100%;
}

.poll-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
}

.poll-description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
}

.poll-question {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}

.poll-answer {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.poll-answer-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;

    /* Neutral/200 */
    background: #f8f8f8;
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.poll-answer-checkbox {
    width: 18px;
    height: 18px;
}

.poll-answer-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    max-width: 90%;
}

@media (max-width: 768px) {
    .poll-answer-label {
        max-width: 80%;
    }
}

.poll-footer {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.poll-button-group {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.poll-button-group button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    padding: 12px 26px;
}

.poll-card-modal {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 16px;
    gap: 24px;
    /* Neutral/100 */
    background: #ffffff;
    /* Neutral/500 */
    /* Inside auto layout */
    flex: none;
    order: 9;
    align-self: stretch;
    flex-grow: 0;
}

.result-answer-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 20px;
    width: 100%;
}

.result-answer-content-sub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.result-answer-content-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.result-answer-content-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
}

.result-answer-content-percent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
}

.result-answer-content-progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    isolation: isolate;
    width: 100%;
    border-radius: 4px;
    position: relative;
}

.result-answer-content-progress div {
    position: absolute;
    border-radius: 4px;
    height: 8px;
    top: calc(50% - 8px / 2);
}

.result-answer-content-progress .selected {
    background: #34beef;
    left: 0px;
}

.result-answer-content-progress .no-selected {
    width: 100%;
    background: #e7fcfd;
}

.pro-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;
    margin-top: 12px;
    width: 100%;
    background: #f8f8f8;
    border-radius: 12px;
}

.pro-card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #34beef;
}

.pro-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.pro-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

.pro-logo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.pro-name-business {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}

.pro-name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;

    color: #444444;
}

.pro-business {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
}

.pro-bio {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.pro-bio-txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    color: #666666;

    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media (max-width: 768px) {
    .pro-card {
        gap: 20px;
    }

    .pro-card-title {
        font-size: 16px;
    }

    .pro-logo {
        width: 40px;
        height: 40px;
    }

    .pro-name {
        font-size: 16px;
    }

    .pro-business {
        font-size: 13px;
    }

    .pro-bio-txt {
        -webkit-line-clamp: 5;
    }
}

.color-unset {
    color: unset;
}

.color-unset:hover {
    color: unset;
}

.magazine {
    font-family: "Roboto";
    color: #353535;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
}

.magazine .text {
    position: relative;
    margin: 0 auto;
    max-width: 700px;
    width: 50%;
    height: 612px;
}

.magazine .container {
    margin: 0 auto;
    max-width: 700px;
    width: 80%;
}

.magazine .text p {
    position: absolute;
}

.magazine-header {
    position: relative;
    width: 100vw;
    height: 612px;
}

.magazine-header .text-head-1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #5b6657;

    top: 321px;
    width: 334px;
}

.magazine-header .text-head-2 {
    bottom: 0px;

    height: 100px;
}

.magazine-body {
    padding-bottom: 70px;
}

.magazine-body .title {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #5b6657;

    width: 80%;
    margin: 0 auto;
}

.magazine-body .title-number {
    font-size: 40px;
    width: 60px;
    height: 60px;
    line-height: 60px;

    border-radius: 50%;
    color: #e5eeec;
    background-color: #5b6657;
}

.col-left .img-wide {
    width: 120%;
    max-width: unset;
    margin-left: -13%;
    margin-top: -15px;
}

.magazine-body .quote {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #5b6657;
    position: relative;
}

.quote-block::before {
    position: absolute;
    content: "";
    background: url("../images/emagazine/common/quote-start.png") center center
        no-repeat;
    background-size: contain;
    width: 28px;
    height: 47px;
    top: -40px;
    left: -28px;
}

.quote-block::after {
    position: absolute;
    content: "";
    background: url("../images/emagazine/common/quote-end.png") center center
        no-repeat;
    background-size: contain;
    width: 28px;
    height: 47px;
    bottom: -24px;
    right: -24px;
}

.magazine-body .quote-size-18 {
    font-size: 18px;
    line-height: 21px;
}

.magazine-body .quote-size-14 {
    font-size: 14px;
    line-height: 16.41px;
}

.magazine-body .quote-size-12 {
    font-size: 12px;
    line-height: 14px;
}

.quote-background {
    background-color: #bee1d4;
}

@media (max-width: 1068px) {
    .magazine .container {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .magazine-header {
        background-color: #b9beb7;
        height: auto;
    }

    .magazine .text {
        height: auto;
    }

    .magazine .text p {
        position: relative;
        width: 100%;
        top: 0;
        height: auto;
    }

    .magazine .container {
        width: 100%;
    }

    .magazine .text {
        width: 100%;
    }

    .magazine-body .title {
        width: 100%;
        font-size: 20px;
    }

    .col-left .img-wide {
        width: 100%;
        margin: 0 auto;
    }

    .quote-block::before {
        left: 0;
    }

    .quote-block::after {
        right: 0;
    }
}

.back-logo {
    height: 32px;
    margin-left: 32px;
}

.emagazine-logo {
    display: inline-block;
    width: calc(100% - 256px);
    vertical-align: middle;
    padding-left: calc(50% - 124px);
}

.emagazine-frame {
    height: calc(100vh - 58px);
}

.headerLog.headerLog-emagazine {
    padding-right: 25px;
    width: 200px;
    display: inline-block;
    vertical-align: middle;
    color: white;
}

.headerLog.headerLog-emagazine > ul > li.headerLog-info a {
    color: white;
}

@media (max-width: 1068px) {
    .back-icon {
        width: 32px;
    }

    .back-logo {
        height: 18px;
        margin-left: 8px;
    }

    .emagazine-logo {
        width: calc(100% - 32px);
        padding-left: calc(50% - 100px);
    }

    .emagazine-frame {
        height: calc(100vh - 34px);
    }

    .headerLog.headerLog-emagazine {
        display: none;
    }
}

.home-banner-long img {
    width: 87%;
}

.iframe-fb {
    position: relative;
    padding-bottom: 56.25%;
}

.iframe-fb iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.category-list {
    width: calc(100%);
}

@media (max-width: 768px) {
    .category-list {
        width: calc(100% + 30px);
        margin-left: -15px;
    }
}

.shop-category {
    border-radius: 4px;
    border: 1px solid transparent;
}

.shop-category:hover,
.shop-category.active {
    background-color: #e7fcfd;
    border-radius: 4px;
    border: 1px solid #34beef;
    overflow: hidden;
}

.slide-wrapper {
    padding: 0 20px;
}

@media (max-width: 768px) {
    .slide-wrapper {
        padding: 0 15px;
    }
}

.category-list .slick-arrow.slick-prev {
    top: calc(50% - 32px);
}

.category-list .slick-arrow.slick-next {
    top: calc(50% - 16px);
}

.moi-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    background: #ff7572;
    border-radius: 4px;
    color: #fff;
    padding: 2px 5px;
    display: inline-block;
}
.user-stt {
    display: inline-block;
    color: #f0fbff;
    background-color: #34beef;
    padding: 2px 3px;
    line-height: 100%;
    font-size: 12px;
    border-radius: 7px;
    margin: 0 4px 0 3px;
    border: 2px solid #e7fcfd;
}

.color-bg {
    color: #666;
}

.my-input::placeholder {
    color: #AAA;
}

.top-message-main-text {
    font-size: 16px;
    color: #222;
    line-height: 24px;
    font-weight: 600;
}

.top-message-sub-text {
    font-size: 16px;
    color: #222;
    line-height: 24px;
    font-weight: 400;
    margin-top: 4px;
}
.title-desc{
    font-size: 17px;
}
.agree-position {
    cursor: pointer;
}
.ant-picker-dropdown span {
    vertical-align: unset;
}
.ant-float-btn-default-zalo{
    background-color: #34BEEF;
    border-radius: 51%;
}
.ant-float-btn-default-zalo:hover{
    background-color: #60d8fc;
}
.arrow-up-zalo{
    width: 25px;
    height: 25px;
}
.float-btn-default-zalo .ant-float-btn-content{
    padding: 0 !important;
}
.expert_profresional{
   margin-top: 20px;
}
.professional-site-mini .user-info-thumb{
    width: 100%;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    background-color: #f8f8f8;
}
.professional-site-mini .user-info-thumb > img{
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    max-width: 80px !important;
    min-width: 80px !important;
    max-height: 80px !important;
}
.expert__list > li .professional-site-mini .rate-star, .expert__list > li .professional-mobile .rate-star{
    border-radius: 10px;
}

.professional-site-mini .showroom-location, .professional-mobile .showroom-location{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #666;
    align-items: center;
    display: flex;
    justify-content: center;
}
.professional-site-mini .showroom-location img, .professional-mobile .showroom-location img{
    height: 16px;
}
.professional-site-mini .showroom-location span,.professional-mobile .showroom-location span{
   margin-top: 1px;
}
.professional-site-mini .user-info, .professional-mobile .user-info{
    padding: 0 0 16px 0 !important;
    min-height: 100px;
    max-height: 100px;
}
.professional-mobile .user-info{
    padding: 12px 0 16px 0 !important;
}
.expert__list > li .professional-site-mini .user-info .name,.expert__list > li .professional-mobile .user-info .name{

    white-space: normal !important;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 40px !important;
    text-align: center;
    max-width: 100% !important;
    margin-bottom: 5px;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
}
.expert__list > li .professional-site-mini .user-info .text,.expert__list > li .professional-mobile .user-info .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    margin-bottom: 0;
    font-size: 12px;
    color: #aaaaaa;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 36px !important;
    display: -webkit-box;
}
.h-97px{
    height: 97px !important;
}
.modal{
    z-index: 99999999;
}
.bosch{
    position: relative; /* Cần thiết để pseudo-element định vị chính xác */
    background-color: #fff; /* Màu nền của box (tùy chọn) */
}
.bosch::before {
    content: ""; /* Bắt buộc để hiển thị pseudo-element */
    position: absolute;
    top: 0; /* Canh phía trên */
    left: 0; /* Bắt đầu từ mép trái */
    width: 100%; /* Chiều rộng bằng box */
    height: 6px; /* Chiều cao 3px */
    background-image: url("../images/background-header.png");
    background-size: cover; /* Hoặc `contain` nếu ảnh có kích thước cố định */
    background-repeat: no-repeat; /* Không lặp lại ảnh */
}
.bosch .headerTopMini{
    border-bottom: none !important;
    height: 60px;
}
.bosch .search-full-with{
    display: none !important;
}
.bosch .searchTb{
    display: none !important;
}

.wrap-bosch .sp-only .banner-tracking{
    padding-top: 0 !important;
    margin-bottom: 35px !important;
}
.bosch .headerTopMini .logo img{
    width: 138px;
    height: 24px;
}
.modal-dang-ky-tu-van{
    z-index: 99999999 !important;
}
